import React from 'react';

import './index.css';

import euroset from './euroset.svg';
import mastercard from './mastercard.svg';
import mir from './mir.svg';
import svyaznoy from './svyaznoy.svg';
import visa from './visa.svg';
import yandex from './yandex.svg';

class LPayments extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_payments_wrapper">
                <div className="l_content">
                    <p>Мы принимаем платежи <span>без дополнительной интеграции</span> — вы получаете выручку</p>
                    <ul className="plates">
                        <li><img src={visa} alt={''} /></li>
                        <li><img src={mastercard} alt={''} /></li>
                        <li><img src={mir} alt={''} /></li>
                        <li><img src={yandex} alt={''} /></li>
                        <li><img src={svyaznoy} alt={''} /></li>
                        <li><img src={euroset} alt={''} /></li>
                    </ul>
                    <p>Комиссия при продаже контента <span>составит 10%</span></p>
                </div>
            </div>
        );
    }
}

export default LPayments;
