import React from 'react';

import './course-socials.css';

import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    VKShareButton,
    OKShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    VKIcon,
    OKIcon
} from 'react-share';

class CourseSocials extends React.Component {
    constructor (props) {
        super();
    }


    render() {

        const sUrl = window.location.href;
        return (
            <div className="course_socials">
                <FacebookShareButton url={sUrl}><FacebookIcon size={36} round={true} /></FacebookShareButton>
                <VKShareButton url={sUrl}><VKIcon size={36} round={true} /></VKShareButton>
                <TwitterShareButton url={sUrl}><TwitterIcon size={36} round={true} /></TwitterShareButton>
                <TelegramShareButton url={sUrl}><TelegramIcon size={36} round={true} /></TelegramShareButton>
                <WhatsappShareButton url={sUrl}><WhatsappIcon size={36} round={true} /></WhatsappShareButton>
                <OKShareButton url={sUrl}><OKIcon size={36} round={true} /></OKShareButton>
            </div>
        );
    }
}

export default CourseSocials;
