import React, {useEffect, useState} from "react";
import {useParams, Redirect} from "react-router-dom";

import './index.css';
import API from "../../utils/api";
import {checkOwner} from "../../utils/utils";
import EditCourseGeneral from "./general";
// import EditCourseLessons from "./lessons";
import EditCourseFinish from "./finish";
import EditCoursePrice from "./price";
import EditCourseTabs from "./tabs";
import EditCourseAdvert from "./advert";
import EditCourseCovers from "./covers";
import EditCourseLanding from "./landing";
import EditCourseLessonsV2 from "./lessons-v2";
import EditCourseDescriptions from "./descriptions";
import Spinner from "../../utils/spinner";

function EditCourse(props) {
    let pageParams = useParams();

    const redirectNoAuth = !props.authorized || props.userData.role !== 'school';
    const [redirectAuthFailed, setRedirectAuthFailed] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const [tabsStatus, setTabsStatus] = useState(0);

    const minPrice = 100;

    const blankCourseName = 'blank_course';

    //const [status, setStatus] = useState('');

    const courseId = pageParams.id;

    const loadData = () => {
        setLoaded(true);
        setLoading(true);

        API.get('/med/v3/course/' + courseId + '?skip_cache=1')
            .then(function (r) {

                if (r.data.title === blankCourseName) {
                    r.data.title = '';
                }

                if (!r.data.lessons) {
                    r.data.lessons = [];

                }

                if (typeof r.data.lessons === "string") {
                    r.data.lessons = [];
                }

                if (!r.data.total_sales) {
                    r.data.total_sales = 0;
                }

                setLoading(false);
                setData(r.data);
                if (!checkOwner(r.data.id, props.userData) && !r.data.status === 'private') {
                    setRedirectAuthFailed(true);
                }
                checkProgress(r.data);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error)
            });
    };

    const updateData = (d, autoSave) => {
        const updated = Object.assign(data, d);

        setData(updated);
        checkProgress(updated);

        if (autoSave) {
            API.put('/med/v3/course/' + courseId, autoSave)
                .then(function (r) {
                    console.log(r);

                })
                .catch(function (error) {
                    alert(error)
                });
        }
    };

    const checkProgress = (d) => {
        let updated = data;
        if (d) {
            updated = d;
        }

        const tabLessonsActive = updated.title.length;
        const tabPriceActive = updated.lessons[0] && !updated.lessons[0].blank && updated.description.length && updated.short_description.length;
        const isOnlineCourse = updated.meta && updated.meta.date_start && parseInt(updated.meta.date_start) !== 0 && updated.description.length && updated.short_description.length;

        let progress = 0;
        if (tabLessonsActive) {
            progress = 1;
        }

        if (tabPriceActive || isOnlineCourse) {
            progress = 2;
        }
        setTabsStatus(progress);
    };

    useEffect(() => {
        document.body.classList.add('no_search');
        if (!loaded) {
            loadData();
        }

        return () => {
            document.body.classList.remove('no_search');
        }
    });

    let editorTab;
    switch (pageParams.tab) {
        case 'general':
            editorTab = <EditCourseGeneral {...props} updateData={updateData} courseData={data} />;
            break;
        case 'covers':
            editorTab = <EditCourseCovers {...props} updateData={updateData} reloadData={loadData} courseData={data} />;
            break;
        case 'descriptions':
            editorTab = <EditCourseDescriptions {...props} updateData={updateData} reloadData={loadData} courseData={data} />;
            break;
        case 'landing':
            editorTab = <EditCourseLanding {...props} updateData={updateData} reloadData={loadData} courseData={data} />;
            break;
        case 'lessons':
            editorTab = <EditCourseLessonsV2 {...props} updateData={updateData} courseData={data} />;
            break;
        case 'price':
            editorTab = <EditCoursePrice {...props} updateData={updateData} courseData={data} minPrice={minPrice} />;
            break;
        case 'advert':
            editorTab = <EditCourseAdvert {...props} updateData={updateData} courseData={data} />;
            break;
        case 'finish':
            editorTab = <EditCourseFinish {...props} updateData={updateData} courseData={data} />;
            break;
        default:
            editorTab = '';
    }

    const renderEditBlock = data ? editorTab : '';

    const tabs = data ? <EditCourseTabs {...props} tabsStatus={tabsStatus} courseData={data} /> : '';

    const redirectToMain = redirectNoAuth ? <Redirect to={{pathname: '/'}}/> : '';
    const redirectToProfile = redirectAuthFailed ? <Redirect to={{pathname: '/user/profile'}}/> : '';

    return (
        <div className="edit_create_wrapper">
            <div className="cc_top">
                <h2>конструктор курса</h2>
                {/*<h6>{status}</h6>*/}

                {tabs}
            </div>

            {renderEditBlock}

            <Spinner show={loading} />

            {redirectToMain}
            {redirectToProfile}
        </div>
    );
}

export default EditCourse;