import React from "react";
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import {TitleComponent} from "./utils/title";
import MetaTags from "react-meta-tags";
import SearchV2 from "./search-v2";
import Course from "./courses/course";
import PrivateRoute from "./utils/private-route";
import EditCourse from "./courses/edit-course";
import NewCourse from "./courses/new-course";
import EditWebinar from "./webinars/edit";
import NewWebinar from "./webinars/new";
import Webinar from "./webinars/webinar";
import CollectionsRouter from "./collections/collections-router";
import User from "./user";
import UserPage from "./user/user-page";
import PasswordRecovery from "./password-reset";
import PasswordReset from "./password-reset/reset";
import SignIn from "./sign-in";
import SignUpConfirm from "./sign-up/confirm";
import SignUpFormPage from "./sign-up/sign-up-form-page";
import SignUp from "./sign-up";
import Contacts from "./about/contacts";
import DocumentsListPage from "./about/docs";
import SchoolFAQ from "./about/school-faq";
import SchoolPlans from "./about/school-plans";
import PaymentsOptions from "./about/payments";
import ContactDetails from "./about/contact-details";
import Sandbox from "./sandbox";
import SalePage from "./utils/sale";
import Logout from "./logout";
import LandingSchool from "./landings/school";
import LandingPromo from "./landings/promo";
import Main from "./main";
import CategoryPage from "./search-v2/category-page";
import Greeting from "./greeting";
import Dedmorozonline from "./dedmorozonline";
import CourseV3 from "./course-v3/course";
import Statistics from "./statistics";
import LessonRedirect from "./lesson-v2/lesson-redirect";
import SchoolServices from "./about/services";
import Help from "./about/help/index";
import BlogEditor from "./blog/editor";
import BlogPost from "./blog/post";

function RouterConfig(props) {

    const p = props;

    let history = useHistory();
    const location = useLocation();

    if (location.hash.startsWith('#/') && location.hash.length > 2) {
        history.replace(location.hash.replace('#', ''));
    }
    if (location.pathname.startsWith('//')) {
        history.replace(location.pathname.replace('//', '/'));
    }

    // // Initialize google analytics page view tracking
    // history.listen(location => {
    //     p.reactGA.set({ page: location.pathname }); // Update the user's current page
    //     p.reactGA.pageview(location.pathname); // Record a pageview for the given page
    // });

    const metaDescription =  <MetaTags>
        <meta name="description" content={props.metaDescription} />
    </MetaTags>;

    return (
        <Switch>

            <Route path="/search">
                {metaDescription}
                <TitleComponent title={'Поиск'}/>
                <SearchV2 {...p}/>
            </Route>

            <Route path="/categories/:category_slug/:subcategory_slug">
                <CategoryPage {...p}/>
            </Route>

            <Route path="/categories/:category_slug">
                <CategoryPage {...p}/>
            </Route>

            <PrivateRoute {...p} allowed={p.authorizedSchool} path="/course/:id/edit/:tab">
                <TitleComponent title={'Редактирование курса'}/>
                <EditCourse {...p}/>
            </PrivateRoute>

            <PrivateRoute {...p} allowed={p.authorizedSchool} path="/course/new">
                <NewCourse {...p}/>
            </PrivateRoute>

            <Route path={['/course/3/:id/video/:lesson_id']}>
                <LessonRedirect />
            </Route>

            <Route path={['/course/3/:id/:lesson_id', '/course/3/:id']}>
                <CourseV3 {...p}/>
            </Route>

            <Route path="/course/:id">
                <Course {...p}/>
            </Route>



            <PrivateRoute {...p} allowed={p.authorizedSchool} path={[
                '/blog/:id/edit',
                '/blog/new'
            ]}>
                <TitleComponent title={'Редактор статьи'}/>
                <BlogEditor {...p}/>
            </PrivateRoute>

            <Route {...p} path={[
                '/blog/:id'
            ]}>
                <TitleComponent title={'Блог'}/>
                <BlogPost {...p}/>
            </Route>

            <PrivateRoute {...p} allowed={p.authorizedSchool} path="/webinar/:id/edit/:tab">
                <TitleComponent title={'Редактирование курса'}/>
                <EditWebinar {...p}/>
            </PrivateRoute>

            <PrivateRoute {...p} allowed={p.authorized} path="/webinar/:id/lecture/:lecture_id">
                <Webinar {...p}/>
            </PrivateRoute>

            <PrivateRoute {...p} allowed={p.authorizedSchool} path="/webinar/new">
                <NewWebinar {...p}/>
            </PrivateRoute>

            <PrivateRoute {...p} allowed={p.authorized} path="/webinar/:id">
                <Webinar {...p}/>
            </PrivateRoute>

            <Route path="/collections/:collectionId">
                {metaDescription}
                <TitleComponent title={'Подборки'}/>
                <CollectionsRouter {...p}/>
            </Route>

            <PrivateRoute {...p} allowed={p.authorized} path="/user/:profilePage">
                <TitleComponent title={'Личный кабинет'}/>
                <User {...p}/>
            </PrivateRoute>

            <PrivateRoute {...p} allowed={p.authorizedSchool} path={[
                '/statistics/:courseId/:lessonId',
                '/statistics/:courseId',
                '/statistics'
            ]}>
                <TitleComponent title={'Статистика'}/>
                <Statistics {...p}/>
            </PrivateRoute>

            <Route path="/users/:slug">
                {metaDescription}
                <UserPage {...p} />
            </Route>

            <Route path="/password-recovery">
                {metaDescription}
                <TitleComponent title={'Восстановление пароля'}/>
                <PasswordRecovery {...p}/>
            </Route>

            <Route path="/password-reset">
                {metaDescription}
                <TitleComponent title={'Новый пароль'}/>
                <PasswordReset {...p}/>
            </Route>

            <Route path="/signin">
                {metaDescription}
                <TitleComponent title={'Авторизация'}/>
                <SignIn {...p}/>
            </Route>

            <Route path="/signup/confirm">
                {metaDescription}
                <TitleComponent title={'Подтверждение регистрации'}/>
                <SignUpConfirm {...p}/>
            </Route>

            <Route path="/signup/:type">
                {metaDescription}
                <TitleComponent title={'Регистрация'}/>
                <SignUpFormPage {...p}/>
            </Route>

            <Route path="/signup">
                {metaDescription}
                <TitleComponent title={'Регистрация'}/>
                <SignUp {...p}/>
            </Route>

            <Route path="/about/contacts">
                {metaDescription}
                <TitleComponent title={'Контакты'}/>
                <Contacts {...p}/>
            </Route>

            <Route path="/about/docs">
                {metaDescription}
                <TitleComponent title={'Документы'}/>
                <DocumentsListPage  {...p}/>
            </Route>

            <Route path="/about/school-faq">
                {metaDescription}
                <TitleComponent title={'Вопросы и ответы (школам)'}/>
                <SchoolFAQ  {...p}/>
            </Route>

            <Route path="/about/school-plans">
                {metaDescription}
                <TitleComponent title={'Тарифные планы'}/>
                <SchoolPlans  {...p}/>
            </Route>

            <Route path="/about/services">
                {metaDescription}
                <TitleComponent title={'Cервисы'}/>
                <SchoolServices  {...p}/>
            </Route>

            <Route path="/about/help">
                {metaDescription}
                <TitleComponent title={'Помощь'}/>
                <Help  {...p}/>
            </Route>

            <Route path="/about/payments">
                {metaDescription}
                <TitleComponent title={'Способы оплаты'}/>
                <PaymentsOptions  {...p}/>
            </Route>

            <Route path="/about/details">
                {metaDescription}
                <TitleComponent title={'Реквизиты'}/>
                <ContactDetails  {...p}/>
            </Route>

            <Route path="/sandbox">
                <TitleComponent/>
                <Sandbox {...p}/>
            </Route>

            <Route path="/sale">
                {metaDescription}
                <SalePage {...p}/>
            </Route>

            <Route path="/logout">
                <Logout {...p}/>
            </Route>

            <Route path="/landings/school">
                {metaDescription}
                <TitleComponent title={'Ваша онлайн-школа на W-Hub'}/>
                <LandingSchool {...p}/>
            </Route>

            <Route path="/landings/promo">
                {metaDescription}
                <TitleComponent title={'Специальное предложение от W-Hub'}/>
                <LandingPromo {...p}/>
            </Route>

            <PrivateRoute {...p} allowed={p.authorized} path="/greeting">
                <TitleComponent title={'В гостях у дедушки мороза'}/>
                <Greeting {...p}/>
            </PrivateRoute>

            <PrivateRoute {...p} allowed={p.authorized} path="/dedmorozonline">
                <TitleComponent title={'В гостях у дедушки мороза'}/>
                <Dedmorozonline {...p}/>
            </PrivateRoute>


            <Route exact path="/">
                {metaDescription}
                <TitleComponent/>
                <Main {...p}/>
            </Route>
        </Switch>
    );
}

export default RouterConfig;