import React from "react";

import './lectures.css';
import Preloader from "../../utils/preloader";
import EditLectureItem from "./lecture";
import {obj2arr, arr2obj} from "../../utils/utils";
import TextareaAutosize from "react-autosize-textarea";
import API from "../../utils/api";

class EditWebinarsLectures extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            courseId: props.courseData.id,
            video_source_edited: false,
            video_source: props.courseData.video_source,
            lessons: props.courseData.lectures ? props.courseData.lectures : props.courseData.webinar_info.lectures
        };

        this.delete = this.delete.bind(this);
    }

    addLesson = () => {
        let lessonsArr = this.state.lessons;
        const blank = {
            title: '',
            description: '',
            video_code: '',
            blank: true,
            id: 'blank' + Math.round(Math.random()*100000)
        };

        lessonsArr.push(blank);
        this.setState({
            lessons: lessonsArr
        });
    };

    handleSave = (id, lesson) => {
        const obj = arr2obj(this.state.lessons, 'id');
        obj[id] = lesson;

        this.setState({lessons: obj2arr(obj)}, this.updateCourseData);
    };

    updateCourseData = () => {
        this.props.updateData({lessons: this.state.lessons});
    };

    handleSourceChange = (e) => {
        this.setState({video_source: e.target.value, video_source_edited: true});
    };

    handleSourceSave = () => {
        const t = this;
        const s = t.state;
        let newData = {
            video_source: this.state.video_source
        };

        t.setState({loading: true});
        API.put('/med/v1/course/update/' + s.courseId, newData)
            .then(function (r) {
                t.setState({loading: false, video_source_edited: false});
                t.props.updateData(newData);
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    delete(id){
        this.setState(prevState => ({
            lessons: prevState.lessons.filter(el => el !== id )
        }), this.updateCourseData);
    }

    render() {
        const preloader = this.state.loading ? <Preloader/> : '';
        const lessonsData = this.state.lessons;
        const lessons = lessonsData.map((lesson, idx) =>
            <EditLectureItem {...this.props} onSave={this.handleSave} deleteFunc={this.delete.bind(this, lesson)} key={lesson.id} idx={idx} lessonData={lesson} courseId={this.state.courseId} />
        );

        return (
            <div className="webinars_edit_block ceb_lectures">
                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={this.props.courseData.name} name="courseName" placeholder="название курса" />
                </div>

                <div className="ceb_lectures_feed">
                    {lessons}
                </div>

                <div className="ce_button_holder">
                    <button onClick={this.addLesson}>Добавить лекцию</button>
                </div>

                {preloader}
            </div>
        );
    }
}


export default EditWebinarsLectures;