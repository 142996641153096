import React from 'react';

import './index.css';
import API from "../utils/api";
import {arr2obj, obj2arr, serrializer} from "../utils/utils";

import './school-plans.css';
import Preloader from "../utils/preloader";
import {TickListBullit} from "../utils/icons";

class SchoolPlans extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: true,
            prices: {
                120024: {
                    regular_price: 999
                },
                120025: {
                    regular_price: 2999
                },
                120026: {
                    regular_price: 5999
                }
            }
        }
    }

    getPlansInfo = () => {
        const t = this;
        const ids = [120024, 120025, 120026];
        API.get('/med/v2/courses?' + serrializer({include: ids, per_page: 5}))
            .then(function (r) {

                const data = obj2arr(r.data.results);
                const obj = arr2obj(data, 'id');
                t.setState({prices: obj, loading: false})

            })
            .catch(function (error) {
                console.log(error);
                t.setState({loading: false})
            });
    };

    componentDidMount() {
        this.getPlansInfo();
    };
    componentWillUnmount() {
    };

    render() {
        const s = this.state;
        const preloader = s.loading ? <Preloader /> : '';

        const promo = s.prices[120024];
        const base = s.prices[120025];
        const expert = s.prices[120026];

        const promoVolume = promo.tariff_data ? promo.tariff_data.volume_human_readable : 20;
        const baseVolume = base.tariff_data ? base.tariff_data.volume_human_readable : 50;
        const expertVolume = expert.tariff_data ? expert.tariff_data.volume_human_readable : 100;

        return (
            <div className="subscription_plans_wrapper">
                <h1>дополнительные сервисы</h1>

                <div className="spw_inner">
                    <div className="sp_item">
                        <div className="sp_item_inner">
                            <div className="spi_price">
                                <div><div className="spi_price_old spi_empty">&nbsp;</div></div>
                                <div className="spi_price_value">0
                                    <div className="spi_price_term">рублей в&nbsp;месяц</div>
                                </div>

                            </div>
                            <div className="spi_title">freeHUB</div>
                            <ul className="spi_list">
                                <li><TickListBullit />доступ к конструктору видеокурсов</li>
                                <li><TickListBullit />возможность размещать видеокурсы на маркетплейсе</li>
                                <li><TickListBullit />возможность размещать платные видеокурсы на маркетплейсе</li>
                                <li><TickListBullit />доступ к статистике продаж и просмотров</li>
                            </ul>
                        </div>
                    </div>

                    <div className="sp_item">
                        <div className="sp_item_inner">
                            <div className="spi_price">
                                <div><div className="spi_price_old">{s.prices[120024].sale_price ? s.prices[120024].regular_price : ''}</div></div>
                                <div className="spi_price_value">{s.prices[120024].sale_price ? s.prices[120024].sale_price : s.prices[120024].regular_price}
                                    <div className="spi_price_term">рублей в&nbsp;месяц</div>
                                </div>

                            </div>
                            <div className="spi_title">promoHUB</div>
                            <ul className="spi_list">
                                <ul className="spi_list">
                                    <li><TickListBullit />{promoVolume} ГБ в облачном сервисе <nobr>W-Hub.Cloud</nobr></li>
                                    <li><TickListBullit />доступ к конструктору видеокурсов</li>
                                    <li><TickListBullit />возможность размещать видеокурсы на маркетплейсе</li>
                                    <li><TickListBullit />возможность размещать платные видеокурсы на маркетплейсе</li>
                                    <li><TickListBullit />доступ к статистике продаж и просмотров</li>
                                    <li><TickListBullit />доступ к расширенной аналитике</li>
                                </ul>
                            </ul>
                        </div>
                    </div>

                    <div className="sp_item">

                        <div className="sp_item_inner">
                            <div className="spi_price">
                                <div><div className="spi_price_old">{s.prices[120025].sale_price ? s.prices[120025].regular_price : ''}</div></div>
                                <div className="spi_price_value">{s.prices[120025].sale_price ? s.prices[120025].sale_price : s.prices[120025].regular_price}
                                    <div className="spi_price_term">рублей в&nbsp;месяц</div>
                                </div>

                            </div>
                            <div className="spi_title">baseHUB</div>
                            <ul className="spi_list">
                                <li><TickListBullit />{baseVolume} ГБ в облачном сервисе <nobr>W-Hub.Cloud</nobr></li>
                                <li><TickListBullit />доступ к конструктору видеокурсов</li>
                                <li><TickListBullit />возможность размещать видеокурсы на маркетплейсе</li>
                                <li><TickListBullit />возможность размещать платные видеокурсы на маркетплейсе</li>
                                <li><TickListBullit />доступ к статистике продаж и просмотров</li>
                                <li><TickListBullit />доступ к расширенной аналитике</li>
                                <li><TickListBullit />приоритетная техническая поддержка</li>
                            </ul>
                        </div>
                    </div>

                    <div className="sp_item">

                        <div className="sp_item_inner">
                            <div className="spi_price">
                                <div><div className="spi_price_old">{s.prices[120026].sale_price ? s.prices[120026].regular_price : ''}</div></div>
                                <div className="spi_price_value">{s.prices[120026].sale_price ? s.prices[120026].sale_price : s.prices[120026].regular_price}
                                    <div className="spi_price_term">рублей в&nbsp;месяц</div>
                                </div>

                            </div>
                            <div className="spi_title">expertHUB</div>
                            <ul className="spi_list">
                                <li><TickListBullit />{expertVolume} ГБ в облачном сервисе <nobr>W-Hub.Cloud</nobr></li>
                                <li><TickListBullit />доступ к конструктору видеокурсов</li>
                                <li><TickListBullit />возможность размещать видеокурсы на маркетплейсе</li>
                                <li><TickListBullit />возможность размещать платные видеокурсы на маркетплейсе</li>
                                <li><TickListBullit />доступ к статистике продаж и просмотров</li>
                                <li><TickListBullit />доступ к расширенной аналитике</li>
                                <li><TickListBullit />приоритетная техническая поддержка</li>
                                <li><TickListBullit />приоритетный доступ к новому функционалу</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className="price_disclamer">Маркетплейс W-Hub взимает комиссию с продажи в размере 20%, но не менее 150 рублей  (в эту сумму входит оплата услуги безопасных платежей и банковская комиссия).</p>

                {preloader}

            </div>
        );
    }
}

export default SchoolPlans;