import React from "react";

import ReactTooltip from "react-tooltip";

class CourseLevel extends React.Component {

    constructor (props) {
        super();
        this.myRef = React.createRef();
    }

    render() {
        const lvl = this.props.lvl;
        const cls1 = lvl > 0 ? 'filled' : '';
        const cls2 = lvl > 1 ? 'filled' : '';
        const cls3 = lvl > 2 ? 'filled' : '';

        let text = 'для всех';

        switch (lvl) {
            case 1:
                text = 'для начинающих';
                break;
            case 2:
                text = 'для опытных';
                break;
            case 3:
                text = 'для профессионалов';
                break;
            default:
                text = 'для всех';
        }

        return (
            <div className="icon_course_level">
                <svg data-tip={text} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle className={cls1} cx="8.5" cy="15.5" r="2"/>
                    <circle className={cls2} cx="16.5" cy="15.5" r="2"/>
                    <circle className={cls3} cx="12.5" cy="8.5" r="2"/>
                </svg>
                <ReactTooltip delayShow={0} effect={'solid'} />
            </div>

        );
    }
}

export default CourseLevel;