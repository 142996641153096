import React from 'react';

import {Link} from 'react-router-dom';

import './index.css';
import './footer-socials.css';
import {SocialIcon} from "react-social-icons";
import {TelegramIconSocial} from "../utils/icons";

class Footer extends React.Component {
    constructor (props) {
        super();

        this.state = {
            footerShown: false
        };
    }

    render() {
        const t = this;
        const cls_footer_shown = t.state.footerShown ? 'footer_shown' : '';
        const socialIconsSize = { height: 32, width: 32 };
        return (
            <div className="page_footer">
                <div className={'footer_links ' + cls_footer_shown}>
                    <div className="footer_hidden_block">
                        <p><span className="pseudo_link" onClick={this.props.modalSupport}>Обратная связь</span></p>
                        <p><span className="pseudo_link pl_dashed" onClick={() => t.setState({footerShown: true})}>показать все ссылки</span></p>
                    </div>
                    <div className="fl_block">
                        <h5>общее</h5>
                        {/*<p><span>О проекте</span></p>*/}
                        {/*<p><span>Команда</span></p>*/}
                        <p><Link to="/about/docs">Документы</Link></p>
                        <p><Link to="/about/details">Реквизиты</Link></p>
                        <p className="tech_support_link"><span className="pseudo_link" onClick={this.props.modalSupport}>Обратная связь</span></p>
                    </div>
                    <div className="fl_block">
                        <h5>студентам</h5>
                        <div className="footer-socials">
                            <SocialIcon url="https://www.instagram.com/whubmarket" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                            <SocialIcon url="https://www.facebook.com/WHubMarket" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                            <SocialIcon url="https://vk.com/Whubmarket" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                            <SocialIcon url="https://www.youtube.com/channel/UCVKyoLKoYrJmOSJ1UYx5JMg" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                        </div>
                        {/*<p><Link className="red" to="/collections/books">Подборки аудиокниг</Link></p>*/}
                        {/*<p><span>Вопросы и ответы</span></p>*/}
                        <p><a href="/docs/whub-manual-student.pdf">Руководство для студента (.pdf)</a></p>
                    </div>
                    <div className="fl_block">
                        <h5>школам</h5>
                        <div className="footer-socials">
                            <SocialIcon url="https://www.instagram.com/w.hub.platform" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                            <SocialIcon url="https://www.facebook.com/W.hub.platform" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                            <a href="https://tlgg.ru/WHubMedia" target="_blank" rel="noopener noreferrer"><TelegramIconSocial /></a>
                        </div>
                        <p><Link to="/landings/school">Все о школах</Link></p>
                        <p><Link to="/about/school-faq">Вопросы и ответы</Link></p>
                        <p><Link to="/about/services">Сервисы</Link></p>
                        <p><a href="/docs/whub-manual-school.pdf">Руководство для школы (.pdf)</a></p>
                    </div>
                    <div className="fl_block">
                        <h5>всем</h5>
                        <p className="tech_support_link"><span className="pseudo_link" onClick={this.props.modalSupport}>Техническая поддержка</span></p>
                        {/*<p>&nbsp;</p>*/}
                        {/*<p><a href="mailto:jobs@w-hub.ru">Отдел кадров</a></p>*/}
                        {/*<h5>W-Hub в соц. сетях</h5>*/}
                    </div>
                </div>
                <p className="cpr">W-Hub © 2019-2021</p>
            </div>
        );
    }
}

export default Footer;
