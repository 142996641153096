import React from 'react';
import API from "../../../../utils/api";
import Spinner from "../../../../utils/spinner";
import './index.css';

import ReactApexChart from 'react-apexcharts';
import ru from './ru';

class CourseStatistics extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            stats: null,

            series: null,
            dateStart: null,
            selection: 'views'
        };


    }

    componentDidMount() {
        this.loadStats();
    };

    loadStats = () => {
        const t = this;
        const {data} = t.props;

        t.setState({loading: true});
        API.get('/med/v3/stat/' + data.id)
            .then(function (r) {
                t.setState({loading: false, stats: r.data});
                if (r.data.length) {
                    t.processStats();
                }
                console.log(r)
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error.response)
            });

    };

    processStats = () => {
        const t = this;
        const {stats,selection} = t.state;

        let dataArray = [];
        stats.forEach((el) => {
            const date = new Date(el.date).getTime();
            const value = el[selection];
            dataArray.push([date, value]);
        });

        t.setState({dateStart: new Date(stats[0].date).getTime(), series: [{data: dataArray}]});

    };

    switchChart = (tar) => {
        const t = this;

        t.setState({loading: true, selection: tar}, t.processStats);
    };

    stopLoading = () => {
        const t = this;
        const {loading} = t.state;

        if (loading) {
            t.setState({loading: false});
        }
    };

    render() {
        const t = this;
        const {loading, dateStart, series, selection} = t.state;

        const options = {
            chart: {
                locales: [ru],
                defaultLocale: 'ru',
                id: 'area-datetime',
                type: 'area',
                height: 350,
                zoom: {
                    autoScaleYaxis: true
                },
                events: {
                    animationEnd: t.stopLoading
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            xaxis: {
                type: 'datetime',
                min: dateStart,
                max: new Date().getTime(),
                tickAmount: 6
            },
            yaxis: {
                min: 0,
                labels: {
                    formatter: function(val) {
                        return val.toFixed(0);
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            }
        };

        const chart = series ? <ReactApexChart options={options} series={this.state.series} type="area" height={350} /> : '';

        return (
            <div className="cl_statistics">
                <div className="cl_centrer">
                    <h2>Статистика</h2>

                    <p>Вы можете видеть статистику взаймодействий с курсом, так как являетесь его автором!</p>

                    <div className="stat_tabs">
                        <span className={selection === 'views' ? 'active' : ''} onClick={() => t.switchChart('views')}>Просмотры</span>
                        <span className={selection === 'favorites' ? 'active' : ''} onClick={() => t.switchChart('favorites')}>Избранное</span>
                        <span className={selection === 'purchases' ? 'active' : ''} onClick={() => t.switchChart('purchases')}>Покупки</span>
                    </div>

                    {chart}

                    <Spinner show={loading} />
                </div>
            </div>
        );
    }
}

export default CourseStatistics;
