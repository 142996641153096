import React from 'react';
import ReactPlayer from 'react-player'

import './index.css';

class PlayerLive extends React.Component {
    constructor (props) {
        super();
        this.player = React.createRef();

        this.state = {
            ready: false
        }
    }

    componentDidMount() {

    };

    render() {
        const t = this;
        const p = t.props;

        return (
            <div className="player_v2_wrapper">
                <ReactPlayer
                    ref={this.player}
                    url={p.url}
                    controls
                    light
                    playing
                    className="react-player"
                    width="100%"
                    height="100%"/>
            </div>
        );
    }
}

export default PlayerLive;