import React, {useState, useEffect} from 'react';

import {useParams} from 'react-router-dom';
import API from "../utils/api";

import PublicProfile from "./public-profile";

function UserPage(props) {
    let pageParams = useParams();

    const plug = {
        plug: true
    };
    const [loading, setLoading] = useState(true);
    const [userSlug, setUserSlug] = useState(null);
    const [userData, setUserData] = useState(plug);

    useEffect(() => {
        statusChange();
        document.body.classList.add('with_background');
        return () => {
            document.body.classList.remove('with_background');
        }
    });

    const statusChange = () => {
        if (!userSlug || userSlug !== pageParams.slug) {
            if (!loading) {
                setLoading(true);
            }
            setUserSlug(pageParams.slug);
            loadData();
        }
    };

    const loadData = () => {
        setLoading(true);
        API.get('/med/v1/users/' + pageParams.slug)
            .then(function (r) {
                setLoading(false);
                setUserData(r.data);
            })
            .catch(function (error) {
                setLoading(false);
                alert('что-то пошло не так...')
            });
    };

    const profile = !userData.plug ? <PublicProfile {...props} userData={userData} /> : '';

    return (
        <div className="profile_wrapper">
            {profile}
        </div>
    );
}

export default UserPage;