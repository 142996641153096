import React from "react";

import '../index.css';
class FormWHub extends React.Component {
    constructor (props) {
        super();
        this.textField = React.createRef();

        this.state = {
            loading: true,
            data: null,
            textCopied: ''
        }
    }

    componentDidMount() {
    };

    copyToClipboard = (value) => {
        const t = this;

        t.setState({textCopied: value}, () => {
            t.textField.current.select();

            document.execCommand('copy');
            t.textField.current.blur();
        });
    };

    fakeChange = () => {
        return false;
    };

    render() {
        const t = this;
        const p = t.props;
        const s = t.state;
        const data = p.lectureData;
        const streamKey = data.stream_key && data.stream_key.length ? data.stream_key : '';
        const streamServer = data.stream_server && data.stream_server.length ? data.stream_server : '';

        const hasStream = streamKey.length;

        const streamSettingsForm = <div>
            <div className="broadcast_manual">
                <ol>
                    <li>Скопируйте содержимое полей "сервер трансляции" и "ключ трансляции" в соответствующие поля настройки трансляции</li>
                    <li>Сохраните настройки и запустите трансляцию</li>
                    <li>Нажмите кнопку play в превью справа</li>
                </ol>
                <p>Если все сделано правильно, справа вы увидите запущенную трансляцию. Можете начинать лекцию!</p>
            </div>
            <div className="default_form">
                <div className="input_block">
                    <input disabled placeholder=" " value={streamServer} />
                    <label>сервер трансляции</label>
                </div>
                <button onClick={() => t.copyToClipboard(streamServer)} disabled={s.textCopied === streamServer} className="small">скопировать</button>
            </div>
            <div className="default_form">
                <div className="input_block">
                    <input disabled placeholder=" " value={streamKey} />
                    <label>ключ трансляции</label>
                </div>
                <button onClick={() => t.copyToClipboard(streamKey)} disabled={s.textCopied === streamKey} className="small">скопировать</button>
            </div>
            <textarea className="copy_textfield" ref={t.textField} value={s.textCopied} onChange={t.fakeChange}></textarea>
        </div>;

        const streamNotReady = <div>
            <div className="broadcast_manual">
                <p>Трансляция будет создана автоматически за 15 минут до запланированного начала лекции, и мы обязательно напомним вам об этом по электронной почте!</p>
                <button onClick={p.createStream}>создать трансляцию</button>
            </div>
        </div>

        const content = hasStream ? streamSettingsForm : streamNotReady;


        return (
            <div>
                <div className="broadcast_manual">
                    <p>Для проведения лекции с использованием сервиса W-Hub.Live вам потребуется программное обеспечение для захвата трансляции. <br />
                        Мы рекомендуем OBS Studio и подготовили для вас инструкцию по запуску трансляции! (ссылка на инструкцию)</p>
                </div>
                {content}
            </div>
        );
    }
}


export default FormWHub;