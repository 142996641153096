import React from 'react';
import {Link} from 'react-router-dom';
import './subscription-plans.css'
import API from "../utils/api";
import Preloader from "../utils/preloader";
import {arr2obj, obj2arr, serrializer} from "../utils/utils";
import Modal from "../modal";

class SubscriptionPlans extends React.Component {
    constructor (props) {
        super();

        const ud = props.userData;
        const plansMap = {
            free: {
                label: 'FreeHUB'
            },
            promo: {
                product_id: 120024,
                label: 'PromoHUB'
            },
            base: {
                product_id: 120025,
                label: 'BaseHUB'
            },
            expert: {
                product_id: 120026,
                label: 'ExpertHUB'
            }
        };

        this.state = {
            currentPlan: ud.subscription_plan,
            selectedPlan: ud.subscription_plan,
            payment_method: 'yandex_kassa',
            sberbank_b2b: false,
            purchaseLink: '123',
            showPurchaseModal: false,
            showInvoiceModal: false,
            showUnsubscribeModal: false,
            loading: true,
            plansMap: plansMap,
            plansObj: {
                120024: {
                    regular_price: 999
                },
                120025: {
                    regular_price: 1999
                },
                120026: {
                    regular_price: 3999
                }
            }
        }
    }

    getPlansInfo = () => {
        const t = this;
        const ids = [120024, 120025, 120026];
        API.get('/med/v2/courses?' + serrializer({include: ids, per_page: 5}))
            .then(function (r) {

                const data = obj2arr(r.data.results);
                const obj = arr2obj(data, 'id');
                t.setState({plansObj: obj, loading: false});
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    handleRadioChange = (e) => {
        const obj = {};
        if (e.target.value === 'sberbank_b2b') {
            this.setState({sberbank_b2b: true, payment_method: 'yandex_kassa'});
        }
        else {
            obj[e.target.name] = e.target.value;
            this.setState({sberbank_b2b: false});
            this.setState(obj);
        }
    };

    initPayment = () => {
        const t = this;
        const s = t.state;
        if (s.selectedPlan !== 'free' && s.selectedPlan !== s.currentPlan) {
            t.setState({loading: true});
            const params =  {product_id: [s.plansMap[s.selectedPlan].product_id], payment_method: s.payment_method};
            if (s.sberbank_b2b) {
                params.b2b = true;
            }

            API.post('/med/v1/order', params)
                .then((r) => {
                    t.setState({loading: false});
                    if (s.payment_method === 'yandex_kassa') {
                        const purchaseLink = r.data.payment_data.payment_url;
                        t.setState({purchaseLink: purchaseLink, showPurchaseModal: true});
                        window.open(purchaseLink);
                    }
                    if (s.payment_method === 'invoice') {
                        t.setState({showInvoiceModal: true});
                    }

                })
                .catch((error) => {
                    t.setState({loading: false});
                    console.log(error);
                })
        }
    };

    unsubscribe = () => {
        const t = this;
        API.get('/med/v1/card/revoke')
            .then((r) => {
                t.setState({loading: false, showUnsubscribeModal: true, currentPlan: 'free'});

            })
            .catch((error) => {
                t.setState({loading: false});
                console.log(error);
            })
    };

    componentDidMount() {
        this.getPlansInfo();
    };

    getPrice = (id) => {
        const s = this.state;
        const item = s.plansObj[id];
        if (item.sale_price) {
            return <span className="plan_price"><b className="regular_price">{item.regular_price} ₽</b><b className="sale_price">{item.sale_price} ₽</b></span>
        }

        return <span className="plan_price">{item.regular_price} ₽</span>;
    };

    hideModal = (d) => {
        this.setState({
            showInvoiceModal: false,
            showPurchaseModal: false,
            showUnsubscribeModal: false
        });
    };

    render() {
        const t = this;
        const s = this.state;
        const preloader = s.loading ? <Preloader /> : '';

        const freeDescription = <div className="sp_discr">
            <p>Подключен по умолчанию.</p>

            <p>Возможности:</p>
            <ul>
                <li>создание видеокурсов,  с использованием Youtube в качестве площадки для размещения контента;</li>
                <li>после завершившая регистрации, доступна возможность устанавливать цены на курсы;</li>
                <li>Доступ к статистике:
                    <ul>
                        <li>список студентов, купивших/получивших курсы школы;</li>
                        <li>отчет о продажах;</li>
                    </ul>
                </li>
            </ul>
        </div>;

        const defaultDescription = <div className="sp_discr">

            <p>Возможности:</p>
            <ul>
                <li>создание видеокурсов,  с использованием Youtube в качестве площадки для размещения контента;</li>
                <li>после завершения регистрации доступна возможность устанавливать цены на курсы;</li>
                <li>Доступ к W-HUB-СLOUD для хранения видео-контента в рамках установленной квоты;</li>
                <li>Доступ к расширенной аналитике включая:
                    <ul>
                        <li>список студентов, купивших/получивших курсы школы;</li>
                        <li>отчет о продажах;</li>
                        <li>подробный отчет о продажах;</li>
                        <li>детализированную аналитику востребованности каждого отдельного курса и урока;</li>
                        <li>список студентов, с исчерпывающей аналитикой просмотров/отказов/возвратов к каждому из приобретенных им курсов;</li>
                    </ul>
                </li>
            </ul>
        </div>;

        const currentDescription = s.currentPlan === 'free' ? freeDescription : defaultDescription;

        const btnUnsubscribe = s.currentPlan !== 'free' ? <button className='btn_unsubscribe' onClick={this.unsubscribe}>отменить подписку</button> : '';

        const purchaseModal = s.showPurchaseModal ? <Modal {...this.props} modalContent={'purchase_link'} modalUrl={s.purchaseLink} onModalClose={t.hideModal} /> : '';
        const invoiceModal = s.showInvoiceModal ? <Modal {...this.props} modalContent={'invoice_generation'} onModalClose={t.hideModal} /> : '';
        const unsubscribeModal = s.showUnsubscribeModal ? <Modal {...this.props} modalContent={'unsubscribe'} onModalClose={t.hideModal} /> : '';

        const completed = t.props.completed;
        const uncompletedNote = !completed ? <p className="uncompleted_note">для подключения платных услуг необходимо <Link to={'/user/school-data'}>завершить регистрацию</Link> и дождаться проверки налоговой</p> : '';

        const promo = s.plansObj[120024];
        const base = s.plansObj[120025];
        const expert = s.plansObj[120026];

        const promoVolume = promo.tariff_data ? promo.tariff_data.volume_human_readable : 20;
        const baseVolume = base.tariff_data ? base.tariff_data.volume_human_readable : 50;
        const expertVolume = expert.tariff_data ? expert.tariff_data.volume_human_readable : 100;
        return (
            <div className="subscription_plan_wrapper">
                <div className="sp_current">
                    <h1>Подключено: {s.plansMap[s.currentPlan].label}</h1>
                    {currentDescription}
                    {btnUnsubscribe}

                    <div className="sp_list_wrapper">

                        <h2>Сервисы:</h2>

                        <div className="plans_list">
                            <div className="radio_wrapper">
                                <input checked={s.selectedPlan === 'promo'} onChange={this.handleRadioChange} value="promo" id="plan_promoHUB" type="radio" name="selectedPlan" />
                                <label htmlFor="plan_promoHUB" className="advert_item">
                                    <div className="point"></div>
                                    <h4>PromoHUB</h4>
                                    <p>{this.getPrice(120024)} в месяц</p>
                                    <p>{promoVolume} ГБ в облаке W-Hub.Cloud и полный функционал маркетплейса</p>
                                </label>
                            </div>

                            <div className="radio_wrapper">
                                <input checked={s.selectedPlan === 'base'} onChange={this.handleRadioChange} value="base" id="plan_baseHUB" type="radio" name="selectedPlan" />
                                <label htmlFor="plan_baseHUB" className="advert_item">
                                    <div className="point"></div>
                                    <h4>BaseHUB</h4>
                                    <p>{this.getPrice(120025)} ₽ в месяц</p>
                                    <p>{baseVolume} ГБ в облаке W-Hub.Cloud и полный функционал маркетплейса</p>
                                </label>
                            </div>

                            <div className="radio_wrapper">
                                <input checked={s.selectedPlan === 'expert'} onChange={this.handleRadioChange} value="expert" id="plan_expertHUB" type="radio" name="selectedPlan" />
                                <label htmlFor="plan_expertHUB" className="advert_item">
                                    <div className="point"></div>
                                    <h4>ExpertHUB</h4>
                                    <p>{this.getPrice(120026)} ₽ в месяц</p>
                                    <p>{expertVolume} ГБ в облаке W-Hub.Cloud и полный функционал маркетплейса</p>
                                </label>
                            </div>
                        </div>

                        <h2>Способы оплаты:</h2>

                        <div className="plans_list">
                            <div className="radio_wrapper">
                                <input checked={s.payment_method === 'yandex_kassa' && !s.sberbank_b2b} onChange={this.handleRadioChange} value="yandex_kassa" id="pm_yandex" type="radio" name="payment_method" />
                                <label htmlFor="pm_yandex" className="advert_item">
                                    <div className="point"></div>
                                    <h4>Бизнес-картой через Яндекс.кассу</h4>
                                </label>
                            </div>
                            
                            <div className="radio_wrapper">
                                <input checked={s.payment_method === 'yandex_kassa' && s.sberbank_b2b} onChange={this.handleRadioChange} value="sberbank_b2b" id="pm_sberbank_b2b" type="radio" name="payment_method" />
                                <label htmlFor="pm_sberbank_b2b" className="advert_item">
                                    <div className="point"></div>
                                    <h4>Сбербанк b2b</h4>
                                </label>
                            </div>

                            <div className="radio_wrapper">
                                <input checked={s.payment_method === 'invoice'} onChange={this.handleRadioChange} value="invoice" id="pm_invoice" type="radio" name="payment_method" />
                                <label htmlFor="pm_invoice" className="advert_item">
                                    <div className="point"></div>
                                    <h4>Выставить счет</h4>
                                </label>
                            </div>
                        </div>


                        <div className="button_holder">
                            {uncompletedNote}
                            <button disabled={s.selectedPlan === s.currentPlan || !completed} onClick={this.initPayment}>подключить</button>
                        </div>

                        {defaultDescription}
                    </div>

                    <div className="sp_discr">
                        <p>Если вас не устраивает основная тарифня сетка, вам нужен бОльший объем хранилища, вы храните медиа-файлы ваших курсов на собственных серверах или, возможно, они в каком-то специфическом формате - свяжитесь с нашим <a href="mailto:sales@w-hub.ru">отделом продаж</a>, мы разработаем предложение специально для вас!</p>
                        <p className="price_disclamer">Маркетплейс W-Hub взимает комиссию с продажи в размере 20%, но не менее 150 рублей  (в эту сумму входит оплата услуги безопасных платежей и банковская комиссия).</p>
                    </div>



                </div>

                {purchaseModal}
                {invoiceModal}
                {unsubscribeModal}
                {preloader}
            </div>
        );
    }
}

export default SubscriptionPlans;