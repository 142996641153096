import React from 'react';
import {CourseRating} from "../../utils/icons";
import {html2plaintext, Plural} from "../../utils/utils";

import {Link} from "react-router-dom";

class WebinarParams extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const data = this.props.courseData;

        return (
            <div className="course_params">
                <dl className="cp_rating">
                    <dt>Рейтинг</dt>
                    <dd><CourseRating rating={data.rating}/>{data.rating}</dd>
                </dl>
                <dl>
                    <dt>Автор вебинара:</dt>
                    <dd><Link to={'/users/' + data.author.slug.toLowerCase()}>{html2plaintext(data.author.display_name)}</Link></dd>
                </dl>
                <dl>
                    <dt>Продолжительность:</dt>
                    <dd>{data.lectures.length} лекци{Plural(data.lectures.length, 'я', 'и', 'й')}</dd>
                </dl>
            </div>
        );
    }
}

export default WebinarParams;
