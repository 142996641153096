import React from 'react';
import { Redirect } from 'react-router-dom';

import './index.css';
import Preloader from "../../utils/preloader";
import API from "../../utils/api";

class NewWebinar extends React.Component {
    constructor (props) {
        super();
        this.state = {
            not_allowed: false,
            loading: true,
            id: null
        }
    }

    createNewWebinar = () => {
        const t = this;

        API.post('/med/v1/products')
            .then(function (r) {
                const id = r.data.draft_course_id ? r.data.draft_course_id : r.data.id;
                t.setState({id: id});
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    componentDidMount() {
        document.body.classList.add('no_search');

        this.createNewWebinar();

    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    render() {
        const redirect = this.state.id ? <Redirect to={{pathname: '/webinar/' + this.state.id + '/edit/general'}}/> : '';
        const preloader = this.state.loading ? <Preloader/> : '';

        return (
            <div className="webinar_create_wrapper">
                <div className="cc_top">
                    <h1>создание вебинара</h1>
                    {preloader}
                    {redirect}
                </div>

            </div>
        );
    }
}

export default NewWebinar;