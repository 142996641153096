import React from 'react';

import {Link, useParams} from 'react-router-dom';


function SchoolDashboardLinks(props) {
    const userData = props.userData;

    let pageParams = useParams();

    const activeCls = (c) => {
        return c === pageParams.profilePage ? 'active' : ''
    };

    const cls_red = !userData.school_data_completed ? 'red' : '';
    const schoolDataText = props.schoolCompleted ? 'личные данные' : 'завершение регистрации';


    const statsLink = props.schoolCompleted ? <Link className={activeCls('stats')} to={'/user/stats'}>статистика<sup>ß</sup></Link> : '';

    return (
        <div className="profile_nav_feed">
            <Link className={activeCls('profile')} to={'/user/profile'}>профиль</Link>
            <Link className={activeCls('school-data') + ' ' + cls_red} to={'/user/school-data'}>{schoolDataText}</Link>
            {/*<Link className={activeCls('calendar')} to={'/user/calendar'}>календарь</Link>*/}
            <Link className={activeCls('courses')} to={'/user/courses?status=publish'}>курсы</Link>
            <Link className={activeCls('online-courses')} to={'/user/online-courses?status=publish'}>онлайн-курсы</Link>
            <Link className={activeCls('posts')} to={'/user/posts'}>блог</Link>
            {statsLink}
            <Link className={activeCls('experts')} to={'/user/experts'}>эксперты</Link>
            <Link className={activeCls('students')} to={'/user/students'}>студенты</Link>
            <Link className={activeCls('homework')} to={'/user/homework'}>домашние задания</Link>
            <Link className={activeCls('chat')} to={'/user/chat'}>чат</Link>
            <Link className={activeCls('reviews')} to={'/user/reviews'}>отзывы</Link>
            <Link className={activeCls('help')} to={'/user/help'}>помощь</Link>
            {/*<Link className={activeCls('support')} to={'/user/support'}>тех. поддержка</Link>*/}
        </div>
    );
}

export default SchoolDashboardLinks;