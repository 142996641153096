import React from "react";
import {Link, useParams} from "react-router-dom";

import './tabs.css';

function EditWebinarsTabs(props) {
    let pageParams = useParams();

    const courseId = props.courseData.id;
    const courseUrl = '/webinar/' + courseId + '/edit/';

    const checkActiveTab = (tab) => {
        if (tab === pageParams.tab) {
            return 'active';
        }

        return '';
    };

    const tabCovers = props.tabsStatus > 0 ? <Link to={courseUrl + 'covers'}>оформление</Link> : <span data-tip="заполните название и описание вебинара<br />для доступа к этой вкладке">оформление</span>

    const tabTimetable = props.tabsStatus > 0 ? <Link to={courseUrl + 'timetable'}>расписание</Link> : <span>расписание</span>;

    const tabPrice = props.tabsStatus > 1 && props.userData.school_data_completed ? <Link to={courseUrl + 'price'}>цена</Link> : <span>цена</span>;
    const tabAdvert = props.tabsStatus > 1 ? <Link to={courseUrl + 'advert'}>реклама</Link> : <span>реклама</span>;
    const tabFinish = props.tabsStatus > 1 ? <Link to={courseUrl + 'finish'}>публикация</Link> : <span>публикация</span>;

    // useEffect(() => {
    //     console.log(props.courseStatus)
    // }, [props.tabsStatus]);

    const tabPriceItem = !props.freeCourse ? <li className={checkActiveTab('price')}>{tabPrice}</li> : '';

    return (
        <ul className="cc_progress">
            <li className={checkActiveTab('general')}><Link to={courseUrl + 'general'}>общее</Link></li>
            <li className={checkActiveTab('covers')}>{tabCovers}</li>
            <li className={checkActiveTab('timetable')}>{tabTimetable}</li>
            {tabPriceItem}
            <li className={checkActiveTab('advert')}>{tabAdvert}</li>
            <li className={checkActiveTab('finish')}>{tabFinish}</li>
        </ul>
    );
}

export default EditWebinarsTabs;