import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';

class SchoolFAQ extends React.Component {
    constructor (props) {
        super();
        this.state = {
        }
    }

    toggleItem = (el) => {

    };

    componentDidMount() {

    };
    componentWillUnmount() {
    };

    render() {

        return (
            <div className="about_wrapper faq_page">
                <h1><Link to="/landings/school">Все о школе</Link> / вопросы и ответы для школ</h1>

                <h2>общие вопросы</h2>
                <dl>
                    <dt>Что такое маркетплейс?</dt>
                    <dd>
                        <p>Маркетплейс – это онлайн-площадка, которая позволяет купить различные товары, необходимые для покупателя. Маркетплейс выступает в роли посредника и осуществляет связь между покупателем и поставщиком.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Как работает W-Hub? </dt>
                    <dd>
                        <p>W-Hub – это маркетплейс, который позволяет предпринимателям и компаниям создавать и продавать свои образовательные курсы и услуги.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Почему я должен размещать свои курсы на W-Hub? </dt>
                    <dd>
                        <p>W-Hub открывает следующие возможности:</p>
                        <ul>
                            <li>Привлечь новых клиентов. Мы профессионально продвигаем W-Hub в поисковиках Google, Yandex, а также в соц. сетях Facebook, Instagram. Наши клиенты - это и Ваши клиенты.</li>
                            <li>Теперь Вы сможете создавать курсы и учить студентов даже без создания рабочего сайта и аренды помещения.</li>
                            <li>Готовая онлайн-инфраструктура, которая позволит начать свой бизнес и получать доход.</li>
                            <li>Все услуги маркетплайса бесплатны, в том числе размещение бесплатного контента. Оплачивается только комиссия при покупке студентом платного контента.</li>
                        </ul>
                    </dd>
                </dl>
                <dl>
                    <dt>Что я могу разместить на маркетплейсе?</dt>
                    <dd>
                        <p>На маркетплейсе Вы можете размещать и продавать образовательный и развлекательный медиаконтент любого уровня сложности. В частности, это могут быть обучающие курсы, видеокурсы, обучающее видео, обучающие материалы в различных форматах, лекции, онлайн-курсы, вебинары, онлайн-трансляции. Размещаемые материалы могут быть предназначены как новичкам, так и профессионалам, а также быть платными или общедоступными.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Как создать аккаунт на W-Hub?</dt>
                    <dd>
                        <p>Начать работу с маркетплейсом просто. Необходимо пройти регистрацию, после чего Вы можете размещать бесплатные материалы и курсы. Для того чтобы размещать платные материалы и осуществлять их продажу, необходимо пройти полную регистрацию в личном кабинете с указанием банковских реквизитов для осуществления сделок.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Кто может размещать образовательные курсы?</dt>
                    <dd>
                        <p>Индивидуальные предприниматели и юридические лица (ООО), а также самозанятые. Если вы физическое лицо, прочитайте нашу инструкцию по регистрации самозанятых. Это просто.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Чем отличается наш сервис от других?</dt>
                    <dd>
                        <p>Главное отличие в том, что наш сервис прост в использовании, а инструменты бесплатны. Взимается только комиссия с продаж. Вы можете начать продавать быстро и без вложений.</p>
                    </dd>
                </dl>

                <h2>самозанятые</h2>

                <dl>
                    <dt>Сложно ли стать самозанятым?</dt>
                    <dd>
                        <p>Стать самозанятым не только просто, но и быстро. Данная процедура занимает несколько минут и не требует никаких бумажных документов. Для того чтобы зарегистрироваться самозанятым вам потребуется только паспорт и фотография, которую можно сделать на смартфон.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Как стать самозанятым?</dt>
                    <dd>
                        <p>Есть три основных способа регистрации:</p>
                        <ul>
                            <li>через <a target="_blank" rel="noopener noreferrer" href="https://npd.nalog.ru/app/">бесплатное приложение «Мой налог»</a></li>
                            <li>через <a target="_blank" rel="noopener noreferrer" href="https://lknpd.nalog.ru/auth/register/about">кабинет налогоплательщика «Налога на профессиональный доход»</a> на сайте ФНС</li>
                            <li>через <a target="_blank" rel="noopener noreferrer" href="https://npd.nalog.ru/credit-orgs/">уполномоченные банки</a></li>
                        </ul>

                        <p>Во всех случаях регистрация простая и быстрая. Инструкцию можно посмотреть здесь:<br />
                            <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/tXFqa5_0cmg">https://youtu.be/tXFqa5_0cmg</a>
                        </p>
                        <p>Если вы обсуживаетесь в сбербанке, вам будет удобно воспользоваться его услугами<br />
                            <a target="_blank" rel="noopener noreferrer" href="https://www.sberbank.ru/ru/svoedelo">https://www.sberbank.ru/ru/svoedelo</a></p>
                    </dd>
                </dl>
                <dl>
                    <dt>Какие преимущества быть самозанятым?</dt>
                    <dd>
                        <p>У данного статуса есть несколько преимуществ:</p>
                        <ul>
                            <li>Вы работаете «на себя», у вас нет работодателя. Если вы все же хотите быть самозанятым и в тоже время работать в другом месте, это не запрещено.</li>
                            <li>Регистрация самозанятого занимает около 5 минут, что гораздо быстрее чем регистрация ИП или юридического лица.</li>
                            <li>Самозанятые не сдают никаких налоговых отчетов.</li>
                            <li>Самозанятым не нужно платить страховые взносы.</li>
                            <li>Налоговая ставка с продаж на площадке W-hub составляет всего 4%. Ставка налога на доходы физических лиц составляет 13%, что гораздо больше.</li>
                            <li>Самозанятый может оформить налоговый вычет в размере 1% от дохода.</li>
                        </ul>
                    </dd>
                </dl>
                <dl>
                    <dt>Почему мне будет выгодно работать с W-Hub?</dt>
                    <dd>
                        <p>W-Hub – это площадка для продажи обучающих курсов и вебинаров. Зарегистрировавшись на нашей площадке, вы получите набор инструментов:</p>
                        <ul>
                            <li>Профессиональное продвижение в социальных сетях и поисковиках.</li>
                            <li>Инструменты расширенной аналитики продаж.</li>
                            <li>Готовый сервис приёма платежей. Мы принимаем платежи удобными для клиента способами, вы получаете прибыль.</li>
                            <li>Все необходимые для легальной работы электронные документы.</li>
                            <li>Инструкции по расчету и уплате налогов.</li>
                        </ul>
                    </dd>
                </dl>
                <dl>
                    <dt>На что еще мне нужно обратить внимание?</dt>
                    <dd>
                        <p>Есть несколько особенностей работы самозанятого о которых вы должны помнить:</p>
                        <ul>
                            <li>Вам нельзя будет нанимать других сотрудников.</li>
                            <li>Ежегодный доход самозанятого ограничен 2.4 млн. рублей.</li>
                        </ul>
                    </dd>
                </dl>

                <h2>стоимость и оплата</h2>
                <dl>
                    <dt>Сколько стоит размещение курсов?</dt>
                    <dd>
                        <p>Размещение курсов и все инструменты маркетплейса бесплатны. Взимается только комиссия с продаж при продажах платных курсов. </p>
                    </dd>
                </dl>
                <dl>
                    <dt>Какова комиссия маркетплейса?</dt>
                    <dd>
                        <p>Маркетплейс взимает комиссию с продаж в размере 10%, включая банковскую комиссию и услуги безопасных платежей.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Можно ли бесплатно создать курс на W-Hub?</dt>
                    <dd>
                        <p>Да, можно размещать бесплатный контент, а также бесплатно пользоваться всеми инструментами маркетплейса, однако в случае продажи курсов маркетплейс взимает комиссию.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Как и когда я буду получать оплату за курсы?</dt>
                    <dd>
                        <p>Маркетплейс перечисляет на Ваш расчетный счет денежную сумму, полученную от покупателя курса за вычетом агентского вознаграждения в течение 30-ти рабочих дней с момента покупки.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Вы работаете с НДС?</dt>
                    <dd>
                        <p>Все счета за дополнительные услуги выставляются без НДС.</p>
                    </dd>
                </dl>

                <h2>работа маркетплейса</h2>
                <dl>
                    <dt>Сложно ли работать с маркетплейсом? Смогу ли я работать самостоятельно или мне нужен программист?</dt>
                    <dd>
                        <p>Работать с маркетплейсом просто. После регистрации Вы можете создать образовательный курс в личном кабинете. Каких-либо специальных знаний для работы с маркетплейсом не требуется.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Где будут храниться мои курсы?</dt>
                    <dd>
                        <p>В зависимости от формата файлов хранение курсов может быть осуществлено с использованием любых популярных видеохостингов и файлообменников. Мы поддерживаем:</p>
                        <ul>
                            <li>Youtube, Vimeo, Twitch</li>
                            <li>прямые ссылки на видео в форматах *.mp4, *.webm, *.ogv на любых сторонних хостингах, таких как getcourse, dropbox</li>
                            <li>стриминговые форматы HLS и DASH</li>
                        </ul>
                    </dd>
                </dl>
                <dl>
                    <dt>Как я могу воспользоваться сервисом youtube для размещения контента на W-Hub, и безопасно ли это? Почему мне стоит использовать youtube?</dt>
                    <dd>
                        <p>Использование сервиса youtube и других видеохостингов позволяет нам размещать Ваш контент на маркетплейсе бесплатно. Несмотря на то, что при размещении курса рекомендуем воспользоваться нашим облачным решением, Вы всегда можете использовать внешний хостинг, указав в качестве места хранения курса ссылку на него</p>
                        <p>Чтобы ограничить доступ к контенту и увеличить безопасность, рекомендуем установить параметры доступа для видео размещенных на внешних хостингах: «Доступ по ссылке».</p>
                    </dd>
                </dl>
                <dl>
                    <dt>На какой срок будет доступен мой курс после продажи?</dt>
                    <dd>
                        <p>По умолчанию срок доступа к курсу составляет один месяц.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Необходимо ли подписать договор для работы с маркетплейсом?</dt>
                    <dd>
                        <p>Регистрируясь на маркетплейсе, Вы соглашаетесь с условиями лицензионного договора-оферты. Подписывать еще какой-либо договор не требуется.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Могу ли я получить оригинал договора на работу с маркетплейсом?</dt>
                    <dd>
                        <p>W-Hub работает по Лицензионному договору оферты, который не предусматривает бумажной копии договора.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Можно ли изменить условия договора?</dt>
                    <dd>
                        <p>Если Вы хотите обсудить специальные условия сотрудничества, напишите нам на адрес <a href="mailto:sales@w-hub.ru">sales@w-hub.ru</a>, и мы рассмотрим Ваше предложение.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Как я получу закрывающие документы? </dt>
                    <dd>
                        <p>Ежемесячно, не позднее двадцатого числа месяца, следующего за отчетным, мы формируем акт в соответствии с объемом услуг, оказанных в отчетном периоде (календарном месяце). Акт приема-передачи оказанных услуг направляется Вам по электронной почте, указанной при регистрации, либо в личном кабинете.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Как создать курс и снять курс с продажи?</dt>
                    <dd>
                        <p>Создавать, редактировать, размещать и удалять курсы Вы можете в личном кабинете. Для Вашего удобства мы создали руководство по работе с маркетплейсом.</p>
                    </dd>
                </dl>
                <dl>
                    <dt>Какие инструменты предлагает маркетплейс для онлайн-школ?</dt>
                    <dd>
                        <p>В настоящее время маркетплейс дает возможность создания, редактирования, публикации и продажи образовательных курсов. Также пользователям маркетплейса доступны такие инструменты как:</p>
                        <ul>
                            <li>Конструктор для создания обучающих курсов, включая видеокурсы, онлайн-курсы, вебинары, онлайн-трансляции и др.</li>
                            <li>Система рассылки писем и приглашений для студентов</li>
                            <li>Персональное оформление страницы курса</li>
                            <li>Аналитика продаж и посещений</li>
                            <li>Интегрированная система проведения платежей</li>
                            <li>Помощь персонального менеджера для организации работы автора на сайте</li>
                        </ul>
                    </dd>
                </dl>
                <dl>
                    <dt>Что означает термин «бета версия»?</dt>
                    <dd>
                        <p>Бета версия означает, что сайт работает в режиме тестирования и ограниченной функциональности. Благодаря данному режиму мы сможем внести все Ваши пожелания в финальный релиз маркетплейса. Если у Вас есть предложения, пишите нам на <a href="mailto:support@w-hub.ru">support@w-hub.ru</a></p>
                    </dd>
                </dl>
                <dl>
                    <dt>Можно ли добавить сотрудников для управления школой и определить им различные права доступа?</dt>
                    <dd>
                        <p>В настоящее время данный функционал находится в разработке. </p>
                    </dd>
                </dl>
                <dl>
                    <dt>Могу ли я интегрировать W-Hub со сторонним программным обеспечением?</dt>
                    <dd>
                        <p>Сообщите нам на <a href="mailto:support@w-hub.ru">support@w-hub.ru</a>, какая интеграция Вам необходима, и мы рассмотрим такую возможность. </p>
                    </dd>
                </dl>

                <div className="faq_bottom">
                    <p>Не нашли ответа на свой вопрос? Задайте его нам по адресу <a href="mailto:support@w-hub.ru">support@w-hub.ru</a>!</p>
                </div>
            </div>
        );
    }
}

export default SchoolFAQ;