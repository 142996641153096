import React from 'react';

import './index.css';

import dropbox from './dropbox.svg';
import gc from './gc.svg';
import stream from './stream.svg';
import twitch from './twitch.svg';
import vimeo from './vimeo.svg';
import youtube from './youtube.svg';
import link from './link.svg';

import presentation from './images/presentation.png';
import docs from './images/docs.png';
import sheets from './images/sheets.png';
import zoom from './images/zoom.png';
import webinar from './images/webinar.png';

class LEasyToUse extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className={'ls_easy_to_use_wrapper ' + this.props.className}>
                <div className="l_content">
                    <h2><nobr>W-HUB</nobr> — это удобно</h2>

                    <div className="storage">
                        <h3>Храните свой контент где удобно. Наш конструктор курсов поддерживает:</h3>

                        <div className="plate_section">
                            <h4>Видеоуроки</h4>
                            <ul className="plates plates_video">
                                <li>
                                    <div className="img_wrapper">
                                        <img src={youtube} alt={''} />
                                    </div>
                                    <p>Youtube</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img src={vimeo} alt={''} />
                                    </div>
                                    <p>Vimeo</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img src={twitch} alt={''} />
                                    </div>
                                    <p>Twitch</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img src={gc} alt={''} />
                                    </div>
                                    <p>GetCourse *</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img src={dropbox} alt={''} />
                                    </div>
                                    <p>Dropbox *</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img src={stream} alt={''} />
                                    </div>
                                    <p>HLS и DASH</p>
                                </li>
                            </ul>
                            <p>* Прямые ссылки на видео в форматах *.mp4, *.webm, *.ogv на любых сторонних хостингах</p>
                        </div>

                        <div className="plate_section">
                            <h4>Вебинары</h4>
                            <ul className="plates plates_webinar">
                                <li>
                                    <div className="img_wrapper">
                                        <img src={youtube} alt={''} />
                                    </div>
                                    <p>Youtube</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_zoom" src={zoom} alt={''} />
                                    </div>
                                    <p>Zoom</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_webinarru" src={webinar} alt={''} />
                                    </div>
                                    <p>Webinar.ru</p>
                                </li>
                            </ul>
                        </div>

                        <div className="plate_section">
                            <h4>Презентации</h4>
                            <ul className="plates plates_pdf">
                                <li>
                                    <div className="img_wrapper">
                                        <img src={link} alt={''} />
                                    </div>
                                    <p>Прямые ссылки</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img src={vimeo} alt={''} />
                                    </div>
                                    <p>Dropbox *</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_g_service" src={presentation} alt={''} />
                                    </div>
                                    <p>Google Презентации</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_g_service" src={docs} alt={''} />
                                    </div>
                                    <p>Google Документы</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_g_service" src={sheets} alt={''} />
                                    </div>
                                    <p>Google Таблицы</p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default LEasyToUse;
