import React from "react";
// import PDFViewer from "pdf-viewer-reactjs";
import './index.css';

class PdfVeiwer extends React.Component {
    constructor (props) {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.url !== nextProps.url;
    }

    urlParser = (url) => {
        let output = url.replace('www.dropbox.com', 'dl.dropboxusercontent.com').replace('dl=0', '');

        if (url.indexOf('docs.google.com') > -1 && url.indexOf('/d/') > -1) {
            const chunks_0 = url.split('/d/');
            const chunks_1 = chunks_0[1].split('/');

            const postUrl = url.indexOf('presentation') > -1 ? '/export/pdf' : '/export?format=pdf';

            output = chunks_0[0] + '/d/' + chunks_1[0] + [postUrl];
        }

        return output;
    };

    render() {
        // const t = this;
        // const {url, onCount} = t.props;


        // const viewer = url.length ? <PDFViewer
        //     getMaxPageCount={onCount}
        //     document={{
        //         url: t.urlParser(url),
        //     }}
        // /> : '';

        return (
            <div className="pdf_viewer">
                <i>К сожалению, просмотр PDF файлов временно недоступен. Приносим извинения!</i>
                {/*{viewer}*/}
            </div>
        )
    }
}

export default PdfVeiwer;