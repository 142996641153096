import React from "react";

import './index.css';
import PlayerLive from "../../utils/player-v2/live-player";
// import LiveChat from "../../utils/chat";
import {arr2obj, checkOwner} from "../../utils/utils";
import LecturerDashboard from "./lecturer-dashboard";

class WebinarLive extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: false,
            data: null
        }
    }

    componentDidMount() {
    };

    render() {
        const t = this;
        const p = t.props;
        const webinarData = p.webinarData;
        const lectureData = arr2obj(webinarData.lectures, 'id')[p.lectureId];
        const isLecturer = checkOwner(webinarData.id, p.userData);

        const cls_lecturer = isLecturer ? 'lecturer' : '';
        const dashboard = isLecturer ? <LecturerDashboard {...p} lectureData={lectureData} /> : '';

        const player = lectureData.stream_url && lectureData.stream_url.length ? <PlayerLive url={lectureData.stream_url} /> : '';


        return (
            <div className={'webinar_live_wrapper ' + cls_lecturer}>
                <div className="wl_top">

                    <h1>{lectureData.title}</h1>

                </div>

                <div className="live_player_wrapper">
                    <div className="live_chat_wrapper">
                        {/*<LiveChat {...p} data={lectureData} />*/}
                    </div>
                    <div className="player_inner">

                        {dashboard}
                        {player}
                    </div>
                </div>

            </div>
        );
    }
}


export default WebinarLive;