import React, {useEffect, useState} from "react";

import {ScrollDebounce, html2plaintext} from "../utils/utils";
import GeoPattern from "geopattern";

import './course-params.css';
import Preloader from "../utils/preloader";
import CourseRelated from "./course-related";
import CourseButtonsBlock from "./course-buttons-block";
import CourseSocials from "./course-socials";
import CourseButtonsSchool from "./course-buttons-school";
import CourseParams from "./course-params";
import CourseText from "./course-text";
import CourseLevel from "../utils/course-level";
import Tracker from "../utils/tracker";

function CourseDefault(props) {
    const [fixed, setFixed] = useState(false);
    const [loading, setLoading] = useState(false);

    const data = props.courseData;

    let coverPattern = 'none';
    const imgObj = data.images_stack;
    const coverSrc = imgObj.cover && imgObj.cover.normal ? imgObj.cover.normal : null;


    let cover = coverSrc ? <div className="cover_wrapper"><img src={coverSrc} alt="" /></div> : '';

    if (!cover.length) {
        coverPattern = GeoPattern.generate(data.name, {color: '#434A5C'});
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollCrunch);
        scrollFunc();

        return () => {
            window.removeEventListener('scroll', scrollCrunch);
        }
    });

    const scrollCrunch = () => {
        return ScrollDebounce(scrollFunc());
    };

    const scrollFunc = () => {
        const scroll = window.scrollY;
        const treshold = 517;
        const bottom = document.querySelectorAll('.page_view')[0].clientHeight - window.innerHeight + document.querySelectorAll('.page_footer')[0].clientHeight;

        if (scroll >= treshold && scroll < bottom && !fixed) {
            setFixed('fixed');
        }

        if ((scroll < treshold || scroll >= bottom) && fixed) {
            setFixed('');
        }
    };

    const preloader = loading || props.loading ? <Preloader /> : '';


    let course_buttons = '';

    if (props.authorized && props.userData.role === 'school') {
        course_buttons = <CourseButtonsSchool {...props} />;
    }
    else {
        course_buttons = <CourseButtonsBlock {...props} setLoading={setLoading} courseType={'video'} />;
    }

    const params = <CourseParams courseData={data} />;
    const text = <CourseText {...props} courseData={data} setLoading={setLoading} />;

    return (
        <div className="course_default">
            <div className={'course_top'}>
                <div className={'bg'} style={{backgroundImage: coverPattern.toDataUrl()}}>{cover}</div>
                <div className={'ct_content'}>
                    <div className="ct_headers">
                        <h6>{data.categories[0].name} <CourseLevel lvl={data.difficulty} /></h6>
                        <h1>{html2plaintext(data.name)}</h1>
                    </div>

                    {params}
                </div>
            </div>

            <div className="course_body">
                <div className="course_side_wrapper">
                    <div className={'course_side ' + fixed}>
                        {course_buttons}
                        <CourseSocials />

                        <div className="cs_info">
                            <h6>{data.categories[0].name}</h6>
                            <h2>{html2plaintext(data.name)}</h2>

                            {params}
                        </div>
                    </div>
                </div>

                {text}

            </div>

            <CourseRelated {...props} ids={data.related_ids} />

            <Tracker id={data.id} event="view" />
            {preloader}
        </div>
    );
}

export default CourseDefault;