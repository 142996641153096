import React, {useEffect, useState} from "react";

import {ButtonPlay, CourseRating} from "../utils/icons";
import {
    ScrollDebounce,
    html2plaintext,
    savePlaybackProgress
} from "../utils/utils";
import GeoPattern from "geopattern";

import './course-params.css';
import Preloader from "../utils/preloader";

import 'react-h5-audio-player/lib/styles.css';
import CourseRelated from "./course-related";
import CourseButtonsBlock from "./course-buttons-block";
import CourseSocials from "./course-socials";
import CourseRatingBlock from "./course-rating-block";
import AudioPlayerTool from "../utils/audio-player-tool";
import API from "../utils/api";
import CourseButtonsSchool from "./course-buttons-school";
import CourseLevel from "../utils/course-level";

function CourseAudio(props) {
    const [fixed, setFixed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [requested, setRequested] = useState(false);
    const [initPlayer, setInitPlayer] = useState(false);

    const data = props.courseData;
    const purchased = props.authorized && props.userData.litres_purchases && props.userData.litres_purchases[data.id];

    let coverPattern = GeoPattern.generate(data.name, {color: '#434A5C'});
    const imgObj = data.images_stack;
    const coverSrc = imgObj.cover && imgObj.cover.normal ? imgObj.cover.normal : null;

    //const [audioSrc, setAudioSrc] = useState('https://partnersdnld.litres.ru/get_mp3_trial/' + data.litres_metadata.litres_id +'.mp3');

    const [files, setFiles] = useState([
        {
            link: 'https://partnersdnld.litres.ru/get_mp3_trial/' + data.litres_metadata.litres_id +'.mp3',
            title: 'ознакомительный фрагмент',
            purchased: false
        }
    ]);

    if (purchased && !requested) {
        setRequested(true);
        setLoading(true);

        API.get('/litres/audio/' + data.id + '/' + props.userData.id)
            .then(function (r) {
                const fileType = r.data.group;
                let files_array;
                if (fileType[5]) {
                    files_array = fileType[5].file;
                }
                else if (!fileType[5] && fileType[3]) {
                    files_array = fileType[3].file;
                }
                else {
                    files_array = fileType[7].file;
                }

                const playlist = createPlaylist(files_array);

                setLoading(false);
                setFiles(playlist);
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    let cover = coverSrc ? <div className="book_cover_cutter"><img src={coverSrc} alt="" /></div> : '';

    useEffect(() => {
        window.addEventListener('scroll', scrollCrunch);
        scrollFunc();

        return () => {
            window.removeEventListener('scroll', scrollCrunch);
        }
    });

    const createPlaylist = (files_array) => {
         return files_array.map((item) => {
            return {
                link: item.url,
                title: data.name,
                purchased: true
            };
         })
    };

    const scrollCrunch = () => {
        return ScrollDebounce(scrollFunc());
    };

    const scrollFunc = () => {
        const scroll = window.scrollY;
        const treshold = 517;
        const bottom = document.querySelectorAll('.page_view')[0].clientHeight - window.innerHeight + document.querySelectorAll('.page_footer')[0].clientHeight;

        if (scroll >= treshold && scroll < bottom && !fixed) {
            setFixed('fixed');
        }

        if ((scroll < treshold || scroll >= bottom) && fixed) {
            setFixed('');
        }
    };

    const saveProgress = (obj) => {
        savePlaybackProgress(data.id, obj.file, obj.time_in_secs);
    };

    const AuthorCheck = () => {
        let authors = [];

        const lm = data.litres_metadata;

        if (lm && lm.authors && lm.authors.author && lm.authors.author.length) {
            lm.authors.author.forEach((el) => {
                if (el.relation.toString() === '0') {
                    const firstName = el['first-name'] ? el['first-name'] : '';
                    const lastName = el['last-name'] ? el['last-name'] : '';
                    authors.push(firstName + ' ' + lastName)
                }
            });
        }

        return authors.length ? <div className="course_grid_item">
            <h5>{authors.length > 1 ? 'авторы' : 'автор'}:</h5>
            <div className="cgi_content">
                <div className="course_description">{authors.join(', ')}</div>
            </div>
        </div> : '';


    };

    const renderCourseParams = () => {
        const rating = parseFloat(data.average_rating) ? <dl className="cp_rating">
            <dt>Рейтинг</dt>
            <dd><CourseRating rating={data.average_rating}/>{parseFloat(data.average_rating).toFixed(1)}</dd>
        </dl> : '';

        return (
            <div className="course_params">
                {rating}
                <dl className="cp_rating">
                    <dt>тип:</dt>
                    <dd>аудиокнига</dd>
                </dl>
                <dl>
                    <dt>озвучивание:</dt>
                    <dd>профессиональное</dd>
                </dl>
            </div>
        )
    };

    const initPlayerFunc = () => {
        setInitPlayer(true);
    };

    const description = data.description.length ? data.description : data.short_description;

    const rating_block = props.authorized && props.userData.role === 'student' ? <CourseRatingBlock {...props} setLoading={setLoading} /> : ''

    const preloader = loading || props.loading ? <Preloader /> : '';

    const audio = initPlayer ? <AudioPlayerTool
        courseId={data.id}
        currentProgress={props.progress ? props.progress : {file_index: 0, time_in_secs: 0}}
        files={files}
        saveProgress={saveProgress}/> : <div className="btn_play" onClick={initPlayerFunc}><ButtonPlay /></div>;

    const course_buttons = props.authorized && props.userData.role === 'school' ? <CourseButtonsSchool {...props} /> : <CourseButtonsBlock {...props} setLoading={setLoading} courseType={'audio'} initPlayerFunc={initPlayerFunc} initPlayerStatus={initPlayer} />

    return (
        <div className="course_default">
            <div className={'course_top book_cover'}>
                <div className={'bg book_cover'} style={{backgroundImage: coverPattern.toDataUrl()}}>
                    <div className="cover_wrapper">
                        {cover}
                        {audio}
                    </div>
                </div>
                <div className={'ct_content book_cover'}>
                    <div className="ct_headers">
                        <h6>{data.categories[0].name} <CourseLevel lvl={data.difficulty} /></h6>
                        <h1>{html2plaintext(data.name)}</h1>
                    </div>

                    {renderCourseParams()}
                </div>
            </div>

            <div className="course_body">
                <div className="course_side_wrapper">
                    <div className={'course_side ' + fixed}>
                        {course_buttons}
                        <CourseSocials />

                        <div className="cs_info">
                            <h6>{data.categories[0].name}</h6>
                            <h2>{data.name}</h2>

                            {renderCourseParams()}

                        </div>
                    </div>
                </div>

                <div className="course_text">
                    <div className="course_grid_item">
                        <h5>о курсе</h5>
                        <div className="cgi_content">
                            <div className="course_description">{html2plaintext(description)}</div>
                        </div>
                    </div>

                    <AuthorCheck />

                    {rating_block}
                </div>
            </div>

            <CourseRelated {...props} ids={data.related_ids} />

            {preloader}
        </div>
    );
}

export default CourseAudio;