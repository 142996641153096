import React from "react";

import './general.css';
import Preloader from "../../utils/preloader";
import ImgUpload from '../../utils/img-upload';

import TextareaAutosize from 'react-autosize-textarea';
import API from "../../utils/api";

class EditWebinarCovers extends React.Component {
    constructor(props) {
        super();

        const imagesTemplate = {
            preview: {name: 'preview', position: 0},
            vertical: {name: 'vertical', position: 2},
            cover: {name: 'cover', position: 4}
        };

        this.state = {
            loading: false,
            data: props.courseData,
            courseId: props.courseData.id,
            imagesData: imagesTemplate,
            addVerticalPreview: false
        };
    }

    renderImages = (rImages = this.state.data.images_stack) => {
        const s = this.state;
        const imagesData = s.imagesData;

        // todo: добавить логику ретины
        if (rImages.preview) {
            imagesData.preview.src = rImages.preview.normal;
        }
        if (rImages.cover) {
            imagesData.cover.src = rImages.cover.normal;
        }
        if (rImages.vertical) {
            imagesData.vertical.src = rImages.vertical.normal;
            this.setState({addVerticalPreview: true});
        }

        this.setState({imagesData: imagesData});
    };

    componentDidMount() {
        this.renderImages();
    };

    componentWillUnmount() {
    };

    saveChanges = (cb) => {
        const t = this;
        const s = t.state;
        let newData = {
            product_type: 'webinar',
            name: s.data.name
        };

        t.setState({loading: true});
        API.put('/med/v1/course/update/' + s.courseId, newData)
            .then(function (r) {
                t.setState({loading: false});
                t.renderImages(r.data.images_stack);
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    showVerticalPreview = () => {
        this.setState({addVerticalPreview: true});
    };

    render() {
        const t = this;
        const s = t.state;
        const preloader = s.loading ? <Preloader/> : '';

        const imgs = s.imagesData;
        const cls_preview_loaded = imgs.preview.src ? 'loaded' : '';
        const cls_cover_loaded = imgs.cover.src ? 'loaded' : '';
        const cls_vertical_loaded = imgs.vertical.src ? 'loaded' : '';

        const previewImg = imgs.preview.src ? <img src={imgs.preview.src} alt={imgs.preview.name} /> : '';
        const coverImg = imgs.cover.src ? <img src={imgs.cover.src} alt={imgs.cover.name} /> : '';
        const verticalImg = imgs.vertical.src ? <img src={imgs.vertical.src} alt={imgs.vertical.name} /> : '';

        // const verticalPreviewSwitcher = s.addVerticalPreview ? '' : <h5 className="pseudo_link" onClick={this.showVerticalPreview}>добавить вертикальное превью</h5>;

        return (
            <div className="webinars_edit_block web_general">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={this.props.courseData.name} name="courseName" placeholder="название курса" />
                </div>

                <div className="course_covers_block">
                    <div className="ce_grid">
                        <div className="ce_grid_block ce_left_block">
                            <div className="ce_upload_wrapper">
                                <h5>превью</h5>
                                <div className={cls_preview_loaded + ' ce_upload ce_upload_preview'}>
                                    <ImgUpload courseId={s.courseId} className="cc_preview" id="coursePreviewImg" imgSlot={s.imagesData.preview} onUpload={t.saveChanges} />
                                    {previewImg}
                                </div>
                                <div className="course_form_hint">*.png или *.jpg в разрешении не менее 608px х 296px</div>
                            </div>

                            {/*{verticalPreviewSwitcher}*/}
                            {this.state.addVerticalPreview ? <div className="ce_upload_wrapper">
                                <h5>вертикальное превью</h5>
                                <div className={cls_vertical_loaded + ' ce_upload ce_upload_vertical'}>
                                    <ImgUpload courseId={s.courseId} className="cc_preview" id="courseVerticalImg" imgSlot={s.imagesData.vertical} onUpload={t.saveChanges} />
                                    {verticalImg}
                                </div>
                                <div className="course_form_hint">*.png или *.jpg в разрешении не менее 600px х 900px</div>
                            </div> : ''}

                        </div>
                        <div className="ce_grid_block ce_right_block">
                            <h5>обложка</h5>
                            <div className={cls_cover_loaded + ' ce_upload ce_upload_cover'}>
                                <ImgUpload courseId={s.courseId} className="cc_cover" id="courseCoverImg" imgSlot={s.imagesData.cover} onUpload={t.saveChanges} />
                                {coverImg}
                            </div>
                            <div className="course_form_hint">*.png или *.jpg в разрешении не менее 1440px х 765px</div>
                        </div>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default EditWebinarCovers;