import React from 'react';

import './feed.css';

import CoursesFeed from "../courses/courses-feed";

class MainFeed extends React.Component {
    constructor (props) {
        super();

        this.state = {
            activeTab: props.defaultTab ? props.defaultTab : 'video'
        };
    }

    switchRange = (e) => {
        const range = e.currentTarget.getAttribute('data-selection');
        this.setState({activeTab: range})
    };

    render() {
        const p = this.props;
        const tab = this.state.activeTab;
        const isNoTabs = p.noTabs;


        const tabs = !isNoTabs ? <ul className="switcher">
            <li
                className={tab === 'video' ? 'active' : ''}
                onClick={this.switchRange} data-selection="video">видео</li>
            <li
                className={tab === 'audio' ? 'active' : ''}
                onClick={this.switchRange} data-selection="audio">аудио</li>
        </ul> : <div className="v_spacer">&nbsp;</div>;

        const courses = !isNoTabs ? p.courses[tab] : p.courses;

        return (
            <div className="main_feed_wrapper">
                <h2>{p.title}</h2>
                {tabs}

                <CoursesFeed {...p} courses={courses} currentTab={this.state.activeTab} firstElement={!p.authorized && p.firstElement ? p.firstElement : null} />
            </div>
        );
    }
}

export default MainFeed;
