import React from 'react';
import { Link } from 'react-router-dom';
import {CourseRatingIcon} from "../utils/icons";
import {Plural, html2plaintext, arr2obj, PriceFormat} from "../utils/utils";

import './course-featured-item.css'
import API from "../utils/api";
import Preloader from "../utils/preloader";
import GeoPattern from "geopattern";
import CourseLevel from "../utils/course-level";
import ProductTypeIcon from "../utils/product-type-icon";

class CourseFeaturedItem extends React.Component {
    constructor (props) {
        super();

        const data = props.itemData;
        this.state = {
            published: data.status === 'publish',
            loading: false
        }
    }

    handleClick = () => {
        if (this.props.closeFunc) {
            this.props.closeFunc();
        }
    };

    // handlePurchaseClick = () => {
    //     const t = this;
    //     const p = t.props;
    //
    //     if (p.purchaseFunc) {
    //         p.purchaseFunc(p.itemData);
    //     }
    // };

    initPayment = () => {
        const t = this;
        const p = t.props;
        const s = t.state;
        const data = s.purchaseItemData;
        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        if (!purchased) {
            t.setState({loading: true});
            API.post('/med/v3/order', {product_id: data.id, payment_method: 'ukassa'})
                .then((r) => {
                    t.setState({loading: false});
                    const purchaseLink = r.data.payment_data.payment_url;
                    p.modalPurchase(purchaseLink);
                    window.open(purchaseLink);
                })
                .catch((error) => {
                    t.setState({loading: false});
                    alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
                    console.log(error);
                })
        }
        else {
            alert('Вы уже купили этот товар!')
        }
    };

    freeCoursePurchase = () => {
        // const t = this;
        // const s = t.state;
        // const p = t.props;
        // const data = s.purchaseItemData;
        // const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        // if (!purchased) {
        //     API.get('/med/v1/pay/nothing/' + data.id)
        //         .then((r) => {
        //             p.reloadUserData();
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // }
        // else {
        //     alert('Вы уже купили этот товар!')
        // }
    };

    handlePurchase = () => {
        const t = this;
        const p = t.props;
        const itemData = p.itemData;

        t.setState({purchaseItemData: itemData}, () => {
            if (p.authorized) {
                t.handleAuthorizedPurchase();
            }
            else {
                const signupAndPurchase = !!parseInt(itemData.regular_price);
                p.modalAuth(t.handleAuthorizedPurchase, signupAndPurchase);
            }
        });
    };

    handleAuthorizedPurchase = () => {
        const t = this;
        const s = t.state;
        const data = s.purchaseItemData;

        if (parseInt(data.price)) {
            t.initPayment();
        }
        else {
            t.freeCoursePurchase();
        }
    };

    render() {
        const preloader = this.state.loading ? <Preloader/> : '';
        const p = this.props;
        const data = p.itemData;

        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        if (!data.tags) {
            data.tags = [];
        }
        const tags = arr2obj(data.tags, 'name');

        const productType = data.product_type;

        let title = data.name ? data.name : data.title;

        if (title && title.length && title.length > 50) {
            title = title.substring(0,50) + '...';
        }

        const pattern = GeoPattern.generate(title, {color: '#434A5C'});
        const bgStyle = {backgroundImage: pattern.toDataUrl()};
        const patternPlug = <div className="img_plug" style={bgStyle}>&nbsp;</div>;

        let img = '';
        let src = null;
        let img_cls = '';
        if (data.images_stack) {
            if (data.images_stack.cover) {
                src = data.images_stack.cover.normal;
            }
            if (data.images_stack.cover && tags.litres && data.images_stack.cover.normal) {
                src = data.images_stack.cover.normal;
                img_cls = 'book_cover';
            }
        }
        else if (data.meta && data.meta.thumbnail) {
            src = data.meta.thumbnail[0];
        }
        else if (data.image && data.image.normal) {
            src = data.image.normal;
            img_cls = 'book_cover';
        }

        if (src) {
            img = <img className={img_cls} src={src} alt="" />;
        }

        let category = '';
        if (data.categories && data.categories[0]) {
            category = data.categories[0].name;
        }
        else if (data.category) {
            category = data.category;
        }

        let lessonsCount = null;
        let lessons = '';
        if (data.lessons) {
            lessonsCount = data.lessons.length;
        }
        else if (data.lessons_count) {
            lessonsCount = data.lessons_count;
        }

        if (lessonsCount) {
            lessons = <p className="cp_lessons_count">{lessonsCount} урок{Plural(lessonsCount, '', 'а', 'ов')}</p>;

            if (productType === 'webinar') {
                lessons = <p className="cp_lessons_count">{lessonsCount} лекц{Plural(lessonsCount, 'ия', 'ии', 'ий')}</p>;
            }
        }
        let teaser = '';

        if (data.short_description) {
            teaser = <p className="teaser">{html2plaintext(data.short_description)}</p>;
        }

        let url = '/course/' + data.slug;

        if (productType === 'webinar') {
            url = '/webinar/' + data.slug
        }

        if (productType === 'video') {
            url = '/course/3/' + data.slug;
        }

        const label = <ProductTypeIcon type={productType} />;

        const rating = parseInt(data.average_rating) ? <CourseRatingIcon rating={data.average_rating} /> : '';

        const purchasedButtonText = productType === 'audio' ? 'слушать' : 'смотреть';
        let button = <Link onClick={this.handleClick} to={url} className="course_preview_link"><button className="preview">бесплатно</button></Link>;

        const discount = !!data.discount_amount_percentage;
        let discountText = discount ? <span className="discount_text">скидка {data.discount_amount_percentage} %</span> : '';

        if (discount && data.regular_price) {
            discountText = <span className="discount_text old_price">{PriceFormat(data.regular_price)} ₽</span>;
        }

        if (parseInt(data.price)) {
            const buttonCls = data.discount_amount_percentage ? 'discount' : '';
            button = <button onClick={this.handlePurchase} className={'preview ' + buttonCls}>купить за {PriceFormat(data.price)} ₽</button>;
        }

        if (data.categories[204]) {
            discountText = <span className="discount_text">Акция!</span>
        }

        if (purchased) {
            button = <Link onClick={this.handleClick} to={url} className="course_preview_link"><button className="preview">{purchasedButtonText}</button></Link>;
        }

        const stockStatus = data.stock_status;

        if (stockStatus === 'outofstock') {
            button = '';
            discountText = ''
        }

        return (
            <div className="course_featured_item">
                <Link onClick={this.handleClick} to={url} className="course_preview_link">
                    <div className="img_wrapper">
                        {patternPlug}
                        {img}
                        {label}
                        <div className="cp_params">
                            {rating}
                            {lessons}
                            <CourseLevel lvl={data.difficulty} />
                        </div>
                        <div className="cp_fade"> </div>
                    </div>
                </Link>

                <Link onClick={this.handleClick} to={url} className="course_preview_link">
                    <h4>{category}</h4>
                    <h3>{html2plaintext(title)}</h3>
                    {teaser}
                </Link>

                <div className="button_holder">
                    {button}
                    {discountText}
                </div>

                {preloader}
            </div>

        );
    }
}

export default CourseFeaturedItem;
