import React from "react";

import './index.css';
import '../styles/auth-tabs.css';

import schoolPic from './school-pic.png';
import studentPic from './student-pic.png';

import {ArrowBack} from "../utils/icons";

class SignUpRadioBlock extends React.Component {
    constructor (props) {
        super();
    }

    handleRadioChange = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.props.onRadioChange(obj);
    };

    render() {

        const p = this.props;

        return (
            <div className="radio_block">
                <div className="radio_wrapper">
                    <input checked={p.registrationType==='student'} onChange={this.handleRadioChange} value="student" id="reg_student" type="radio" name="registrationType" />
                    <label htmlFor="reg_student" className="radio_item">
                        <div className="point"></div>
                        <img src={studentPic} alt="" className="radio_pic" />
                        <h4>Учиться</h4>
                        <p>Регистрируйся, чтобы:</p>
                        <ul>
                            <li>создать аккаунт Студента на W-Hub;</li>
                            <li>проходить бесплатные курсы;</li>
                            <li>приобретать платные курсы;</li>
                            {/*<li>приобретать аудиокниги;</li>*/}
                            <li>добавлять курсы в избранное;</li>
                            <li>использовать систему автоматического сохранения прогресса;</li>
                            <li>выставлять оценки пройденным курсам;</li>

                        </ul>
                        <p className="reg_join_free">присоединиться бесплатно <ins><ArrowBack /></ins></p>
                    </label>
                </div>

                <div className="radio_wrapper">
                    <input checked={p.registrationType==='school'} onChange={this.handleRadioChange} value="school" id="reg_school" type="radio" name="registrationType" />
                    <label htmlFor="reg_school" className="radio_item">
                        <div className="point"></div>
                        <img src={schoolPic} alt="" className="radio_pic" />
                        <h4>Обучать</h4>
                        <p>Регистрируйся, чтобы:</p>
                        <ul>
                            <li>создать Онлайн-школу на W-Hub;</li>
                            <li>создавать платные и бесплатные видео- и/или онлайн-курсы;</li>
                            <li>создавать вебинары;</li>
                            <li>размещать образовательные курсы и материалы любых форматов на маркетплейсе;</li>
                            <li>использовать бесплатные сервисы, включая:<br />
                                - конструктор курсов<br />
                                - рассылку писем и сообщений<br />
                                - инструменты дизайна страницы курса
                            </li>
                            <li>использовать инструменты статистики и аналитики;</li>
                        </ul>
                        <p className="reg_join_free">присоединиться бесплатно <ins><ArrowBack /></ins></p>
                    </label>
                </div>
            </div>
        )
    }
}

export default SignUpRadioBlock;