import React from "react";
import {TipPoint} from "../icons";

import './index.css';

class SimpleDropdown extends React.Component {
    constructor (props) {
        super();

        this.state = {
            disabled: props.disabled,
            opened: false
        }
    }

    toggleOpened = () => {
        if (!this.state.disabled) {
            this.setState({opened: !this.state.opened});
        }
    };

    collapse = () => {
        this.setState({opened: false});
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    };
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    };

    handleClick = (e) => {
        e.stopPropagation();
        if (this.state.opened) {
            if (this.node.contains(e.target)) {
                return;
            }
            this.collapse();
        }
    };

    render() {
        const t = this;
        const p = t.props;
        const s = t.state;

        const cls = p.className ? p.className : '';
        const cls_opened = s.opened ? 'opened' : '';

        return (
            <div ref={node => t.node = node} className={'dropdown ' + cls + ' ' + cls_opened}>
                <span onClick={t.toggleOpened} className={'dropdown_opener'}>
                    {p.clickArea}
                </span>

                <div className="dropdown_list_wrapper">
                    <TipPoint />
                    <div className="simple_list_wrapper">
                        {p.list}
                    </div>
                </div>
            </div>
        )
    }
}

export default SimpleDropdown;