import React from 'react';
import Hammer from 'react-hammerjs';

import './courses-feed.css';
import CoursePreviewV2 from "../course-preview-v2";
import {ArrowPrev} from "../utils/icons";
import PostPreview from "../course-preview-v2/post-preview";

class CoursesFeed extends React.Component {
    constructor (props) {
        super();

        this.feedWrapper = React.createRef();
        this.feed = React.createRef();

        this.state = {
            currentTab: props.currentTab ? props.currentTab : null,
            curStep: 0,
            stepSize: 336,
            firstEl: props.firstElement ? 1 : 0,
            prevHidden: true,
            nextHidden: false
        };
    }

    initialArrowsCheck = () => {
        const t = this;
        const p = t.props;
        const s = t.state;

        const wrapper = t.feedWrapper.current;
        const wrapperWidth = wrapper.clientWidth;
        const feed = t.feed.current;
        let feedWidth = feed.clientWidth;

        if (window.innerWidth <= 600) {
            feedWidth -= Math.floor(window.innerWidth/10);
        }

        let itemsLength = p.courses.length;

        if (p.firstElement) {
            itemsLength += 1;
        }

        const itemWidth = feedWidth/itemsLength;
        const itemsVisible = Math.floor(wrapperWidth/itemWidth);

        if (itemWidth) {

            t.setState({stepSize: itemWidth})
        }
        if (itemsLength <= itemsVisible) {
            t.setState({prevHidden: true, nextHidden: true});
        }
        else {
            if (s.curStep <= 0) {
                t.setState({prevHidden: true, nextHidden: false});
            }
            else if (s.curStep >= itemsLength - itemsVisible) {
                t.setState({prevHidden: false, nextHidden: true});
            }
            else {
                t.setState({prevHidden: false, nextHidden: false});
            }
        }
    };

    slideNext = (dir) => {
        const t = this;
        const p = t.props;
        const s = t.state;

        const wrapper = t.feedWrapper.current;
        const wrapperWidth = wrapper.clientWidth;
        const feed = t.feed.current;
        let feedWidth = feed.clientWidth;

        if (window.innerWidth <= 600) {
            feedWidth -= Math.floor(window.innerWidth/10);
        }

        let itemsLength = p.courses.length;

        if (p.firstElement) {
            itemsLength += 1;
        }

        const itemWidth = feedWidth/itemsLength;
        const itemsVisible = Math.floor(wrapperWidth/itemWidth);

        let next = s.curStep + dir;

        if (next >= 0 && next <= itemsLength - itemsVisible) {
            t.setState({curStep: next}, t.initialArrowsCheck);
        }
    };

    componentDidMount = () => {
        this.initialArrowsCheck();
    };
    componentDidUpdate = () => {
        this.checkCurrentTab();
    };

    checkCurrentTab = () => {
        const t = this;
        const s = t.state;
        const p = t.props;
        if (s.currentTab && s.currentTab !== p.currentTab) {
            t.setState({currentTab: p.currentTab, curStep: 0}, t.initialArrowsCheck);
        }
    };

    render() {
        const s = this.state;
        const p = this.props;

        const firstElement = p.firstElement ? p.firstElement : '';
        const feedData = p.courses;
        let feed;

        if (p.feedType && p.feedType === 'blog_posts') {
            feed = feedData.map((post, idx) =>{
                if (post && post.id) {
                    return <PostPreview {...p} itemData={post} itemId={idx} key={post.id} />
                }
                return '';
            });
        }
        else {
            feed = feedData.map((course, idx) => {
                if (course && course.id) {
                    return <CoursePreviewV2 {...p} itemData={course} key={course.id} itemId={idx} />;
                }
                return '';
            });
        }


        const arrPrev = !s.prevHidden ? <div onClick={() => this.slideNext(-1)} className="arr arr_prev"><i><ArrowPrev /></i></div> : '';
        const arrNext = !s.nextHidden ? <div onClick={() => this.slideNext(1)} className="arr arr_next"><i><ArrowPrev /></i></div> : '';

        const style = {left: s.curStep * s.stepSize * -1};

        const options = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        const cls_single_gift = p.singleGiftItem ? 'single_gift' : '';

        return (
            <div className={'feed_block_outer ' + cls_single_gift}>
                {arrPrev}
                {arrNext}
                <div className="feed_block_wrapper" ref={this.feedWrapper}>
                    <div style={style} className="feed_block" ref={this.feed}>
                        <Hammer
                            direction={'DIRECTION_HORIZONTAL'}
                            options={options}
                            onSwipeLeft={() => this.slideNext(1)}
                            onSwipeRight={() => this.slideNext(-1)}>
                            <div>
                                {firstElement}
                                {feed}
                            </div>
                        </Hammer>
                    </div>
                </div>
            </div>

        );
    }
}

export default CoursesFeed;
