import React from 'react';
import PlayerV2 from "../../utils/player-v2";
import Tracker from "../../utils/tracker";

import './video.css';

class LessonVideo extends React.Component {
    constructor (props) {
        super();

        this.state = {
            started: false
        }
    }

    render() {
        const t = this;
        const p = t.props;
        const {content, courseData, lessonId, authorized, progress} = p;
        const {started} = t.state;

        const isCode = !content.url.match(/https:\/\//g) && !content.url.match(/http:\/\//g);

        const externalSrc = isCode ? 'https://youtu.be/' + content.url : content.url;
        const cloudSrc = content.embed_link ? content.embed_link : '';

        const src = content.is_cloud ? cloudSrc : externalSrc;

        const player_iframe = <iframe src={src}
                                      frameBorder="0" title="video_iframe"
                                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen></iframe>;

        const player_v2 = <PlayerV2
            courseId={courseData.id}
            lessonId={lessonId}
            url={src}
            authorized={authorized}
            progress={progress}
            onPlayStarted={() => t.setState({started: true})} />;

        const player = content.is_cloud ? player_iframe : player_v2;

        const tracker = started ? <Tracker id={courseData.id} event="videoStarted" childId={lessonId} /> : '';

        return (
            <div className="player_wrapper">
                <div className="player_inner">
                    {player}
                    {tracker}
                </div>
            </div>
        )
    }
}

export default LessonVideo;
