import React from 'react';

import API from "../utils/api";
import {PriceFormat} from "../utils/utils";

import {Link} from "react-router-dom";
//import { withId } from 'react-yandex-metrika';
import Modal from "../modal";

class CourseButtonsBlock extends React.Component {
    constructor (props) {
        super();

        this.state = {
            favourite: props.userData.favourites && props.userData.favourites.indexOf(props.courseData.id) > -1,
            showGiftModal: false,
            showAbuseModal: false
        };
    }

    initPayment = () => {
        const t = this;
        const p = t.props;

        p.setLoading(true);

        API.post('/med/v3/order', {product_id: t.props.courseData.id, payment_method: 'ukassa'})
            .then((r) => {
                p.setLoading(false);
                const purchaseLink = r.data.payment_data.payment_url;
                p.modalPurchase(purchaseLink);
                window.open(purchaseLink);

                //withId(62510686)('reachGoal','purchase');
            })
            .catch((error) => {
                p.setLoading(false);
                alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
                console.log(error);
            });
    };

    handlePurchaseClick = (event) => {
        const t = this;
        const p = t.props;
        const data = p.courseData;
        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        if (!purchased) {
            if (p.authorized) {
                t.handleAuthorizedPurchase();
            }
            else {
                if (event) {
                    event.preventDefault();
                }
                const signupAndPurchase = !!parseInt(data.regular_price);
                p.modalAuth(t.handleAuthorizedPurchase, signupAndPurchase);
            }
        }
    };

    handleAuthorizedPurchase = () => {
        const t = this;
        const p = t.props;
        const data = p.courseData;

        if (parseInt(data.regular_price)) {
            t.initPayment();
        }
        else {
            p.freeCoursePurchase();
        }
    };

    renderPurchasedButton = () => {
        const t = this;
        const p = t.props;
        const data = p.courseData;

        if (p.progress) {
            if (p.courseType === 'audio') {
                return <button onClick={p.initPlayerFunc} disabled={p.initPlayerStatus}>продолжить</button>;
            }
            else {
                return <Link to={'/course/' + data.slug + '/' + p.progress.file_index}><button>продолжить</button></Link>;
            }
        }

        if (p.courseType === 'audio') {
            return <button onClick={p.initPlayerFunc} disabled={p.initPlayerStatus}>слушать</button>;
        }

        if (!data.lesson) {
            return '';
        }

        return <Link to={'/course/' + data.slug + '/' + data.lessons[0].id}><button>смотреть</button></Link>;
    };

    renderBuyButton = (promo) => {
        if (!promo) {
            promo = false;
        }
        const t = this;
        const p = t.props;
        const data = p.courseData;
        const discount = !!parseInt(data.discount_amount_percentage);
        const price = discount ? data.price : data.regular_price;
        let purchaseText = discount ? 'купить со скидкой' : 'купить';

        if (promo) {
            purchaseText = 'купить';
        }

        if (!parseInt(Math.ceil(price))) {
            return t.renderPurchasedButton();
        }

        const outputPrice = PriceFormat(parseInt(Math.ceil(price)));

        return <button onClick={t.handlePurchaseClick}>{purchaseText} <span>за {outputPrice} ₽</span></button>
    };

    handleFavouriteClick = () => {
        const t = this;
        const s = t.state;
        const p = t.props;

        if (p.authorized && !s.favourite) {
            t.addFavourite();
        }
        else {
            p.modalAuth(t.handleFavouriteClick, false);
        }
    };

    addFavourite = () => {
        const t = this;
        const data = t.props.courseData;
        API.post('/med/v1/favorite/' + data.id)
            .then(function (r) {
                t.setState({favourite: true})
                t.props.authFunc();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    handleGiftClick = () => {
        this.setState({showGiftModal: true});
    };

    giftPurchase = (email) => {
        const t = this;
        const p = t.props;

        t.setState({showGiftModal: false});
        p.setLoading(true);

        API.post('/med/v3/order', {product_id: t.props.courseData.id, payment_method: 'ukassa', gift_recipient: email})
            .then((r) => {
                p.setLoading(false);
                const purchaseLink = r.data.payment_data.payment_url;
                p.modalPurchase(purchaseLink);
                window.open(purchaseLink);

                //withId(62510686)('reachGoal','purchase');
            })
            .catch((error) => {
                p.setLoading(false);
                alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
                console.log(error);
            });
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;
        const data = p.courseData;
        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        const discount = !!parseInt(data.discount_amount_percentage);
        let discountText = discount && !purchased ? <p className="discount_disclamer">На этот товар действует скидка {data.discount_amount_percentage}%</p> : '';
        let promo = false;

        if (data.categories[204]) {
            discountText = <p className="discount_disclamer">Этот товар продается по акции!</p>;
            promo = true;
        }

        const btnPurchased = purchased ? t.renderPurchasedButton() : '';
        const btnBuy = !purchased ? t.renderBuyButton(promo) : '';

        const price = discount ? data.price : data.regular_price;
        const btnGift = p.authorized && parseInt(Math.ceil(price)) ? <button onClick={t.handleGiftClick}>купить в подарок</button> : '';
        const giftModal = s.showGiftModal ? <Modal modalContent={'gift'} onSuccess={t.giftPurchase} onModalClose={() => t.setState({showGiftModal: false})} /> : '';

        const favouriteText = s.favourite ? 'в избранном' : 'в избранное';
        const btnFavourites = <button onClick={t.handleFavouriteClick} disabled={s.favourite} className="light_border">{favouriteText}</button>;

        const outofstock = data.stock_status && data.stock_status === "outofstock";
        const outofstock_overlay = outofstock ? <div className="outofstock_overlay"><p>К сожалению, эта книга недоступна</p></div> : '';

        const cls_discount = discount ? 'discount' : '';

        const abuseModal = s.showAbuseModal ? <Modal {...p} modalContent={'abuse'} onModalClose={() => t.setState({showAbuseModal: false})} /> : '';

        return (
            <div className={'buttons_block ' + cls_discount}>
                {discountText}

                {btnBuy}
                {btnGift}
                {giftModal}
                {btnPurchased}

                {btnFavourites}

                <button onClick={() => t.setState({showAbuseModal: true})}>пожаловаться</button>
                {abuseModal}

                {outofstock_overlay}
            </div>
        );
    }
}

export default CourseButtonsBlock;
