import React from "react";

import '../../styles/react-datepicker.css';
import './general.css';
import API from "../../utils/api";
import Preloader from "../../utils/preloader";
import {arr2obj, html2plaintext, Plural} from "../../utils/utils";

import TextareaAutosize from 'react-autosize-textarea';
import Dropdown from "../../utils/dropdown/dropdown";
import Checkbox from "../../utils/checkbox/checkbox";
import Modal from "../../modal";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import {addDays} from "@progress/kendo-date-math";

class EditWebinarsGeneral extends React.Component {
    constructor(props) {
        super();

        const dynamicDataList = [
            'courseName',
            'courseDescription',
            'courseShortDescription',
            'categories',
            'difficulty',
            'webinarStart',
            'availableForSubscribe',
            'participantsAmount'
        ];

        const difficulties = [
            {value: 1, label: 'Начинающий'},
            {value: 2, label: 'Средний'},
            {value: 3, label: 'Продвинутый'}
        ];

        const webinarData = props.courseData;

        this.state = {
            loading: false,
            modal: false,
            valid: false,
            data: webinarData,
            courseId: webinarData.id,
            courseName: '',
            courseDescription: '',
            courseShortDescription: '',
            shortDescriptionLength: 0,
            addVerticalPreview: false,
            categories: [{}],
            difficulies: difficulties,
            difficulty: 0,
            originalState: {},
            dynamicDataList: dynamicDataList,
            firstTimeEdit: props.courseData.name === '',
            webinarStart: webinarData.date_start ? new Date(webinarData.date_start*1000) : addDays(new Date(), 3),
            availableForSubscribe: webinarData.is_available_for_subscribe,
            participantsAmount: webinarData.participants_amount
        };
    }

    checkChanges = () => {
        return JSON.stringify(this.state.originalState) === JSON.stringify(this.mapCurrentState());
    };

    validate = () => {
        const s = this.state;
        const noChanges = this.checkChanges();

        const test = s.courseName
            && s.courseName.length
            && s.courseDescription
            && s.courseDescription.length
            && s.courseShortDescription
            && s.courseShortDescription.length && !noChanges;

        this.setState({valid: test});
    };

    renderData = () => {
        const s = this.state;
        const rName = s.data.name;

        const state = {
            courseName: rName,
            courseDescription: html2plaintext(s.data.description),
            shortDescriptionLength: s.data.short_description.length,
            courseShortDescription: html2plaintext(s.data.short_description),
            categories: s.data.categories,
            difficulty: s.data.difficulty,
            loading: false,
            originalState: {}
        };

        this.setState(state, this.afterRender);
    };

    afterRender = () => {
        this.updateOriginalState(this.validate);
    };

    mapCurrentState = () => {
        const s = this.state;
        return s.dynamicDataList.reduce(function (map, item) {
            map[item] = s[item];
            return map;
        }, {});
    };

    updateOriginalState = (cb) => {
        const originalState = this.mapCurrentState();

        this.setState({originalState: originalState}, cb)
    };

    saveChanges = (cb) => {
        const t = this;
        const s = t.state;
        let newData = {
            product_type: 'webinar',
            name: s.courseName,
            description: s.courseDescription,
            short_description: s.courseShortDescription,
            difficulty: s.difficulty,
            date_start: s.webinarStart.getTime() / 1000,
            is_available_for_subscribe: s.availableForSubscribe,
            participants_amount: s.participantsAmount
        };

        if (s.data.status === 'publish') {
            newData.edit = 1;
        }
        if (s.categories[0].id) {
            newData.categories = s.categories;
        }

        t.setState({loading: true});
        API.put('/med/v1/course/update/' + s.courseId, newData)
            .then(function (r) {
                t.setState({loading: false}, t.afterRender);
                t.updateLocalData({data: r.data});
                t.props.reloadUserData();

                if (cb && typeof cb === "function") {
                    cb();
                }

                if (s.firstTimeEdit) {
                    t.setState({firstTimeEdit: false, modal: {
                            modalData: {
                                content: 'text',
                                text: 'Теперь вы можете загрузить обложки или добавить лекции в расписание вашего вебинара!',
                                additional_buttons: [
                                    {
                                        button_text: 'оформление',
                                        button_url: '/webinar/' + s.courseId + '/edit/covers'
                                    },
                                    {
                                        button_text: 'расписание',
                                        button_url: '/webinar/' + s.courseId + '/edit/timetable'
                                    }
                                ]
                            }
                        }})
                }

            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    updateLocalData = (opts) => {
        const s = this.state;
        const updatedData = {
            product_type: 'webinar',
            name: s.courseName,
            description: s.courseDescription,
            short_description: s.courseShortDescription,
            difficulty: s.difficulty,
            id: s.courseId
        };

        if (s.categories[0].id) {
            updatedData.categories = s.categories;
        }

        if (s.data.status === 'publish') {
            updatedData.edit = 1;
        }

        if (!opts) {
            opts = {};
        }

        let newData = s.data;
        newData.name = updatedData.name;
        newData.description = updatedData.description;
        newData.short_description = updatedData.short_description;
        newData.difficulty = updatedData.difficulty;

        if (updatedData.categories) {
            newData.categories = updatedData.categories;
        }

        if (opts.data) {
            newData = opts.data;
        }

        const autoSave = s.valid && opts.leave ? updatedData : null;

        this.setState({data: newData, valid: false});
        this.props.updateData(newData, autoSave);
    };

    componentDidMount() {
        this.renderData();
    };

    componentWillUnmount() {
        this.updateLocalData({leave: true});
    };

    handleTextInput = (e) => {
        const t = this;
        const obj = {};

        obj[e.target.name] = e.target.value;
        t.setState(obj, t.validate);

        if (e.target.name === 'courseName') {
            this.props.updateData({name: e.target.value});
        }
    };

    handleLinebreak = (e) => {
        if (e.which === 13) {
            e.preventDefault();
        }
    };

    handleShortDescriptionInput = (e) => {
        this.setState({shortDescriptionLength: e.target.innerHTML.length})
    };

    handleCategoryChange = (d) => {
        const update = [{id: d.data.value, name: d.data.label}];
        this.setState({categories: update}, this.validate);
        // const params = this.state.catData;
        // const t = this;
        // if (d.name === 'category') {
        //     params.subcategory = null;
        // }
        // params[d.name] = d.data.value;
        //
        // t.setState({catData:{category: null, subcategory: null}}, function () {
        //     t.props.catSelect(params);
        //     t.props.paramsFunc(params);
        //     t.setState({catData: params});
        // })
    };

    handleDifficultyChange = (d) => {
        this.setState({difficulty: d.data.value}, this.validate);
        this.updateLocalData();
    };

    handleParticipantsAmountChange = (d) => {
        this.setState({participantsAmount: d.data.value}, this.validate);
        this.updateLocalData();
    };

    getSelectedOption = (arr, val) => {
        const obj = arr2obj(arr, 'value');
        if (obj[val]) {
            return obj[val];
        }
        return arr[0];
    };

    makeCatsDropdownArr = (obj, first) => {
        const arr = [first];

        Object.keys(obj).forEach((n) => {
            const el = obj[n];
            if (el.id !== 181 && el.id !== 182) {
                arr.push({label: el.name, value: el.id});

            }
        });

        return arr;
    };

    handleCheckbox = () => {
        this.props.toggleFreeCourse();
    };

    toggleSubscribeOption = () => {
        const t = this;
        const s = t.state;

        t.setState({availableForSubscribe: !s.availableForSubscribe}, t.validate);
    };

    render() {
        const t = this;
        const s = t.state;
        const preloader = s.loading ? <Preloader/> : '';

        const maxShortDescriptionLength = 300;
        const remainingSymbols = maxShortDescriptionLength - s.shortDescriptionLength;

        const catsDefaultOption = {label: 'выбрать', value: null};
        const catsDropdownArr = t.makeCatsDropdownArr(t.props.categories, catsDefaultOption);
        const catSelected = this.getSelectedOption(catsDropdownArr, s.categories[0].id);

        const subcatsDefaultOption = [{label: 'выбрать', value: null}];

        const difficultyDefaultOption = [{label: 'выбрать', value: null}];
        const difficultyDropdownArr = difficultyDefaultOption.concat(s.difficulies);
        const difficultySelected = this.getSelectedOption(difficultyDropdownArr, parseInt(s.difficulty));

        const participantsOptions = [
            {value: 25, label: 25},
            {value: 50, label: 50},
            {value: 75, label: 75},
            {value: 100, label: 100},
            {value: 125, label: 125},
            {value: 150, label: 150},
            {value: 175, label: 175},
            {value: 200, label: 200},
        ];
        const participantsDefaultOption = [{label: 'выбрать', value: null}];
        const participantsDropdownArr = participantsDefaultOption.concat(participantsOptions);
        const participantsSelected = this.getSelectedOption(participantsDropdownArr, parseInt(s.participantsAmount));

        const modal = s.modal ? <Modal modalContent={s.modal.modalData.content} modalData={s.modal.modalData} onModalClose={() => t.setState({modal: null})} /> : '';

        return (
            <div className="webinars_edit_block web_general">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" autoFocus={true} onKeyDown={t.handleLinebreak} onChange={t.handleTextInput} value={s.courseName} name="courseName" placeholder="название вебинара" />
                    <div className="course_form_hint">название вебинара рекомендуется делать максимально кратким и понятным
                    </div>
                </div>

                <div className="course_parametres_block">
                    <div className="cpb_item">
                        <h5>категория</h5>
                        <Dropdown onChange={this.handleCategoryChange} name="category" className="dd_cats" selected={catSelected} ddArr={catsDropdownArr} />
                    </div>

                    <div className="cpb_item">
                        <h5>подкатегория</h5>
                        <Dropdown disabled={true} name="subcategory" className="dd_cats" selected={subcatsDefaultOption[0]} ddArr={subcatsDefaultOption} />
                    </div>

                    <div className="cpb_item">
                        <h5>уровень студента</h5>
                        <Dropdown onChange={this.handleDifficultyChange} name="difficulty" className="dd_cats" selected={difficultySelected} ddArr={difficultyDropdownArr} />
                    </div>

                </div>

                <div className="course_parametres_block">

                    <div className="cpb_item">
                        <h5>количество участников</h5>
                        <Dropdown onChange={this.handleParticipantsAmountChange} name="participants_amount" className="dd_cats" selected={participantsSelected} ddArr={participantsDropdownArr} />
                    </div>
                    <div className="cpb_item on_top">
                        <h5>старт вебинара</h5>
                        <DatePicker
                            className="text_input"
                            selected={s.webinarStart}
                            locale={ru}
                            minDate={addDays(new Date(), 3)}
                            onChange={date => t.setState({webinarStart: date}, () => t.validate())}
                            showTimeSelect
                            timeCaption="время"
                            dateFormat="dd.MM.yyyy HH:mm"
                        />
                    </div>
                    <div className="cpb_item">
                        <Checkbox onChange={t.toggleSubscribeOption} name="subscribe_option_tpggler" checked={s.availableForSubscribe} label={'доступен для записи'} />

                    </div>
                </div>

                <div className="course_description_block">
                    <div className="ce_grid">
                        <div className="ce_grid_block ce_left_block">
                            <h5>краткое описание вебинара</h5>
                            <textarea maxLength={maxShortDescriptionLength} onKeyUp={t.handleShortDescriptionInput} onChange={t.handleTextInput} name="courseShortDescription" value={s.courseShortDescription} />
                            <div className="course_form_hint">осталось {remainingSymbols} символ{Plural(remainingSymbols, '', 'а', 'ов')}</div>
                        </div>
                        <div className="ce_grid_block ce_right_block">
                            <h5>полное описание вебинара</h5>
                            <textarea onChange={t.handleTextInput} name="courseDescription" value={s.courseDescription} />
                            <div className="course_form_hint">не стесняйтесь, люди любят читать</div>
                        </div>
                    </div>
                </div>

                <div className="ce_button_holder">
                    <button onClick={t.saveChanges} disabled={!s.valid}>Сохранить</button>
                </div>

                {modal}
                {preloader}
            </div>
        );
    }
}

export default EditWebinarsGeneral;