import React from 'react';
import ReactTooltip from "react-tooltip";
import PlayerV2 from "../../../../../utils/player-v2";

import './video.css';
import HostingHint from "./hosting-hint";

class LessonTypeVideo extends React.Component {
    constructor (props) {
        super();

        const content = props.content;

        const url = content.url ? content.url : '';

        this.state = {
            external_check: !!url.length,
            url: url,
            originalUrl: url.slice(),
            is_cloud: false,
            valid: false,
            changes: false,
            hostingHintVisible: false
        }
    }

    componentDidMount() {
        this.validate();
    };

    switchVideoSection = (e) => {
        const t = this;
        const p = t.props;
        const plan = p.userData.subscription_plan;
        const v = parseInt(e.currentTarget.getAttribute('data-cloud'));

        if (plan === 'free' && v) {
            alert('Для использования облака необходимо оплатить подписку!');
        }
        else if (p.freeCourse && v) {
            alert('Для бесплатных курсов облачный функционал недоступен!');
        }
        else {
            this.setState({is_cloud: !!v}, this.validate);
        }
    };

    handleExternalSourceInput = (e) => {
        const t = this;
        t.setState({external_check: false});
        this.handleTextInput(e);
    };

    handleCanNotPlay = () => {
        this.setState({external_check: false}, this.validate);
        alert('воспроизведение видео по этой ссылке невозможно!');
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    checkUrlChange = () => {
        const t = this;
        const {url, originalUrl} = t.state;
        return url !== originalUrl;
    };

    readOnLoad = (file, cb) => (e) => {
        const video = document.createElement('video');

        const getMeta = () => {
            if (video.readyState >= 2) {
                cb(video)
            }
        };

        video.addEventListener('loadedmetadata', getMeta);

        video.src = URL.createObjectURL(file);
    };

    validateVideo = (file, cb) => {

        if (file.type !== 'video/mp4') {
            alert('На данный момент мы поддерживаем видеофайлы исключительно в формате mp4!');
            cb(false);
        }
        else {
            const reader = new FileReader();
            reader.onload = this.readOnLoad(file, (video) => {
                if (video.videoWidth >=1280 && video.videoHeight >=720) {
                    cb(true);
                }
                else if (!video.videoWidth && !video.videoHeight) {
                    alert('К сожалению, метаданные этого файла недоступны и мы не можем загрузить его в облако... ');
                }
                else {
                    alert('Минимальное допустимое разрешение видеофайла - 1280х720!');
                    cb(false);
                }
            });
            reader.readAsText(file);
        }
    };

    handleFile = (e) => {
        const file = e.target.files[0] ? e.target.files[0] : null;
        this.setState({file_name: file.name});
        if (file) {
            this.validateVideo(file, (valid) => {
                if (valid) {
                    this.setState({file: file}, this.validate);
                }
            });
        }
        else {
            alert('ошибочка вышла');
        }
    };

    validate = () => {
        const t = this;
        const p = t.props;
        const {url, originalUrl, file, external_check, is_cloud} = t.state;

        const codeTest = (url.length && external_check) || (is_cloud && (file || !p.data.id));

        t.setState({valid: codeTest, changes: (url !== originalUrl) || file}, t.updateLesson);
    };

    updateLesson = () => {
        const t = this;
        const {updateTypeContent} = t.props;
        const {url, is_cloud, file, valid, changes} = t.state;

        const d = {
            content: {
                url: url,
                is_cloud: is_cloud
            },
            state: {
                valid: valid,
                changes: changes
            }
        };

        if (file) {
            d.file = file;
        }

        updateTypeContent(d)
    };

    toggleHint = () => {
        const t = this;
        const {hostingHintVisible} = t.state;
        t.setState({hostingHintVisible: !hostingHintVisible})
    };

    render() {
        const t = this;
        const {updateDuration} = t.props;
        const {external_check, url, hostingHintVisible} = t.state;

        const hintTogglerText = hostingHintVisible ? 'скрыть рекомендуемые сервисы' : 'показать рекомендуемые сервисы';
        const hostingHint = <div className="course_form_hint">вставьте в это поле ссылку на ваше видео, которое может храниться на видеохостинге или в облачном хранилище. <br />храните свой контент там, где вам удобно! <br /><span onClick={t.toggleHint} className="pseudo">{hintTogglerText}</span></div>;

        return (
            <div>
                <dl>
                    <dt className="eli_dt_video_label">Ссылка на видео:</dt>
                    <dd className="eli_video">
                        <div className="external_source_field_wrapper">
                            <input className="upload_bar" type="text" value={url} name="url" onChange={t.handleExternalSourceInput} />
                            {t.checkUrlChange() && !external_check && url.length ? <button className="small btn_check_source" onClick={() => t.setState({external_check: true}, t.validate)}>проверить</button> : ''}

                            {t.checkUrlChange() && !external_check && url.length ? <div className="course_form_hint">перед сохранением необходимо проверить ссылку!</div> : ''}
                            {external_check ? <div className="course_form_hint">если видео не запускается - попробуйте проверить видео <a target="_blank" rel="noreferrer noopener" href={url}>по вашей ссылке</a>, возможно, оно недоступно, либо защищено от размещения на сторонних доменах</div> : hostingHint}
                            {!external_check && hostingHintVisible ? <HostingHint /> : ''}
                            {external_check ? <PlayerV2
                                url={url}
                                onDuration={updateDuration}
                                onCanNotPlay={t.handleCanNotPlay}/> : ''}
                        </div>

                        <ReactTooltip delayShow={0} effect={'solid'} multiline={true} />
                    </dd>
                </dl>
            </div>
        );
    }
}

export default LessonTypeVideo;
