import React from 'react';
import Hammer from 'react-hammerjs';

import './index.css';
import {ArrowPrev} from "../utils/icons";
import CourseFeaturedItem from "./course-featured-item";
import CourseFeaturedAd from "./course-featured-ad";

class CoursesFeaturedV2 extends React.Component {
    constructor (props) {
        super();

        this.feedWrapper = React.createRef();
        this.feed = React.createRef();

        this.state = {
            currentTab: props.currentTab ? props.currentTab : null,
            curStep: 0,
            stepSize: 640,
            steps: 1,
            prevHidden: true,
            nextHidden: false
        };
    }

    initialArrowsCheck = () => {
        const t = this;
        const p = t.props;
        const s = t.state;

        const wrapper = t.feedWrapper.current;
        const wrapperWidth = wrapper.clientWidth;
        const feed = t.feed.current;
        let feedWidth = feed.clientWidth;

        const steps = Math.ceil(feedWidth / wrapperWidth);

        let itemsLength = p.data.length;

        const itemWidth = feedWidth/itemsLength;
        const itemsVisible = Math.floor(wrapperWidth/itemWidth);

        t.setState({stepSize: wrapperWidth, steps: steps})
        if (itemsLength <= itemsVisible) {
            t.setState({prevHidden: true, nextHidden: true});
        }
        else {
            if (s.curStep <= 0) {
                t.setState({prevHidden: true, nextHidden: false});
            }
            else if (s.curStep >= steps-1) {
                t.setState({prevHidden: false, nextHidden: true});
            }
            else {
                t.setState({prevHidden: false, nextHidden: false});
            }
        }
    };

    slideNext = (dir) => {
        const t = this;
        const s = t.state;

        const next = s.curStep + dir;

        if (next >= 0 && next < s.steps) {
            t.setState({curStep: next}, t.initialArrowsCheck);
        }
    };

    componentDidMount = () => {
        this.initialArrowsCheck();
    };

    featuredSteps = (steps, cur) => {
        const t = this;
        let arr = [];

        for (let i=0;i<steps;i++) {
            arr.push(
                <i onClick={() => t.setState({curStep: i}, t.initialArrowsCheck)} key={i} className={i===cur ? 'active' : ''}> </i>
            );
        }

        return arr;
    };

    render() {
        const s = this.state;
        const p = this.props;

        const feedData = p.data;
        const feed = feedData.map((course, idx) => {
            if (course.advert) {
                return <CourseFeaturedAd data={course} key={course.id} />;
            }
            return <CourseFeaturedItem {...p} itemData={course} key={course.id} itemId={idx} />;
        });


        const arrPrev = !s.prevHidden ? <div onClick={() => this.slideNext(-1)} className="arr arr_prev"><i><ArrowPrev /></i></div> : '';
        const arrNext = !s.nextHidden ? <div onClick={() => this.slideNext(1)} className="arr arr_next"><i><ArrowPrev /></i></div> : '';

        const style = {left: this.state.curStep * s.stepSize * -1};

        const options = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        return (
            <div className="featured_feed_holder">
                <div className="featured_feed_outer">
                    {arrPrev}
                    {arrNext}
                    <div className="featured_feed_wrapper" ref={this.feedWrapper}>
                        <div style={style} className="featured_feed_block" ref={this.feed}>
                            <Hammer
                                direction={'DIRECTION_HORIZONTAL'}
                                options={options}
                                onSwipeLeft={() => this.slideNext(1)}
                                onSwipeRight={() => this.slideNext(-1)}>
                                <div>
                                    {feed}
                                </div>
                            </Hammer>
                        </div>
                    </div>
                    <div className="featured_steps">{this.featuredSteps(s.steps, s.curStep)}</div>
                </div>
            </div>

        );
    }
}

export default CoursesFeaturedV2;
