import React, {useState} from "react";
import {useParams} from "react-router-dom";

import './finish.css';
import Preloader from "../../utils/preloader";
import API from "../../utils/api";
import TextareaAutosize from "react-autosize-textarea";
import CoursePreviewV2 from "../../course-preview-v2";


function EditCourseFinish(props) {
    let pageParams = useParams();
    const data = props.courseData;

    const [loading, setLoading] = useState(false);
    const [published, setPublished] = useState(data.status === 'publish');

    const courseId = pageParams.id;

    const updateGlobalData = () => {
        setLoading(true);
        props.authFunc(setLoading(false));
    };

    const publishCourse = () => {
        const d = {id: courseId};

        setLoading(true);
        API.put('/med/v1/course/publish/' + courseId, d)
            .then(function (r) {
                setLoading(false);
                setPublished(true);
                updateGlobalData();
            })
            .catch(function (error) {
                setLoading(false);
                alert(error)
            });
    };

    const hideCourse = () => {
        setLoading(true);
        API.put('/med/v1/course/update/' + courseId, {status: 'draft'})
            .then(function (r) {
                setLoading(false);
                setPublished(false);
                props.updateData({status: 'draft'});
                updateGlobalData();

            })
            .catch(function (error) {
                setLoading(false);
                alert(error)
            });
    };

    const preloader = loading ? <Preloader/> : '';
    const buttonText = published ? 'Опубликовано' : 'Опубликовать';
    const hideButton = published ? <button onClick={hideCourse}>Скрыть</button> : '';


    return (
        <div className="ceb_finish">

            <div className="course_name_block">
                <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={props.courseData.title} name="courseName" placeholder="название курса" />
            </div>

            <div className="courses_block">
                <CoursePreviewV2 itemData={data} itemId={data.id} key={0} />
            </div>

            <div className="ce_button_holder">
                <button onClick={publishCourse} disabled={published}>{buttonText}</button>
                {hideButton}
            </div>

            {preloader}
        </div>
    );
}

export default EditCourseFinish;