import React from 'react';

import './index.css';
import API from "../api";
import Preloader from "../preloader";

class ImgUpload extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false
        }
    }

    componentDidMount = () => {

    };

    handleUpload = (e) => {
        const t = this;
        const file = e.target.files[0];
        const maxSize = 8*1024*1024;
        if (file) {
            if (file.size > maxSize) {
                alert('Максимальный вес загружаемого изображения - 8 MB!');
                return false;
            }
            const type = t.props.imgType;

            const formData = new FormData();

            formData.append('file', file);
            formData.append('image_type', type);
            formData.append('course_id', t.props.courseId);

            t.setState({loading: true});

            API.post('/med/v3/media', formData)
                .then(function (response) {
                    t.setState({loading: false});
                    t.props.onUpload();
                })
                .catch(function (error) {
                    t.setState({loading: false});
                    alert(error)
                });

        }

    };

    render() {
        const preloader = this.state.loading ? <Preloader/> : '';
        return (
            <label htmlFor={this.props.id} className={'img_upload_block ' + this.props.className}>
                <input type="file" accept="image/*" id={this.props.id} onChange={this.handleUpload} />
                {preloader}
            </label>
        );
    }
}

export default ImgUpload;