import React from 'react';

import './support.css';
import API from "../utils/api";
import Preloader from "../utils/preloader";
import {EmailTest} from "../utils/utils";

class AbuseModal extends React.Component {
    constructor (props) {
        super();

        this.state = {
            name: '',
            email: '',
            body: '',
            loading: false,
            valid: false,
            message: ''
        }
    }

    handleSubmit = () => {
        const t = this;
        const s = t.state;
        const data = {
            name: s.name,
            email: s.email,
            message: s.body,
            url: document.location.href
        };

        t.setState({loading: true});
        API.post('/med/v1/abuse\n', data)
            .then(function (r) {
                if (r.data.code && parseInt(r.data.code) === 200) {
                    t.setState({message: <span>Спасибо за ваше обращение!<br />В ближайшее время мы свяжемся с вами!</span>})
                }
                else if (r.data.message) {
                    t.setState({message: r.data.message})
                }
                t.setState({loading: false});
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                t.setState({loading: false});
            });
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);

    };

    validate = () => {
        const t = this;
        const s = t.state;

        if (s.name && s.email && EmailTest(s.email)) {
            t.setState({valid: true})
        }
        else {
            t.setState({valid: false})
        }
    };

    componentDidMount = () => {
        const t = this;
        const p = t.props;

        if (p.authorized) {
            t.setState({email: p.userData.email});
        }
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;
        const preloader = s.loading ? <Preloader /> : '';

        const form = <div className="ffm_inner">
            <div className="default_input_wrapper">
                <input onChange={t.handleTextInput} type="text" name="name" placeholder=" " />
                <label>имя</label>
            </div>
            <div className="default_input_wrapper">
                <input onChange={t.handleTextInput} type="text" name="email" placeholder=" " disabled={p.authorized} value={s.email} />
                <label>e-mail</label>
            </div>
            <div className="default_input_wrapper">
                <textarea onChange={t.handleTextInput} name="body" placeholder=" "></textarea>
                <label className="up">сообщение</label>
            </div>
            <button disabled={!s.valid} onClick={this.handleSubmit}>отправить</button>
        </div>;

        const afterMessage = <div className="ffm_after_message">
            <p>{s.message}</p>
            <button onClick={t.props.onModalClose}>закрыть</button>
        </div>;

        const content = s.message ? afterMessage : form;

        return (
            <div className="feedback_form_modal">
                {content}
                {preloader}
            </div>
        );
    }
}

export default AbuseModal;
