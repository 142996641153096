import React, { useState, useEffect } from 'react';

import {useParams, useLocation} from 'react-router-dom';

import './course.css';
import API from "../utils/api";
import Error404 from "../utils/404";
import {html2plaintext, loadPlaybackProgress, PriceFormat} from "../utils/utils";
import {TitleComponent} from "../utils/title";
import MetaTags from 'react-meta-tags';
import CourseLanding from "./landing";
import LessonV2 from "../lesson-v2";
import Spinner from "../utils/spinner";

function CourseV3 (props) {
    const [loading, setLoading] = useState(true);
    const [courseID, setCourseId] = useState(null);
    const [courseData, setCourseData] = useState(null);
    const [error, setError] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [progress, setProgress] = useState(null);

    let content = '';

    let pageParams = useParams();
    const location = useLocation();

    useEffect(() => {
        statusChange();
        if (pageParams.lesson_id) {
            document.body.classList.remove('with_background');
        }
        else {
            document.body.classList.add('with_background');
        }

        return () => {
            document.body.classList.remove('with_background');
        }
    });

    const statusChange = () => {
        if (!courseID || courseID !== pageParams.id) {
            setCourseId(pageParams.id);
            loadData();
        }
    };

    const buildTitle = (d) => {
        let wip_title = d.title;
        let title_price = 'бесплатно';

        if (parseInt(d.regular_price)) {
            const discount = !!parseInt(d.discount_amount_percentage);
            const price = discount ? d.price : d.regular_price;
            const purchaseText = discount ? 'купить со скидкой' : 'купить';
            const outputPrice = PriceFormat(parseInt(Math.ceil(price)));

            title_price = purchaseText + ' за ' + outputPrice + ' ₽';
        }

        wip_title += ' ' + title_price + ' | W-Hub.ru';

        setTitle(wip_title);
    };

    const buildDescription = (d) => {
        let descr = props.metaDescription;

        const fullText = html2plaintext(d.description.length ? d.description : d.short_description);

        if (fullText.length) {
            descr = fullText.substring(0,100);

            if (fullText.length > 100) {
                const restText = fullText.split(descr)[1];
                descr += restText.split('.')[0];
            }

            descr += ' - лучшие курсы и аудиокниги на W-Hub.ru';
        }

        setDescription(descr)
    };

    const loadData = () => {
        setLoading(true);
        API.get('/med/v3/course/' + pageParams.id)
            .then(function (r) {
                setLoading(false);
                if (!r.data.lessons) {
                    r.data.lessons = [];
                }
                buildTitle(r.data);
                buildDescription(r.data);
                setCourseData(r.data);
                checkProgress(r.data.id);
            })
            .catch(function (error) {
                setLoading(false);
                setError(true);
                console.log(error)
            });
    };

    const checkProgress = (id) => {
        if (props.authorized) {
            setLoading(true);
            loadPlaybackProgress(id, (r) => {
                setLoading(false);
                if (r.data.file_index || r.data.time_in_secs) {
                    setProgress(r.data);
                }
            })
        }
    };

    const freeCoursePurchase = () => {
        const p = props;
        const data = courseData;
        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        if (!purchased) {
            setLoading(true);
            API.get('/med/v1/pay/nothing/' + data.id)
                .then((r) => {
                    setLoading(false);
                    p.reloadUserData();
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error.response);
                });
        }
    };

    if ((courseData && courseData.errors) || error) {
        content = <Error404/>;
    }
    else {
        if (courseData) {
            if (pageParams.lesson_id && !loading) {
                content = <LessonV2
                    {...props}
                    courseData={courseData}
                    lessonId={pageParams.lesson_id}
                    progress={progress}
                    freeCoursePurchase={freeCoursePurchase}
                    pageParams={pageParams} />;
            } else {
                content = <CourseLanding
                    {...props}
                    data={courseData}
                    pathname={location.pathname}
                    loading={loading}
                    setLoading={setLoading}
                    progress={progress}
                    freeCoursePurchase={freeCoursePurchase}
                    pageParams={pageParams} />;
            }
        }
    }

    const page_title = title ? <TitleComponent title={title} /> : '';
    const meta_description = description ? <MetaTags>
        <meta name="description" content={description} />
    </MetaTags> : '';

    return (
        <div className="course_wrapper">
            {page_title}
            {meta_description}
            {content}
            <Spinner show={loading && !props.loading} />
        </div>
    );
}

export default CourseV3;
