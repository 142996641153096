import React, {useEffect, useState} from "react";
import API from '../utils/api';
import { useLocation } from 'react-router-dom';
import tus from "tus-js-client";

// import {withId} from 'react-yandex-metrika';

const tokenName = 'WHM_AUTH_TOKEN';
const progressVarName = 'WHUB_progress_object';

export const reachGoalLink = (el) => {
    const tagId = parseInt(el.currentTarget.getAttribute('data-tag'));
    const goalId = el.currentTarget.getAttribute('data-goal');

    reachGoal(tagId, goalId);
};

export const reachGoal = (tagId, goalId) => {
    // withId(tagId)('reachGoal',goalId)
};

export const removeParamFromArray = (arr, param) => {
    const newArr = arr.slice();

    newArr.forEach((el) => {
        if (el[param]) {
            delete el[param];
        }
    });

    return newArr;
};

export function LocationSearchCrawler(props) {
    const [located, setLocated] = useState(false);
    const locationSearch = parseQS(useLocation().search.replace('?',''));
    useEffect(() => {
        if (!located) {
            locate();
        }
    });

    const locate = () => {
        setLocated(true);
        props.onLocation(locationSearch);
    };

    return <i> </i>;
}

export const getDropdownSelected = (arr, val) => {
    const obj = arr2obj(arr, 'value');
    if (obj[val]) {
        return obj[val];
    }
    return arr[0];
};

export const compareObjects = (o1, o2) => {
    for(let p in o1){
        if(o1.hasOwnProperty(p)){
            if(o1[p] !== o2[p]){
                return false;
            }
        }
    }
    for(let p in o2){
        if(o2.hasOwnProperty(p)){
            if(o1[p] !== o2[p]){
                return false;
            }
        }
    }
    return true;
};

export const isVertical = (src) => {
    const img = document.createElement('img');
    img.src = src;

    return img.clientHeight > img.clientWidth;
};

export const isTrueSet = (val) => {
    return val.toString() === 'true';
};

export const isFalseSet = (val) => {
    return val.toString() === 'false';
};

export const isNullSet = (val) => {
    return val.toString() === 'null';
};

export const PaymentLogos = () => {

    return <div className="payment_logos">
        <img src="/images/logos/mastercard.png" alt="" />
        <img src="/images/logos/visa.png" alt="" />
        <img src="/images/logos/mir.png" alt="" />
        <img src="/images/logos/kassa.png" alt="" />
    </div>;
};

export const checkOwner = (id, ud) => {
    const school = ud.role && ud.role === 'school';
    const created = ud.courses_created;

    if (school && created) {
        return !!((created.draft && created.draft.indexOf(id) > -1)
            || (created.publish && created.publish.indexOf(id) > -1)
            || (created.blank && created.blank.indexOf(id) > -1));
    }


    return false;
};

export const checkPostOwner = (id, ud) => {
    const school = ud.role && ud.role === 'school';
    const created = ud.blog_posts;

    if (school && created) {
        return !!((created.indexOf(parseInt(id)) > -1));
    }

    return false;
};

export const getPlaybackProgress = (courseId) => {
    let progressObject = JSON.parse(localStorage.getItem(progressVarName));
    if (!progressObject) {
        progressObject = {}
    }

    return progressObject[courseId] ? progressObject[courseId] : {file_index: 0, time_in_secs: 0};
};

export const savePlaybackProgress = (courseId, fileIndex, timeInSecs) => {
    let progressObject = JSON.parse(localStorage.getItem(progressVarName));
    if (!progressObject) {
        progressObject = {}
    }
    
    progressObject[courseId] = {
        file_index: fileIndex,
        time_in_secs: timeInSecs
    };
    
    localStorage.setItem(progressVarName, JSON.stringify(progressObject))
};


export const loadPlaybackProgress = (courseId, cb) => {
    API.get('/med/v1/paused/' + courseId)
        .then(function (r) {
            cb(r);
        })
        .catch(function (error) {
            cb({error: true});
            console.log(error)
        });
};

export const pushPlaybackProgress = (courseId, progressObj) => {
    API.post('/med/v1/paused/' + courseId, {data: progressObj})
        .then(function (r) {
        })
        .catch(function (error) {
            console.log(error)
        });
};

export const cloudUpload = (file, progress, finish, cleanup) => {
    if (file) {
        const upload = new tus.Upload(file, {
            endpoint: "https://api.cloudflare.com/client/v4/accounts/b16e848c05a38cee4b6d48f5483f1e47/stream",
            headers: {
                'X-Auth-Email': 'cloud@w-hub.ru',
                'X-Auth-Key': '7f62f6adef16279abf167687c493b6f033cce',
            },
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            onError: function(error) {
                console.log("Failed because: " + error)
                alert("Failed because: " + error);
                cleanup();
            },
            onProgress: function(bytesUploaded, bytesTotal) {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                progress(Math.round(percentage));
            },
            onSuccess: function() {
                finish(upload.url)
            }
        });

        // Start the upload
        upload.start()
    }
};

export const html2plaintext = (html) => {
    if (html) {
        //remove code brakes and tabs
        html = html.replace(/\n/g, "");
        html = html.replace(/\t/g, "");

        //keep html brakes and tabs
        html = html.replace(/<\/td>/g, "\t");
        html = html.replace(/<\/table>/g, "\n");
        html = html.replace(/<\/tr>/g, "\n");
        html = html.replace(/<\/p>/g, "\n");
        html = html.replace(/<\/div>/g, "\n");
        html = html.replace(/<\/h>/g, "\n");
        html = html.replace(/<br>/g, "\n"); html = html.replace(/<br( )*\/>/g, "\n");

        //parse html into text
        var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
        return dom.body.textContent;
    }
};

export const obj2arr = (obj) => {
    let arr = [];

    Object.keys(obj).forEach((el) => {
        arr.push(obj[el]);
    });

    return arr;
};

export const obj2arrCheckTotal = (obj) => {
    let arr = [];

    if (obj.total) {
        delete obj.total;
    }

    Object.keys(obj).forEach((el) => {
        arr.push(obj[el]);
    });

    return arr;
};

export const arr2obj = (arr, key) => {
    var obj = {};
    arr.forEach(function (e) {
        obj[e[key]] = e;
    });

    return obj;
};

export const serrializer = (obj, prefix) => {
    let str = [];
    let p;

    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            const k = prefix ? prefix + "[" + p + "]" : p;
            const v = obj[p];
            if (v !== null && typeof v === "object") {
                str.push(serrializer(v, k));
            }
            else {
                if (v !== null && (v || v.length)) {
                    str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
                }
            }
        }
    }

    return str.join("&");
};

export const parseQS = (str) => {
    const obj = {};
    const chunks = str.split('&');

    chunks.forEach((el) => {
        const kv = el.split('=');

        if (kv.length > 1) {
            if (isTrueSet(kv[1])) {
                obj[kv[0]] = true;
            } else if (isFalseSet(kv[1])) {
                obj[kv[0]] = false;
            } else if (isNullSet(kv[1])) {
                obj[kv[0]] = null;
            } else {
                obj[kv[0]] = kv[1];
            }
        }
    });

    return obj;
};

export const checkLocalStorage = () => {
    return localStorage.getItem(tokenName);
};

export const validateToken = (cb) => {
    API.post('/jwt-auth/v1/token/validate')
        .then(function (r) {
            cb(true);
        })
        .catch(function (error) {
            cb(false);
        });
};

export const setToken = (token, opts) => {
    if (!opts) {
        opts = {};
    }

    const t = token;
    localStorage.setItem(tokenName, t);
    API.defaults.headers.common['Authorization'] = 'Bearer ' + t;
};

export const logout = (cb) => {
    localStorage.removeItem(tokenName);
    API.defaults.headers.common['Authorization'] = '';

    if (cb) {
        cb();
    }
};

export const Plural = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};

export const PriceFormat = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const ScrollDebounce = (fn) => {

    let frame;

    return (...params) => {

        if (frame) {
            cancelAnimationFrame(frame);
        }

        frame = requestAnimationFrame(() => {
            fn(...params);
        });

    }
};

export const EmailTest = (mail) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(mail).toLowerCase());
};
