import React from 'react';
import { Link } from 'react-router-dom';

import './students.css';
import API from "../../utils/api";
import Spinner from "../../utils/spinner";

class Students extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: true,
            students: []
        };
    }

    loadStudents = () => {
        const t = this;

        API.get('/med/v3/school/student')
            .then(function (r) {
                t.setState({loading: false, students: r.data});
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    componentDidMount() {
        document.body.classList.add('no_search');
        this.loadStudents();
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    render() {
        const t = this;

        const {students, loading} = t.state;

        return (
            <div className="school_students">

                <h1>Студенты</h1>
                <p>Ваш список студентов формируется из всех, кто когда-либо покупал ваши курсы, подписывался на онлайн курсы или подписывался на обновления школы.</p>

                <ul className="students_list">
                    {students.map((el, idx) => {
                        return <li key={idx}>{idx + 1}. <Link to={'/users/' + el.slug}>{el.display_name}</Link></li>
                    })}
                </ul>


                <Spinner show={loading} />
            </div>
        );
    }
}

export default Students;