import React from 'react';
import PdfVeiwer from "../../utils/pdf-viewer";
import './pdf.css';

class LessonPdf extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const {content} = p;

        return (
            <div className="lesson_pdf_wrapper">
                <PdfVeiwer url={content.url} />
            </div>
        )
    }
}

export default LessonPdf;
