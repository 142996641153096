import React from 'react';
import './index.css';
import Header from "./components/header";
import Params from "./components/params";
import Advert from "./components/advert";
import FutureSkills from "./components/future_skills";
import Description from "./components/description";
import Content from "./components/content";
import AboutSchool from "./components/about_school";
import Authors from "./components/authors";
import Roadmap from "./components/roadmap";
import Requirements from "./components/requirements";
import FAQ from "./components/faq";
import Reviews from "./components/reviews";
import Related from "./components/related";
import Purchase from "./components/purchase";
import Perks from "./components/perks";
import LeaveReview from "./components/leave-review";
import API from "../../utils/api";
import Modal from "../../modal";
import Tracker from "../../utils/tracker";
import {checkOwner} from "../../utils/utils";
import CourseStatistics from "./components/course-statistics";
import {crumbBuilder} from "../../utils/breadcrumbs/crumb-builder";
import Breadcrumbs from "../../utils/breadcrumbs";

class CourseLanding extends React.Component {
    constructor (props) {
        super();

        this.state = {
            showGiftModal: false,
            showReportModal: false
        };
    }

    scrollToContent = () => {
        document.getElementById('landingContentBlockAnchor').scrollIntoView({behavior: 'smooth'});
    };

    checkIfRated = (ud, data) => {
        if (data.reviews && data.reviews.length) {
            for (let i=0;i<data.reviews.length;i++) {
                if (parseInt(ud.id) === parseInt(data.reviews[i].user.id)) {
                    return true;
                }
            }
        }
        return false;
    };

    handleReportClick = () => {
        this.setState({showReportModal: true});
    };

    handleGiftClick = () => {
        this.setState({showGiftModal: true});
    };

    giftPurchase = (email) => {
        const t = this;
        const p = t.props;

        t.setState({showGiftModal: false});
        p.setLoading(true);

        API.post('/med/v3/order', {product_id: p.data.id, payment_method: 'ukassa', gift_recipient: email})
            .then((r) => {
                p.setLoading(false);
                const purchaseLink = r.data.payment_data.payment_url;
                p.modalPurchase(purchaseLink);
                window.open(purchaseLink);

                //withId(62510686)('reachGoal','purchase');
            })
            .catch((error) => {
                p.setLoading(false);
                alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
                console.log(error);
            });
    };

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;
        const meta = data.meta;
        const userData = p.userData;

        const {showGiftModal, showReportModal} = t.state;

        const isOwner = checkOwner(data.id, userData);
        const isPurchased = userData.purchased && userData.purchased.indexOf(data.id) > -1;
        const isRated = isPurchased && t.checkIfRated(userData, data);
        const isSchool = p.authorized && userData.role === 'school';

        const hasDiscount = !!parseInt(data.discount_amount_percentage);
        const price = hasDiscount ? data.price : data.regular_price;
        const isFree = !price;

        const advert = meta.self_advert ? <Advert {...p} /> : '';
        const futureSkills = meta.future_skills ? <FutureSkills {...p} /> : '';
        const perks = meta.perks ? <Perks {...p} /> : '';
        const authors = meta.authors ? <Authors {...p} /> : '';
        const roadmap = meta.roadmap ? <Roadmap {...p} /> : '';
        const requirements = meta.requirements ? <Requirements {...p} /> : '';
        const faq = meta.faq ? <FAQ {...p} /> : '';
        const courseStatistics = isOwner ? <CourseStatistics {...p} /> : '';

        const leaveReview = isPurchased && !isRated ? <LeaveReview {...p} /> : '';
        const reviews = data.reviews && data.reviews.length ? <Reviews {...p} /> : '';

        const related = data.related_ids ? <Related {...p} /> : '';

        const purchase = !isSchool ? <Purchase {...p} handleGift={t.handleGiftClick} /> : '';

        const giftModal = showGiftModal ? <Modal modalContent={'gift'} onSuccess={t.giftPurchase} onModalClose={() => t.setState({showGiftModal: false})} /> : '';
        const reportModal = showReportModal ? <Modal {...p} modalContent={'abuse'} onModalClose={() => t.setState({showReportModal: false})} /> : '';

        const crumbs = crumbBuilder('course', {data: data, pageParams: p.pageParams});

        return (
            <div className="course_landing_wrapper">
                <Breadcrumbs data={crumbs} />

                <Header {...p} isOwner={isOwner} handleGift={t.handleGiftClick} handleReport={t.handleReportClick} scrollToContent={t.scrollToContent} />
                <Params {...p} />

                {courseStatistics}

                {advert}
                {futureSkills}

                <Description {...p} />

                {perks}

                <Content {...p} isPurchased={isPurchased} isFree={isFree} ref={this.contentBlock} />
                <AboutSchool {...p} />

                {authors}
                {roadmap}
                {requirements}
                {faq}

                {leaveReview}
                {reviews}

                {related}

                {purchase}

                {giftModal}
                {reportModal}

                <Tracker id={data.id} event="view" />
            </div>
        );
    }
}

export default CourseLanding;
