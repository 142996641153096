import React, {useEffect} from 'react';

import {useParams} from 'react-router-dom';


import './index.css';
import MyCourses from "../profile/school/my-courses";
import PurchasedCourses from "../profile/student/purchased";
import Favourites from "../profile/student/favourites";
import SchoolData from "../profile/finish-signup";
import Profile from "../profile";
import SubscriptionPlans from "../profile/subscription-plans";
import SchoolStatistics from "../profile/school/stats";
import ArchivedCourses from "../profile/student/archived";
import Entries from "../profile/student/entries";
import Posts from "../profile/school/posts";
import SchoolDashboardLinks from "./school-dashboard-links";
import StudentDashboardLinks from "./student-dashboard-links";
import Help from "../about/help";
import MyOnlineCourses from "../profile/school/my-online-courses";
import Students from "../profile/school/students";
import Reviews from "../profile/school/reviews";
import Experts from "../profile/school/experts";

function User(props) {
    const userData = props.userData;

    let pageParams = useParams();

    let schoolCompleted = userData.school_data_completed;
    const schoolData = schoolCompleted ? JSON.parse(userData.additional_data) : {};
    const schoolSZ = schoolCompleted && schoolData.company_type === 'sz';
    const schoolSZChecked = userData.sz_inn_status;

    if (schoolSZ && !schoolSZChecked) {
        schoolCompleted = false;
    }

    const links = userData.role === 'school' ? <SchoolDashboardLinks {...props} schoolCompleted={schoolCompleted} /> : <StudentDashboardLinks {...props} />;

    const profileMap = {
        'plans': <SubscriptionPlans completed={schoolCompleted} {...props} />,
        'profile': <Profile {...props} />,
        'school-data': <SchoolData {...props} />,
        'courses': <MyCourses {...props} />,
        'online-courses': <MyOnlineCourses {...props} />,
        'posts': <Posts {...props} />,
        'students': <Students {...props} />,
        'experts': <Experts {...props} />,
        'reviews': <Reviews {...props} />,
        'stats': <SchoolStatistics {...props} />,
        'help': <Help {...props} />,


        'favourites': <Favourites {...props} />,
        'purchased': <PurchasedCourses {...props} />,
        'entries': <Entries {...props} />,
        'archived': <ArchivedCourses {...props} />
    };


    useEffect(() => {
        document.body.classList.add('with_background');
        document.body.classList.add('profile_dashboard');
        return () => {
            document.body.classList.remove('with_background');
            document.body.classList.remove('profile_dashboard');
        }
    });

    return (
        <div className="profile_wrapper">
            <div className="profile_inner">
                <div className="profile_nav">
                    {links}
                </div>
                {profileMap[pageParams.profilePage]}
            </div>

        </div>
    );
}

export default User;