import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';
import {CourseRating} from "../../icons";

import moment from 'moment';
import 'moment/locale/ru';

class Reviews extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    reviewsMapper = (item, idx) => {
        const user = item.user;
        const review = item.review;

        const name = user.display_name.length ? user.display_name : user.slug;

        if (!name) {
            return '';
        }

        const firstLetter = name[0].toUpperCase();
        const userpic = user.avatar.length ? <img src={user.avatar} alt="" /> : <span>{firstLetter}</span>;

        return (
            <div key={idx} className="review_item">
                <div className="user">
                    <div className="userpic"><Link to={'/users/' + user.slug}>{userpic}</Link></div>
                    <div className="username"><Link to={'/users/' + user.slug}>{name}</Link></div>
                    <div className="review_date">{moment(review.date).format('DD MMMM YYYY')}</div>

                    <div className="stars">
                        <CourseRating rating={review.score}/>
                    </div>
                </div>
                <div className="text">
                    {review.text}
                </div>
                <div className="score">
                    <CourseRating rating={review.score}/>
                </div>
            </div>
        );
    };

    render() {
        const t = this;
        const p = t.props;
        const reviews = p.data.reviews;

        return (
            <div className="cl_reviews">
                <div className="cl_centrer">
                    <h2>Отзывы</h2>

                    <div className="reviews_list">
                        {reviews.map(t.reviewsMapper)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Reviews;
