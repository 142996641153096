import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterConfig from "./router-config";

import ScrollToTop from './utils/scroll-top';

import API from './utils/api';
import {
    arr2obj,
    checkLocalStorage,
    logout,
    obj2arr,
    parseQS,
    serrializer,
    setToken,
    validateToken
} from "./utils/utils";

import {isMobileOnly} from 'react-device-detect';

import './styles/misc.css';
import './styles/layout.css';
import './styles/global.css';
import './styles/buttons.css';
import './styles/forms.css';
import './utils/icons.css';

import Footer from './footer';
import Demo from "./utils/demo";
import BottomDisclaimer from "./utils/bottom-disclaimer";

import CategoriesList from './categories';
import UploadProgressWidget from "./utils/upload-progress-widget";
import Modal from "./modal";
import TosConfirmationScreen from "./utils/tos-confirmation-screen";
import HeaderV2 from "./header-v2";

import ErrorBoundary from "./error-boundary";
import Spinner from "./utils/spinner";
//
// import ReactGA from "react-ga";
//
// const trackingId = "UA-164117868-1";
// ReactGA.initialize(trackingId);

API.interceptors.request.use((config) => {
    config.metadata = { startTime: new Date()};
    return config;
}, (error) => {
    return Promise.reject(error);
});

API.interceptors.response.use((response) => {
    response.config.metadata.endTime = new Date();
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
    return response;
}, (error) => {
    console.log(error)
    error.config.metadata.endTime = new Date();
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
    return Promise.reject(error);
});

class AppRouter extends React.Component {
    constructor (props) {
        super();

        this.state = {
            searchRequest: '',
            authCheck: false,
            setPasswordState: false,
            setPasswordStatus: 'loading',
            loading: false,
            categories_list: CategoriesList,
            demo: false,
            authorized: false,
            user_data: {},
            all_categories: CategoriesList,
            latest_courses: [],
            show_bottom_disclaimer: !localStorage.WHUB_BOTTOM_DISCLAMER_CLOSED,
            landscape: false,
            preloaded: false,
            uploadQueue: [],
            uploadProgress: {},
            mainPageLoaded: false,
            mainPageObj: {
                featured: [],
                latest: {audio: [], video: []},
                popular: {audio: [], video: []},
                quarantine: {audio: [], video: []},
                articles: []
            },
            latest: {audio: [], video: []},

            showAuthModal: false,
            showSupportModal: false,
            authModalOnSuccess: null,
            showPurchaseModal: false,
            modalPurchaseLink: '',
            signupAndPurchase: false,
            passwordResetPage: false,
            noDialogs: false
        };
    };

    componentDidMount = () => {
        this.processAuth();
        if (isMobileOnly) {
            this.checkOrientation();
        }
        this.checkPasswordInUrl()
    };

    checkPasswordInUrl = () => {
        const t = this;
        const search = parseQS(window.location.search.replace('?',''));

        if (search.setPassword) {
            t.setState({setPasswordState: true});
            delete search.setPassword;
            t.processKeyCheck(search);
        }
    };

    checkOrientation = () => {
        window.addEventListener('resize', this.doOnOrientationChange);
        this.doOnOrientationChange();
    };

    doOnOrientationChange = () => {
        if (window.innerWidth < window.innerHeight) {
            this.setState({landscape: false});
        } else {
            this.setState({landscape: true});
        }
    };

    processAuth = (cb) => {
        const t = this;
        const localToken = checkLocalStorage();
        t.setState({loading: true});
        if (localToken) {
            t.setState({loading: false});
            setToken(localToken);
            validateToken(t.processValidatedAuth);
        }
        else {
            t.setState({loading: false});
            t.processAuthFailed();
        }
    };

    processValidatedAuth = (tokenValid) => {
        const t = this;
        if (tokenValid) {
            API.get('/wp/v2/users/me')
                .then(function (r) {
                    t.setState({
                        authCheck: true,
                        authorized: true,
                        user_data: r.data
                    });

                    t.preloadData();
                })
                .catch(function (error) {
                    t.processAuthFailed();
                });
        }
        else {
            t.processAuthFailed();
        }
    };

    reloadUserData = (cb) => {
        const t = this;
        API.get('/wp/v2/users/me')
            .then(function (r) {
                t.setState({
                    authCheck: true,
                    authorized: true,
                    user_data: r.data
                }, () => {
                    if (cb) {
                        cb(r.data);
                    }
                });
            })
            .catch(function (error) {
                if (cb) {
                    cb({error: true})
                }
            });
    };

    processAuthFailed = () => {
        logout();
        this.setState({
            authCheck: true,
            authorized: false,
            user_data: {}
        });

        this.preloadData();
    };

    processKeyCheck = (d) => {
        const t = this;
        API.get('/med/v3/keycheck?' + serrializer(d))
            .then(function (r) {
                if (r.data.valid_key) {
                    t.setState({setPasswordStatus: 'password'});
                }
                else {
                    if (r.data.pass_not_set) {
                        t.setState({setPasswordStatus: 'error'});
                    }
                    else {
                        t.setState({setPasswordStatus: 'auth'});
                    }
                }
            })
            .catch(function (error) {
                t.setState({setPasswordStatus: 'error'});
                console.log(error);
            });
    };

    preloadData = () => {
        const s = this.state;
        const loc = window.location;
        // todo: ПЕРЕПИСАТЬ!
        const mainPage = loc.hash === '#/' || loc.pathname === '/';


        if (!s.preloaded && (!mainPage || s.mainPageLoaded)) {
            this.setState({preloaded: true});
            this.loadCategories();
            this.loadLatest();
        }
    };

    loadCategories = () => {
        const t = this;
        API.get('/med/v3/categories')
            .then(function (r) {
                if (r.data.total) {
                    delete r.data.total;
                }
                const data = r.data;
                const catsObj = arr2obj(data, 'term_id');
                t.setState({all_categories: catsObj, categories_list: data});
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    loadLatest = () => {
        const t = this;
        API.get('/med/v2/courses?per_page=10', {per_page: 10})
            .then(function (r) {
                t.setState({latest_courses: obj2arr(r.data.results)});
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    showDemo = () => {
        this.setState({demo: true});
    };

    hideDemo = () => {
        this.setState({demo: false});
    };

    processUpload = (uploadData) => {
        const t = this;
        const s = t.state;
        const queueArray = s.uploadQueue.slice();
        const progressObj = JSON.parse(JSON.stringify(s.uploadProgress));

        queueArray.push(uploadData);
        progressObj[uploadData.lesson_id] = 0;
        t.setState({uploadQueue: queueArray, uploadProgress: progressObj});
    };

    handleUploadProgress = (lesson_id, progress) => {
        const t = this;
        const s = t.state;
        const progressObj = JSON.parse(JSON.stringify(s.uploadProgress));

        progressObj[lesson_id] = progress;
        t.setState({uploadProgress: progressObj});
    };

    handleUploadFinish = (url, uploadData) => {
        const t = this;

        const chunks = url.split('/');
        const clip_id = chunks[chunks.length-1];
        const lesssonUpdate = {
            id: uploadData.course_id,
            lesson_id: uploadData.lesson_id,
            video_code: clip_id,
            is_cloud: true
        };

        API.put('/med/v1/lesson/' + lesssonUpdate.id, lesssonUpdate)
            .then(function (r) {
                alert('Видеоролик к уроку #' + lesssonUpdate.lesson_id + ' успешно загружен!');
                t.handleUploadCLeanup(uploadData);
            })
            .catch(function (error) {
                alert(error);
                t.handleUploadCLeanup(uploadData);
            });
    };

    handleUploadCLeanup = (uploadObj) => {
        const t = this;
        const s = t.state;

        const progressObj = JSON.parse(JSON.stringify(s.uploadProgress));
        const queueArray = s.uploadQueue.slice();

        const idx = queueArray.indexOf(uploadObj);

        queueArray.splice(idx, 1);
        delete progressObj[uploadObj.lesson_id];
        t.setState({uploadQueue: queueArray, uploadProgress: progressObj});
    };

    closeBottomDisclamer = () => {
        this.setState({show_bottom_disclaimer: false});
        localStorage.setItem("WHUB_BOTTOM_DISCLAMER_CLOSED", "1");

        // ReactGA.event({
        //     category: "Disclamer close",
        //     action: "User closed beta-n-cookie disclamer",
        // });
    };

    setMainPageObj = (obj, key) => {
        const t = this;
        // if (obj.featured) {
        //     const advertObj = {
        //         advert: true,
        //         id: 'random_id_12356554345123123',
        //         cover: '/images/collections-ad-2-v2.png',
        //         cover_cls: 'middle_orientated',
        //         url: '/collections/books',
        //         title: 'Подборки аудиокниг',
        //         teaser: '',
        //         label: 'подборка'
        //     };
        //
        //     obj.featured.unshift(advertObj);
        // }
        if (key) {
            const singleObj = {};
            singleObj[key] = obj;
            t.setState(singleObj);

        }
        else {
            t.setState({mainPageObj: obj, mainPageLoaded: true}, () => {
                t.preloadData();
            });
        }
    };

    showPurchaseModal = (purchaseLink) => {
        this.setState({
            showPurchaseModal: true,
            modalPurchaseLink: purchaseLink
        });
    };

    showAuthModal = (afterAuth, purchaseCall) => {
        this.setState({
            showAuthModal: true,
            authModalOnSuccess: afterAuth,
            signupAndPurchase: purchaseCall ? purchaseCall : false
        });
    };

    showSupportModal = () => {
        this.setState({
            showSupportModal: true
        });
    };

    handleModalAuthSuccess = () => {
        const t = this;
        const s = t.state;

        t.reloadUserData(s.authModalOnSuccess);
        t.hideModals();
    };

    hideModals = (d) => {
        this.setState({
            showAuthModal: false,
            showPurchaseModal: false,
            showSupportModal: false
        });
    };

    authorizeFunc = (d, cb) => {
        const t = this;
        API.post('/jwt-auth/v1/token', d, {headers: {'Authorization': ''}})
            .then(function (r) {
                setToken(r.data.token, {login: true});
                t.processAuth();
                if (cb) {
                    cb();
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                console.log(error)
                cb({error: true});
            });
    };

    toggleNoDialogs = (s) => {
        this.setState({noDialogs: s});
    };

    render() {
        const t = this;
        const s = this.state;

        const demo = s.demo ? <Demo onDemoClose={this.hideDemo} /> : '';
        const bottom_disclaimer = s.show_bottom_disclaimer ? <BottomDisclaimer onBottomDisclamerClose={this.closeBottomDisclamer} /> : '';

        const upload_widget = s.uploadQueue.length ? <UploadProgressWidget
            uploadQueueData={s.uploadQueue}
            uploadProgressData={s.uploadProgress}
            updateUploadProgress={t.handleUploadProgress}
            afterUpload={t.handleUploadFinish}
            uploadCleanup={t.handleUploadCLeanup}/> : '';

        const authModal = s.showAuthModal ? <Modal modalContent={'auth'} onSuccess={t.hideModals} onModalClose={t.hideModals} onModalAuthSuccess={t.handleModalAuthSuccess} authFunc={t.processAuth} authorizeFunc={t.authorizeFunc} signupButton={s.signupAndPurchase} /> : '';
        const purchaseModal = s.showPurchaseModal ? <Modal modalContent={'purchase_link'} modalUrl={s.modalPurchaseLink} onModalClose={t.hideModals} /> : '';
        const supportModal = s.showSupportModal ? <Modal  modalContent={'support'} onModalClose={t.hideModals} /> : '';

        // const passNotSetNote = s.user_data.pass_not_set && !s.passwordResetPage ? <div className="pass_not_set"><p>Пожалуйста, завершите регистрацию и создайте пароль для сохранения доступа к аккаунту! Письмо отправлено на адрес {s.user_data.email}</p></div> : '';

        const authorized = s.authorized;
        const authorizedSchool = authorized && s.user_data.role === 'school';

        const tosScreen = authorized && !s.user_data.tos_accepted && !s.passwordResetPage ? <TosConfirmationScreen role={s.user_data.role} reloadUserData={this.reloadUserData} /> : '';

        const routerConfig = <RouterConfig
            searchRequest={s.searchRequest}
            loading={s.loading}
            authorized={s.authorized}
            authorizedSchool={authorizedSchool}
            userData={s.user_data}
            setPasswordState={s.setPasswordState}
            setPasswordStatus={s.setPasswordStatus}
            keyCheck={t.processKeyCheck}
            categories={s.categories_list}
            latestCourses={s.latest_courses}
            authFunc={t.processAuth}
            authorizeFunc={t.authorizeFunc}
            modalAuth={t.showAuthModal}
            modalPurchase={t.showPurchaseModal}
            demoFunc={this.showDemo}
            reloadUserData={t.reloadUserData}
            uploadFunc={this.processUpload}
            setPasswordResetPage={(state) => t.setState({passwordResetPage: state})}
            mainPageLoaded={s.mainPageLoaded}
            mainPageObj={s.mainPageObj}
            latest={s.latest}
            setMainPageObj={this.setMainPageObj}

            updateSearchRequest={(request) => t.setState({searchRequest: request})}

            noDialogs={s.noDialogs}
            toggleNoDialogs={t.toggleNoDialogs}

            // reactGA={ReactGA}

            metaDescription="Онлайн обучение: видеоуроки, видеокурсы, аудиокниги, бесплатные курсы, профессиональные курсы. Удобная платформа для вебинаров."/>;

        const router = <Router basename={'/'}>
            <ErrorBoundary
                authorized={s.authorized}
                userData={s.user_data}>
                <ScrollToTop>
                    <HeaderV2
                        loading={s.loading}
                        demoFunc={this.showDemo}
                        categories={s.categories_list}
                        authorized={s.authorized}
                        userData={s.user_data}
                        authFunc={this.processAuth}
                        authorizeFunc={t.authorizeFunc}
                        latestCourses={s.latest_courses}
                        modalAuth={t.showAuthModal}
                        modalPurchase={t.showPurchaseModal}
                        searchRequest={s.searchRequest}
                        updateSearchRequest={(request) => t.setState({searchRequest: request})}
                        reloadUserData={this.reloadUserData}/>

                    <div className="page_view">

                        {routerConfig}
                    </div>

                    <Footer modalSupport={t.showSupportModal} />
                    {demo}
                    {bottom_disclaimer}
                    {upload_widget}

                    {authModal}
                    {purchaseModal}
                    {supportModal}

                    {tosScreen}
                    <Spinner show={s.loading} />
                </ScrollToTop>

                    {/*{passNotSetNote}*/}
            </ErrorBoundary>
        </Router>;

        const appContent = s.authCheck ? router : <Spinner show={true} />;

        // const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
        // const isProd = env !== 'dev' && env !== 'stage';

        return <div>
            {appContent}
        </div>
    }
}

export default AppRouter;
