import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';

import './signup-gift.css';
import Preloader from "../utils/preloader";
import API from "../utils/api";
import {obj2arr, parseQS} from "../utils/utils";
import CoursesFeed from "../courses/courses-feed";

function SignUpGift(props) {
    const [loading, setLoading] = useState(false);
    const [isGift, setIsGift] = useState(false);
    const [validated, setValidated] = useState(false);
    const [giftData, setGiftData] = useState([]);
    const [giftSelectedId, setGiftSelectedId] = useState(null);

    let params = parseQS(useLocation().search.replace('?',''));

    const validateGiftCode = () => {
        const code = params.giftCode;

        setIsGift(true);
        setLoading(true);
        setValidated(true);

        API.post('/med/v2/code/validate', {code: code})
            .then(function (r) {

                console.log(r)
                setLoading(false);

                if (!r.data.code) {
                    const giftsArr = obj2arr(r.data);


                    props.onIsGift(code);

                    setGiftData(giftsArr);

                    if (giftsArr.length === 1) {
                        handleGiftSelect(giftsArr[0]);
                    }
                }
                else {
                    if (r.data.message) {
                        alert(r.data.message);
                    }
                }

            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    };

    const handleGiftSelect = (gift) => {
        setGiftSelectedId(gift.id);
        props.onGiftIdSelect(gift);
    };

    useEffect(() => {
        if (!validated && (params.giftCode || params.giftcode)) {
            validateGiftCode();
        }
    });

    const giftText = props.authorized ? 'Поздравляем! Вы получили в подарок ' : 'Поздравляем! После регистрации вы получите в подарок ';

    const giftBlock = giftData.length ? <div className="gift_block">
        <h5>{giftText} {giftData.length > 1 ? 'одну из книг по вашему выбору' : 'книгу'}:</h5>
        <CoursesFeed {...props}
                     courses={giftData}
                     giftFeed={true}
                     onGiftSelect={handleGiftSelect}
                     giftSelectedId={giftSelectedId}
                     singleGiftItem={giftData.length === 1} />
    </div> : '';

    const preloader = loading ? <Preloader/> : '';

    const giftWrapper = <div className="sign_up_gift">
        {giftBlock}

        {preloader}
    </div>;

    return isGift ? giftWrapper : '';
}

export default SignUpGift;