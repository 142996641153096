import React from 'react';

import API from "../utils/api";
import {CourseRating} from "../utils/icons";

import './course-rating-block.css';

class CourseRatingBlock extends React.Component {
    constructor (props) {
        super();

        const userRate = props.userData.reviews[props.courseData.id];

        this.state = {
            vote: userRate ? userRate : 0,
            selected: userRate,
            rated: userRate
        };
    }

    handleMouseOver = (e) => {
        if (!this.state.selected) {
            this.setState({vote: e.target.getAttribute('data-vote')})
        }
    };

    handleSelect = (e) => {
        this.setState({vote: e.target.getAttribute('data-vote'), selected: true});
    };

    handleSubmit = () => {
        const t = this;
        const p = t.props;

        const reviewData = {
            product_id: p.courseData.id,
            review: ' ',
            rating: t.state.vote
        };

        p.setLoading(true);
        API.post('/med/v1/review', reviewData)
            .then((r) => {
                p.setLoading(false);
                t.setState({rated: true});
            })
            .catch((error) => {
                console.log(error);
                p.setLoading(false);
                alert('вероятно, вы уже голосовали за этот курс...')
            })
    };

    render() {
        const t = this;
        const p = t.props;
        const s = t.state;

        const typeText = p.type === 'webinar' ? 'вебинар' : 'курс';

        const cls_rated = s.rated ? 'rated' : '';
        const headerText = s.rated ? 'вы оценили ' + typeText + ':' : 'оценить ' + typeText;

        return (
            <div className={'course_grid_item course_rating_block ' + cls_rated}>
                <h5>{headerText}</h5>
                <div className="cgi_content">

                    <div className="rating_vote">
                        <div className="rv_buttons">
                            <i data-vote="1" onMouseOver={this.handleMouseOver} onClick={this.handleSelect}>&nbsp;</i>
                            <i data-vote="2" onMouseOver={this.handleMouseOver} onClick={this.handleSelect}>&nbsp;</i>
                            <i data-vote="3" onMouseOver={this.handleMouseOver} onClick={this.handleSelect}>&nbsp;</i>
                            <i data-vote="4" onMouseOver={this.handleMouseOver} onClick={this.handleSelect}>&nbsp;</i>
                            <i data-vote="5" onMouseOver={this.handleMouseOver} onClick={this.handleSelect}>&nbsp;</i>
                        </div>
                        <CourseRating rating={s.vote} />
                    </div>
                    <button onClick={this.handleSubmit} className="small" disabled={!s.selected}>подтвердить</button>
                </div>
            </div>
        )
    }
}

export default CourseRatingBlock;
