import React, {useEffect, useState} from "react";
import {useParams, Redirect} from "react-router-dom";

import './index.css';
import API from "../../utils/api";
import Preloader from "../../utils/preloader";
import {checkOwner} from "../../utils/utils";
import EditWebinarsGeneral from "./general";
import EditWebinarsTabs from "./tabs";
import EditWebinarsLectures from "./lectures";
import EditWebinarCovers from "./covers";

function EditWebinar(props) {
    let pageParams = useParams();

    const redirectNoAuth = !props.authorized || props.userData.role !== 'school';
    const [redirectAuthFailed, setRedirectAuthFailed] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const [tabsStatus, setTabsStatus] = useState(0);

    const minPrice = 750;

    const [freeCourse, setFreeCourse] = useState(true);

    const blankCourseName = 'blank_course';

    //const [status, setStatus] = useState('');

    const courseId = pageParams.id;

    const loadData = () => {
        setLoaded(true);
        API.get('/med/v1/course/' + courseId + '?skip_cache=1')
            .then(function (r) {

                if (r.data.name === blankCourseName) {
                    r.data.name = '';
                }

                if (typeof r.data.lessons === "string") {
                    r.data.lessons = [];
                }

                if (parseInt(r.data.price)) {
                    setFreeCourse(false);
                }

                setLoading(false);
                setData(r.data);
                if (!checkOwner(r.data.id, props.userData) && !r.data.status === 'private') {
                    setRedirectAuthFailed(true);
                }
                checkProgress(r.data);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error)
            });
    };

    const updateData = (d, autoSave) => {
        const updated = Object.assign(data, d);

        setData(updated);
        checkProgress(updated);

        if (autoSave) {
            API.put('/med/v1/course/update/' + courseId, autoSave)
                .then(function (r) {
                    console.log(r);

                })
                .catch(function (error) {
                    alert(error)
                });
        }
    };

    const checkProgress = (d) => {
        let updated = data;
        if (d) {
            updated = d;
        }

        if (!updated.name) {
            updated.name = '';
        }
        if (!updated.description) {
            updated.description = '';
        }
        if (!updated.short_description) {
            updated.short_description = '';
        }

        const tabTimetableActive = updated.name.length
            && updated.description.length
            && updated.short_description.length;
            // && updated.webinar_info.webinar_start
            // && updated.webinar_info.webinar_length;

        const tabPriceActive = updated.lessons.length;
        let progress = 0;

        if (tabTimetableActive) {
            progress = 1;
        }
        if (tabPriceActive) {
            progress = 2;
        }
        setTabsStatus(progress);
    };

    const toggleFreeCourse = () => {
        if (!freeCourse) {
            setFreeCourse(true);
            updateData({regular_price: '0'}, {regular_price: '0'})
        }
        else {
            setFreeCourse(false);
            updateData({regular_price: minPrice.toString()}, {regular_price: minPrice.toString()})
        }

    };

    useEffect(() => {
        document.body.classList.add('no_search');
        if (!loaded) {
            loadData();
        }

        return () => {
            document.body.classList.remove('no_search');
        }
    });

    let editorTab;
    switch (pageParams.tab) {
        case 'general':
            editorTab = <EditWebinarsGeneral {...props} updateData={updateData} courseData={data} toggleFreeCourse={toggleFreeCourse} freeCourse={freeCourse} />;
            break;
        case 'covers':
            editorTab = <EditWebinarCovers {...props} updateData={updateData} courseData={data} />;
            break;
        case 'timetable':
            editorTab = <EditWebinarsLectures {...props} updateData={updateData} courseData={data} freeCourse={freeCourse} />;
            break;
        default:
            editorTab = '';
    }

    const renderEditBlock = data ? editorTab : '';

    const preloader = loading ? <Preloader/> : '';

    const pageTitle = data && data.name !== '' ? 'редактирование вебинара' : 'создание вебинара';

    const tabs = data ? <EditWebinarsTabs {...props} tabsStatus={tabsStatus} courseData={data} freeCourse={freeCourse} /> : '';

    const redirectToMain = redirectNoAuth ? <Redirect to={{pathname: '/'}}/> : '';
    const redirectToProfile = redirectAuthFailed ? <Redirect to={{pathname: '/user/profile'}}/> : '';

    return (
        <div className="edit_webinars_wrapper">
            <div className="cc_top">
                <h2>{pageTitle}</h2>
                {/*<h6>{status}</h6>*/}

                {tabs}
            </div>

            {renderEditBlock}

            {preloader}

            {redirectToMain}
            {redirectToProfile}
        </div>
    );
}

export default EditWebinar;