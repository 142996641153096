import React from 'react';
import './index.css';
import CourseRelated from "../../../../courses/course-related";

class Related extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;

        return (
            <div className="cl_related">
                <div className="cl_centrer">
                    <h2>Похожие курсы</h2>
                    <CourseRelated {...p} ids={data.related_ids} />
                </div>
            </div>
        );
    }
}

export default Related;
