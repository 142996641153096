import React from 'react';
import { Link } from 'react-router-dom';

import '../my-courses.css';
import API from "../../utils/api";
import {Plus} from "../../utils/icons";
import Spinner from "../../utils/spinner";
import PostPreview from "../../course-preview-v2/post-preview";
import {Crawler} from "./my-courses";

class Posts extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: false,
            posts: [],
            status: ''
        };
    }

    loadPosts = () => {
        const t = this;
        const {status} = t.state;

        t.setState({loading: true});
        API.get('/med/v3/post?my=1&posts_per_page=30&status=' + status)
            .then(function (r) {
                t.setState({loading: false, posts: r.data});
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    componentDidMount() {
        document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    onLocation = (params) => {
        const t = this;
        const {status} = t.state;
        let newStatus = 'publish';

        if (params.status && params.status !== status) {
            newStatus = params.status;
        }

        t.setState({status: newStatus}, t.loadPosts);
    };

    activeCls = (cls) => {
        const t = this;
        const {status} = t.state;

        return cls === status ? 'active' : '';
    };

    render() {
        const t = this;

        const {posts, loading} = t.state;

        const postsList = posts.map((post, idx) =>
            <PostPreview {...t.props} itemData={post} itemId={idx} key={post.id} linkType="edit" />
        );

        const createNewPost = <div key="random_key" className="course_preview_v2 cp_create_new_course">
            <Link to="/blog/new" className="course_preview_link">
                <div className="cnc_plus"><Plus /></div>
                <p className="cnc_text">создать статью</p>
            </Link>
        </div>;

        postsList.unshift(createNewPost)

        return (
            <div className="profile_courses_list">
                <Crawler onLocation={t.onLocation} />

                <div className="status_switcher">
                    <Link className={t.activeCls('publish')} to='?status=publish'>Опубликованные</Link>
                    <span>|</span>
                    <Link className={t.activeCls('draft')} to='?status=draft'>Черновики</Link>
                </div>

                <div className="courses_block">
                    {postsList}
                </div>


                <Spinner show={loading} />
            </div>
        );
    }
}

export default Posts;