import React from 'react';
import Preloader from "../../../utils/preloader";
import {Plural} from "../../../utils/utils";

class CourseForModal extends React.Component {
    constructor (props) {
        super();

        const meta = props.courseMeta;
        const data = meta['course_for'] ? meta['course_for'] : '';

        this.state = {
            loading: false,
            data: data,
            valid: false
        }
    }

    validate = () => {
        const t = this;
        const {data} = t.state;
        t.setState({
            valid: !!data.length
        })
    };

    handleChange = (e) => {
        const t = this;
        t.setState({data: e.target.value}, t.validate);
    };

    handleSave = () => {
        const t = this;
        const p = t.props;

        const {data} = t.state;
        const d = {'course_for': data};

        t.setState({loading: true});
        p.onSave(d, (r) => {
            t.setState({loading: false});
        });
    };

    render() {
        const t = this;
        const {data, loading} = t.state;
        const p = t.props;

        const preloader = loading ? <Preloader/> : '';

        const maxLength = 75;
        const remainingSymbols = maxLength - data.length;

        return (
            <div className="course_edit_modal cem_course_for">
                <div className="cem_inner">
                    <div className="cem_text">
                        <h3>Блок "Курс для"</h3>
                        <p className="block_description">Опишите кратко, для кого этот курс. Напрмер, "<i>школьников 9-11 классов</i>"</p>
                    </div>
                    <div className="cem_content cem_text">
                        <h5>Курс для</h5>
                        <textarea maxLength={maxLength} placeholder="всех и каждого" onChange={t.handleChange} value={data}/>
                        <div className="cem_hint">осталось {remainingSymbols} символ{Plural(remainingSymbols, '', 'а', 'ов')}</div>
                    </div>
                    <div className="cem_buttons">
                        <button className="small" onClick={p.onModalClose}>отмена</button>
                        <button className="small" onClick={t.handleSave}>сохранить</button>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default CourseForModal;
