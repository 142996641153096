import React from "react";

import API from '../utils/api';
import {EmailTest, PaymentLogos, reachGoal, setToken} from "../utils/utils";

import './sign-up-form.css';
import Checkbox from "../utils/checkbox/checkbox";
import Preloader from "../utils/preloader";

class SignUpForm extends React.Component {
    constructor (props) {
        super();
        this.state = {
            school: false,
            agreement: false,
            display_name: '',
            email: '',
            valid: false,
            loading: false,
            success: false,
            success_message: '',
            password: ''
        }
    }

    validate = () => {
        const t = this;
        const p = t.props;
        const s = t.state;
        const test = s.agreement && EmailTest(s.email) && s.password;

        const school_test = p.registrationType === 'school' ? s.display_name.length : true;

        const gift_test = p.giftCode ? p.selectedGiftId : true;

        if (test && school_test && gift_test && !s.valid) {
            this.setState({valid: true});
        }

        if ((!test || !school_test || !gift_test) && s.valid) {
            this.setState({valid: false});
        }
    };

    componentDidMount() {
        const {registrationType} = this.props;

        if (registrationType === 'school') {
            reachGoal(80212912,'visit_school_signup');
        }
    };

    componentDidUpdate() {
        const t = this;
        const p = t.props;
        if (p.giftCode && p.selectedGiftId) {
            t.validate();
        }
    };

    clearForm = () => {
        this.setState({
            school: false,
            agreement: false,
            display_name: '',
            email: '',
            valid: false,
            role: this.props.registrationType
        })
    };

    handleCheckbox = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.checked;

        this.setState(obj, this.validate);
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    handleSubmit = () => {
        const t = this;
        const p = t.props;
        const s = t.state;
        const data = {
            email: s.email,
            password: s.password,
            role: p.registrationType,
            tos_accepted: true
        };

        if (s.display_name.length) {
            data.display_name = s.display_name;
        }

        if (this.props.registrationType === 'school') {
            data.invite_code = 'kek';
        }

        if (p.giftCode) {
            data.gift_code = p.giftCode;
            data.gift_id = p.selectedGiftId;
        }

        t.setState({loading: true});

        API.post('/wp/v2/users/register', data, {headers: {'Authorization': ''}})
            .then(function (r) {
                t.setState({loading: false});

                if (p.registrationType === 'school') {
                    reachGoal(80212912,'school_signup_complete');
                }

                if (r.data.token) {
                    t.setState({
                        success: true,
                        success_email: data.email
                    });

                    t.instantAuth(r.data);
                    t.clearForm();
                }
                else if (r.data.code === 200 && data.password) {
                    t.setState({
                        success: true,
                        success_email: data.email
                    });

                    t.instantAuthPassword(data);
                    t.clearForm();
                }
                else if (r.data.existing_user_gift) {
                    p.setExistingUser(data.email);
                }
                else {
                    alert('произошла ошибка!')
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                t.setState({loading: false});
            });
    };

    instantAuth = (data) => {
        const t = this;
        setToken(data.token, {login: true});

        if (t.props.authFunc) {
            t.props.authFunc();
        }
        if (t.props.onSuccess) {
            t.props.onSuccess();
        }
    };

    instantAuthPassword = (d) => {
        const t = this;
        const data = {
            username: d.email,
            password: d.password
        };

        t.setState({loading: true});
        API.post('/jwt-auth/v1/token', data, {headers: {'Authorization': ''}})
            .then(function (r) {
                setToken(r.data.token, {login: true});
                t.setState({loading: false});
                if (t.props.authFunc) {
                    t.props.authFunc();
                }
                if (t.props.onSuccess) {
                    t.props.onSuccess();
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                t.setState({loading: false});
            });
    };

    handleSignIn = (login, password) => {
        const t = this;
        const data = {
            username: login,
            password: password
        };

        t.setState({loading: true});
        API.post('/jwt-auth/v1/token', data, {headers: {'Authorization': ''}})
            .then(function (r) {
                setToken(r.data.token, {login: true});
                t.setState({loading: false});

                if (t.props.authFunc) {
                    t.props.authFunc();
                }
                if (t.props.onSuccess) {
                    t.props.onSuccess();
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                t.setState({loading: false});
            });
    };

    responseFacebook = (r) => {
        this.handleSignIn(r.email, r.signedRequest);
    };

    render() {

        const s = this.state;
        const p = this.props;

        const cls_success = s.success ? 'success' : '';
        const schoolNameInput = this.props.registrationType === 'school' ? <input onChange={this.handleTextInput} value={s.display_name} type="text" name="display_name" className="text_input" placeholder="название школы" /> : '';
        const preloader = s.loading ? <Preloader/> : '';

        const labels = {
            student: <span>Согласен на обработку персональных данных, ознакомлен с <a rel="noopener noreferrer" target="_blank" href="/docs/privacy_policy.docx">политикой конфиденциальности</a>, принимаю условия <a rel="noopener noreferrer" target="_blank" href="/docs/offer_student.docx">договора-оферты</a></span>,
            school: <span>Согласен на обработку персональных данных, ознакомлен с <a rel="noopener noreferrer" target="_blank" href="/docs/privacy_policy.docx">политикой конфиденциальности</a>, принимаю условия <a rel="noopener noreferrer" target="_blank" href="/docs/offer_school.docx">Лицензионного договора-оферты</a></span>
        };
        const label = labels[this.props.registrationType];

        const buttonLabel = p.signupButton ? 'Купить' : 'Зарегистрироваться';
        const paymentLogos = p.signupButton ? <PaymentLogos /> : '';

        return (
            <div className={'sign_up_form ' + cls_success}>
                <div className="success_message">
                    <p>Пользователь успешно зарегистрирован!</p>
                    <p>Ссылка на завершение регистрации отправлена по адресу {s.success_email}</p>
                </div>
                <div className="suf_wrapper">
                    {schoolNameInput}
                    <input onChange={this.handleTextInput} value={s.email} type="text" name="email" className="text_input" placeholder="электронная почта" />
                    <input onChange={this.handleTextInput} value={s.password} type="password" name="password" className="text_input" placeholder="придумайте пароль" />

                    <Checkbox onChange={this.handleCheckbox} name="agreement" checked={s.agreement} label={label} />

                    {paymentLogos}
                    <button onClick={this.handleSubmit} disabled={!s.valid}>{buttonLabel}</button>
                    {/*<FacebookLogin*/}
                        {/*appId="690578565095189"*/}
                        {/*fields="name,email,picture"*/}
                        {/*callback={this.responseFacebook}*/}
                        {/*render={renderProps => (*/}
                            {/*<button className="small" onClick={renderProps.onClick}>Войти через Facebook</button>*/}
                        {/*)}*/}
                    {/*/>*/}
                </div>
                {preloader}
            </div>
        )
    }
}

export default SignUpForm;