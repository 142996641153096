import React from "react";

import API from "../utils/api";
import {obj2arrCheckTotal, serrializer} from "../utils/utils";
import Preloader from "../utils/preloader";
import CollectionFeed from './collection-feed';

import './index.css';
import CoursesFeaturedV2 from "../course-featured-v2";
import ProductTypeIcon from "../utils/product-type-icon";

class Collections extends React.Component {
    constructor(props) {
        super();

        this.state = {
            loading: false,
            featured: [],
            collections: [],
            totalCollections: 0,
            loadedColections: 0,
            curPage: 1,
            canLoad: true,
            bottomLoading: false,
            purchaseItemData: {}
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const s = this.state;
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const footerHeight = document.querySelectorAll('.page_footer')[0].clientHeight;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - footerHeight;
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            if (!s.bottomLoading && s.canLoad) {
                this.bottomLoad();
            }
        }
    }

    initialLoad = () => {
        const t = this;

        t.setState({loading: true});
        t.loadCollections((r) => {
            t.setState({totalCollections: parseInt(r.data.total)});

            t.parseCollectionObject(r.data.results, (collectionsArray) => {
                t.setState({loading: false});
                t.updateCollections(collectionsArray);
                window.addEventListener("scroll", this.handleScroll);
            });
        })

    };

    bottomLoad = () => {
        const t = this;
        const s = t.state;
        const nextPage = s.curPage + 1;

        if (s.loadedColections < s.totalCollections) {
            t.setState({bottomLoading: true, curPage: nextPage}, () => {
                t.loadCollections((r) => {
                    t.parseCollectionObject(r.data.results, (collectionsArray) => {
                        t.setState({bottomLoading: false});
                        t.updateCollections(collectionsArray);
                    });
                })
            });
        }
        else {
            t.setState({canLoad: false});

            document.body.classList.remove('no_footer');
        }

    };

    updateCollections = (collectionsArray) => {
        const t = this;
        const s = t.state;

        if (s.collections.length) {
            const updated = s.collections.concat(collectionsArray);
            t.setState({collections: updated});
        }
        else {
            t.setState({collections: collectionsArray});
        }
    };

    loadCollections = (cb) => {
        const t = this;
        const s = t.state;
        const params = serrializer({order_by: 'menu_order', order: 'desc', per_page: 4, paged: s.curPage, foo: '3214'});

        API.get('/med/v2/feed?' + params)
            .then(function (r) {
                cb(r);
            })
            .catch(function (error) {
                console.log(error);
                t.setState({bottomLoading: false, loading: false});
            });
    };

    parseCollectionObject = (obj, cb) => {
        const t = this;
        const s = t.state;
        const collectionsArray = obj2arrCheckTotal(obj);
        const loaded = s.loadedColections + collectionsArray.length;

        t.setState({loadedColections: loaded});

        collectionsArray.forEach((el, idx) => {
            if (el.title.toLowerCase() === 'featured') {
                t.setState({featured: el.items});
                collectionsArray.splice(idx, 1);
            }
        });


        cb(collectionsArray);
    };

    componentDidMount() {
        this.initialLoad();
        document.body.classList.add('no_footer');
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        document.body.classList.remove('no_footer');
    }

    initPayment = () => {
        const t = this;
        const p = t.props;
        const s = t.state;
        const data = s.purchaseItemData;
        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        if (!purchased) {
            t.setState({loading: true});
            API.post('/med/v3/order', {product_id: data.id, payment_method: 'ukassa'})
                .then((r) => {
                    t.setState({loading: false});
                    const purchaseLink = r.data.payment_data.payment_url;
                    p.modalPurchase(purchaseLink);
                    window.open(purchaseLink);
                })
                .catch((error) => {
                    t.setState({loading: false});
                    alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
                    console.log(error);
                })
        }
        else {
            alert('Вы уже купили этот товар!')
        }
    };

    freeCoursePurchase = () => {
        // const t = this;
        // const s = t.state;
        // const p = t.props;
        // const data = s.purchaseItemData;
        // const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        // if (!purchased) {
        //     API.get('/med/v1/pay/nothing/' + data.id)
        //         .then((r) => {
        //             p.reloadUserData();
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // }
        // else {
        //     alert('Вы уже купили этот товар!')
        // }
    };

    handlePurchase = (itemData) => {
        const t = this;
        const p = t.props;

        t.setState({purchaseItemData: itemData}, () => {
            if (p.authorized) {
                t.handleAuthorizedPurchase();
            }
            else {
                p.modalAuth(t.handleAuthorizedPurchase);
            }
        });
    };

    handleAuthorizedPurchase = () => {
        const t = this;
        const s = t.state;
        const data = s.purchaseItemData;

        if (parseInt(data.price)) {
            t.initPayment();
        }
        else {
            t.freeCoursePurchase();
        }
    };

    render() {
        const s = this.state;
        const p = this.props;

        const preloader = s.loading ? <Preloader /> : '';
        const bottomLoader = s.bottomLoading ? <div className="bottom_loader"><Preloader /> </div> : '';

        const collectionId = p.collectionId;
        let banner = '';

        if (collectionId === 'books') {
            banner = <div className="professional_banner">
                <ProductTypeIcon type="audio" notooltip="true" /><span>аудиокниги на маркетплейсе W-Hub предоставлены нашим партнером Литрес</span>
            </div>
        }

        const featuredBlock = s.featured.length >= 3 ? <CoursesFeaturedV2
            {...p}
            data={s.featured}
            collectionType={'audio'} /> : '';

        const feeds = s.collections.map((collection, idx) =>
            <CollectionFeed {...p}
                            courses={collection.items}
                            title={collection.title}
                            collectionType={'audio'}
                            key={'collection_' + idx}
                            purchaseFunc={this.handlePurchase} />
        );

        return (
            <div className="collections_page">
                {banner}
                {featuredBlock}

                <div className="cp_feeds">
                    {feeds}
                    {bottomLoader}
                </div>

                {preloader}
            </div>
        );
    }
}

export default Collections;