import React from "react";
import {Link} from 'react-router-dom';

import './index.css';
import {
    ArrowBack, CloseCross,
    TimeWatchIcon,
    TypePdf,
    TypeText,
    TypeVideo,
    TypeWebinar
} from "../../course-v3/landing/icons";
import moment from "moment";

class LessonSidebar extends React.Component {
    constructor (props) {
        super();

        const typesMap = {
            video: {
                icon: <TypeVideo />,
                value: 'видеоурок'
            },
            text: {
                icon: <TypeText />,
                value: 'текстовый урок'
            },
            pdf: {
                icon: <TypePdf />,
                value: 'PDF'
            },
            webinar: {
                icon: <TypeWebinar />,
                value: 'вебинар'
            }
        };

        this.state = {
            courseData: props.courseData,
            typesMap: typesMap
        }

    }

    lessonsMapper = (lesson, idx) => {
        const t = this;
        const {courseData, typesMap} = t.state;
        const {lessonId, onToggleSidebar} = t.props;

        const type = lesson.type ? lesson.type : 'video';

        const typeIcon = typesMap[type].icon;
        const typeTip = typesMap[type].value;

        let dateStart = '';
        let statusIcon = '';

        if (lesson.content) {
            const content = lesson.content;

            if (content.date) {
                dateStart = <p className="lesson_starts">{moment(content.date).format('DD MMMM YYYY, HH:mm')}</p>
                statusIcon = <TimeWatchIcon />;
            }
        }

        const isCurrent = lesson.id === parseInt(lessonId);
        const cls_current = isCurrent ? 'current' : '';

        return (
            <Link onClick={onToggleSidebar} to={'/course/3/' + courseData.slug + '/' + lesson.id} className={cls_current} key={idx}>
                <div data-tip={typeTip} className="type_icon">
                    {typeIcon}
                </div>
                {lesson.title}
                <span className="date_start">{dateStart}</span>

                <div className="status_icon">{statusIcon}</div>
            </Link>
        );
    };

    render() {
        const t = this;
        const {courseData, onToggleSidebar} = t.props;

        const lessons = courseData.lessons;

        return (
            <div className="lesson_sidebar">
                <div className="sidebar_tools">
                    <div onClick={onToggleSidebar} className="go_back"><Link to={'/course/3/' + courseData.slug}><ArrowBack />На страницу курса</Link></div>
                    <div onClick={onToggleSidebar} className="close_sidebar"><CloseCross /></div>
                </div>
                <div className="lessons_list">
                    {lessons.map(t.lessonsMapper)}
                </div>
            </div>
        );
    }
}

export default LessonSidebar;