import React from 'react';
import PlayerV2 from "../../../utils/player-v2";

class DlItemVideo extends React.Component {
    constructor (props) {
        super();

        this.state = {
            url: props.dt,
            originalUrl: props.dt.slice(),
            external_check: false,
            valid: true
        }
    }

    componentDidMount() {
        const t = this;
        const {dt} = t.props;
        if (dt.length) {
            t.setState({external_check: true}, t.validate);
        }
    };

    validate = () => {
        const t = this;
        const {external_check} = t.state;
        const {idx, validate} = t.props;

        validate(idx, external_check);
    };

    handleCanNotPlay = () => {
        this.setState({external_check: false, valid: false}, this.validate);
    };

    handleChange = (e) => {
        const t = this;
        const p = t.props;
        const k = e.target.name;
        const v = e.target.value;

        if (k === 'url') {
            t.setState({url: v, external_check: false, valid: true}, t.validate);
        }

        p.update(p.idx, k, v);
    };

    checkUrlChange = () => {
        const t = this;
        const {url, originalUrl} = t.state;

        return url !== originalUrl;
    };

    render() {
        const t = this;
        const {url, external_check, valid} = t.state;
        const p = t.props;

        return (
            <div className="course_dl_wrapper dl_video">
                <dl>
                    <dt>
                        <div className="video_input_holder">
                            <input maxLength={p.dtLength} placeholder={p.dtPlaceholder} onChange={t.handleChange} value={p.dt} name={p.dtName} />
                            {t.checkUrlChange() && !external_check && url.length ? <button className="small btn_check_source" onClick={() => t.setState({external_check: true}, t.validate)}>проверить</button> : ''}
                        </div>
                        {t.checkUrlChange() && !external_check && url.length ? <span className="hint">перед сохранением необходимо проверить ссылку!</span> : ''}
                        {!valid ? <span className="invalid">воспроизведение видео по этой ссылке невозможно!</span> : ''}
                        {external_check ? <PlayerV2
                            url={url}
                            onCanNotPlay={t.handleCanNotPlay}/> : ''}
                    </dt>
                    <dd>
                        <textarea maxLength={p.ddLength} placeholder={p.ddPlaceholder} onChange={t.handleChange} value={p.dd} name={p.ddName} />
                    </dd>
                </dl>
                <div data-idx={p.idx} onClick={p.removeItem} className="dl_item_delete">X</div>
            </div>
        );
    }
}

export default DlItemVideo;
