import React from 'react';

import API from "../../utils/api";
import {PriceFormat} from "../../utils/utils";

//import {Link} from "react-router-dom";
import { withId } from 'react-yandex-metrika';

class WebinarButtonsBlock extends React.Component {
    constructor (props) {
        super();

        this.state = {
            favourite: props.userData.favourites && props.userData.favourites.indexOf(props.courseData.id) > -1
        };
    }

    initPayment = () => {
        const t = this;
        const p = t.props;

        p.setLoading(true);

        API.post('/med/v3/order', {product_id: t.props.courseData.id, payment_method: 'ukassa'})
            .then((r) => {
                p.setLoading(false);
                const purchaseLink = r.data.payment_data.payment_url;
                p.modalPurchase(purchaseLink);
                window.open(purchaseLink);

                withId(62510686)('reachGoal','purchase');
            })
            .catch((error) => {
                p.setLoading(false);
                alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
                console.log(error);
            })
    };

    handlePurchaseClick = (event) => {
        const t = this;
        const p = t.props;
        const data = p.courseData;
        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        console.log(purchased)

        if (!purchased) {
            if (p.authorized) {
                t.handleAuthorizedPurchase();
            }
            else {
                if (event) {
                    event.preventDefault();
                }
                const signupAndPurchase = !!parseInt(data.regular_price);
                p.modalAuth(t.handleAuthorizedPurchase, signupAndPurchase);
            }
        }
    };

    handleAuthorizedPurchase = () => {
        const t = this;
        const p = t.props;
        const data = p.courseData;

        console.log(data)

        if (parseInt(data.regular_price)) {
            t.initPayment();
        }
        else {
            p.freeCoursePurchase();
        }
    };

    renderPurchasedButton = () => {

        return <button disabled={true}>куплено</button>;
    };

    renderBuyButton = (promo=false) => {
        const t = this;
        const p = t.props;
        const data = p.courseData;
        const discount = !!parseInt(data.discount_amount_percentage);
        const price = discount ? data.price : data.regular_price;
        let purchaseText = discount ? 'купить со скидкой' : 'купить';

        if (promo) {
            purchaseText = 'купить';
        }

        if (!parseInt(price)) {
            return <button disabled={true}>бесплатно</button>;
        }

        return <button onClick={t.handlePurchaseClick}>{purchaseText} <span>за {PriceFormat(parseInt(price))} ₽</span></button>
    };

    handleFavouriteClick = () => {
        const t = this;
        const s = t.state;
        const p = t.props;

        if (p.authorized && !s.favourite) {
            t.addFavourite();
        }
        else {
            p.modalAuth(t.handleFavouriteClick, false);
        }
    };

    addFavourite = () => {
        const t = this;
        const data = t.props.courseData;
        API.post('/med/v1/favorite/' + data.id)
            .then(function (r) {
                t.setState({favourite: true})
                t.props.authFunc();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;
        const data = p.courseData;
        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        const btnPurchased = purchased ? t.renderPurchasedButton() : '';
        const btnBuy = !purchased ? t.renderBuyButton() : '';

        const favouriteText = s.favourite ? 'вы записаны!' : 'записаться';
        const btnFavourites = <button onClick={t.handleFavouriteClick} disabled={s.favourite} className="light_border">{favouriteText}</button>;

        return (
            <div className={'buttons_block '}>

                {btnBuy}
                {btnPurchased}

                {btnFavourites}

            </div>
        );
    }
}

export default WebinarButtonsBlock;
