import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import API from '../../../utils/api';

import '../form.css';
import Preloader from "../../../utils/preloader";
import {ErrorMessage} from "@hookform/error-message";
import {reachGoal} from "../../../utils/utils";

function FSFormSZ(props) {
    const [loading, setLoading] = useState(false);
    let data = props.userData.additional_data;
    if (typeof data === "string") {
        data = JSON.parse(props.userData.additional_data);
    }
    if (!data.company_ogrnip) {
        data.company_ogrnip = ''
    }
    if (!data.company_document) {
        data.company_document = 'ustav'
    }
    if (!data.company_tax_system) {
        data.company_tax_system = 'osn'
    }
    if (!data.company_nds_ammount) {
        data.company_nds_ammount = 'free_of_charge'
    }
    if (!data.company_doverennost_number) {
        data.company_doverennost_number = '';
    }

    const { register, errors, watch, handleSubmit } = useForm();
    watch();

    const onSubmit = formData => {
        if(formData === {}) {
            formData = data;
        }
        setLoading(true);
        const newData = {};
        Object.keys(formData).forEach((el) => {
            newData[el] = checkQuote(formData[el]);
        });
        API.post('/med/v1/school/data/' + props.userData.id, newData)
            .then(function (r) {
                setLoading(false);
                props.authFunc();
                reachGoal(80212912,'school_signup_finish');
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    };

    const checkQuote = (str) => {
        const regex = /([^"]*)"([^"]*)"([^"]*)/gm;
        const subst = `$1«$2»$3`;

        return str.replace(regex, subst);
    };

    const preloader = loading ? <Preloader /> : '';
    const completed = props.formCompleted;
    const button = !completed ? <button type="submit">подтвердить</button> : '';

    const ud = props.userData;
    const checked = ud.sz_inn_status;
    const checkedText = checked ? 'проверено!' : 'ожидает проверки';
    const checkedCls = checked ? 'checked' : '';
    const checkedNote = !checked ? <p className="checked_note">Проверка самозанятого налоговой обычно занимает не более минуты, но это зависит от сервера налоговой...</p> : '';
    const checkBlock = completed ? <div className={'sz_status_check form_block ' + checkedCls}>
        Статус проверки: {checkedText}
        {checkedNote}
        </div> : '';

    return (
        <form className="default_form" onSubmit={handleSubmit(onSubmit)}>
            {checkBlock}
            <input type="hidden" defaultValue={props.companyType} name="company_type" ref={register} />
            <div className="form_block">
                <p className="form_block_label">данные самозанятого</p>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.surname} name="surname" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>Фамилия</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="surname" /></span>
                </div>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.name} name="name" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>Имя</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="name" /></span>
                </div>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.middle_name} name="middle_name" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>Отчество</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="middle_name" /></span>
                </div>

                <div className="input_block ib_832">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_inn} name="company_inn" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 12,
                            message: '12 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 12,
                            message: '12 цифр'
                        } })} />
                    <label>ИНН (самозанятого)</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_inn" /></span>
                </div>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_address} name="company_address" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>юридический адрес</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_address" /></span>
                </div>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_post_address} name="company_post_address" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>почтовый адрес</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_post_address" /></span>
                </div>
            </div>
            <div className="form_block">

                <p className="form_block_label">паспорт</p>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.document_series} name="document_series" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 4,
                            message: '4 цифры'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 4,
                            message: '4 цифры'
                        }
                    })} />
                    <label>серия</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="document_series" /></span>
                </div>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.document_number} name="document_number" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 6,
                            message: '6 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 6,
                            message: '6 цифр'
                        }
                    })} />
                    <label>номер</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="document_number" /></span>
                </div>

                <div className="input_block ib_832">
                    <input disabled={completed} placeholder=" " defaultValue={data.issued_by} name="issued_by" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>выдан</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="issued_by" /></span>
                </div>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.issued_date} name="issued_date" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>дата выдачи</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="issued_date" /></span>
                </div>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.code} name="code" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>код подразделения</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="code" /></span>
                </div>
            </div>

            <div className="form_block">
                <p className="form_block_label">банковские данные</p>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_fullname} name="bank_fullname" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>название банка</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_fullname" /></span>
                </div>

                <div className="input_block ib_304">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_accout_number} name="bank_accout_number" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 20,
                            message: '20 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 20,
                            message: '20 цифр'
                        }
                    })} />
                    <label>номер счёта</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_accout_number" /></span>
                </div>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_bik} name="bank_bik" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 9,
                            message: '9 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 9,
                            message: '9 цифр'
                        }
                    })} />
                    <label>БИК</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_bik" /></span>
                </div>

                <div className="input_block ib_304">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_offset_account} name="bank_offset_account" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 20,
                            message: '20 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 20,
                            message: '20 цифр'
                        }
                    })} />
                    <label>корр. счёт</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_offset_account" /></span>
                </div>
            </div>

            <div className="form_block">
                <p className="form_block_label">контактные данные</p>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.contact_phone} name="contact_phone" ref={register({
                        required: 'необходимо заполнить',
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        }
                    })} />
                    <label>телефон</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="contact_phone" /></span>
                </div>

                <div className="input_block ib_256">
                    <input disabled={completed} placeholder=" " defaultValue={data.contact_email} name="contact_email" ref={register({
                        required: 'необходимо заполнить',
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'необходимо ввести адрес электронной почты',
                        }
                    })} />
                    <label>е-мейл</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="contact_email" /></span>
                </div>
            </div>
            <div className="button_holder">
                {button}
            </div>
            {preloader}
        </form>
    );
}

export default FSFormSZ;