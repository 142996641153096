import React from 'react';
import './index.css';
import {
    Certificate,
    FoldSwitcherDots,
    LessonFold,
    LessonUnfold, TimeWatchIcon,
    TypePdf,
    TypeText,
    TypeVideo,
    TypeWebinar
} from "../../icons";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {arr2obj, Plural} from "../../../../utils/utils";
import {sortLessonsByType, timeConverter} from "../../duration-utils";
import moment from "moment";

class Content extends React.Component {
    constructor (props) {
        super();

        const typesMap = {
            video: {
                icon: <TypeVideo />,
                value: 'видеоурок'
            },
            text: {
                icon: <TypeText />,
                value: 'текстовый урок'
            },
            pdf: {
                icon: <TypePdf />,
                value: 'PDF'
            },
            webinar: {
                icon: <TypeWebinar />,
                value: 'вебинар'
            }
        };

        this.anchor = React.createRef();
        this.state = {
            opened: [],
            canFold: true,
            isFolded: true,
            typesMap: typesMap
        };
    }

    componentDidMount() {
        this.checkCanFold();
    };

    checkCanFold = () => {
        const t = this;
        const p = t.props;
        const lessons = p.data.lessons;
        if (lessons.length <= 3) {
            t.setState({canFold: false});
        }
    };

    switchFoldList = () => {
        const t = this;
        const {isFolded} = t.state;

        t.setState({isFolded: !isFolded})
    };

    switchFoldItem = (itemIdx) => {
        const t = this;
        const {opened} = t.state;
        const idx = opened.indexOf(itemIdx);

        if (idx === -1) {
            opened.push(itemIdx);
        }
        else {
            opened.splice(idx, 1);
        }

        t.setState({opened: opened});
    };

    lessonsMapper = (lesson, idx) => {
        const t = this;
        const {isPurchased, isFree, data} = t.props;
        const {opened, typesMap} = t.state;
        const isOpened = opened.indexOf(idx) > -1;
        const type = lesson.type ? lesson.type : 'video';

        const typeIcon = typesMap[type].icon;
        const typeTip = typesMap[type].value;

        const foldIcon = isOpened ? <LessonFold /> : <LessonUnfold />;
        const cls_opened = isOpened ? 'opened' : '';

        let lecturer = '';
        let dateStart = '';

        if (lesson.content) {
            const content = lesson.content;
            if (content.lecturer) {
                const authors = arr2obj(data.authors, 'id');
                const lecturerObj = authors[content.lecturer];

                if (lecturerObj) {
                    lecturer = <p className="lesson_lecturer">Лектор: <span>{lecturerObj.name}</span></p>;
                }
            }

            if (content.date) {
                dateStart = <p className="lesson_starts">Дата и время: {moment(content.date).format('DD MMMM YYYY, HH:mm')}</p>
            }
        }
        const btnView = isPurchased || isFree ? <div className="btn_holder"><Link to={'/course/3/' + data.slug + '/' + lesson.id}><button className="v2 light_inverted">смотреть</button></Link></div> : ''

        let duration = '';

        if (lesson.duration) {
            const time = timeConverter(lesson.duration);

            let timeText = '';

            if (time.hours) {
                timeText = time.hours + ' час' + Plural(time.hours, '', 'а', 'ов');
            }

            if (time.minutes) {
                if (time.hours) {
                    timeText += ' ';
                }

                timeText += time.minutes + ' минут' + Plural(time.minutes, 'а', 'ы', '');
            }

            duration = <p className="lesson_duration"><TimeWatchIcon />{timeText}</p>;
        }


        return (
            <div key={idx} className={'lesson_item ' + cls_opened}>
                <div onClick={() => t.switchFoldItem(idx)} className="header">
                    <div data-tip={typeTip} className="type_icon">
                        {typeIcon}
                    </div>
                    <div className="title">
                        {lesson.title}
                    </div>

                    <div className="fold_icon">{foldIcon}</div>
                </div>

                <div className="description_wrapper">
                    <div className="description">
                        {duration}
                        {lecturer}
                        {dateStart}
                        <p>{lesson.description}</p>
                        {btnView}
                    </div>
                </div>
            </div>
        );
    };

    typesMapper = (el, idx) => {
        const typesMap = {
            video: ['видеоурок', '', 'а', 'ов'],
            webinar: ['вебинар', '', 'а', 'ов'],
            pdf: ['презентаци', 'я', 'и', 'й'],
            text: ['текстов', 'ый урок', 'ых урока', 'ых уроков'],
        };

        const count = el.count;
        const type = el.type;
        const t = typesMap[type];

        return (
            <li key={idx}><span>{count}</span>{t[0]}{Plural(count, t[1], t[2], t[3])}</li>
        )
    };

    render() {
        const t = this;
        const p = t.props;
        const lessons = p.data.lessons;

        const {canFold, isFolded} = t.state;

        const foldSwitcherText = isFolded ? 'Показать всю программу' : 'Свернуть программу';
        const foldSwitcher = canFold ? <div onClick={t.switchFoldList} className="fold_switcher">{foldSwitcherText}<FoldSwitcherDots /></div> : '';
        const cls_folded = canFold && isFolded ? 'folded' : '';

        const sortedLessons = sortLessonsByType(lessons);

        const time = timeConverter(sortedLessons.duration);
        let timeText = '';
        let durationBlock = '';

        if (time.hours || time.minutes) {
            if (time.hours) {
                timeText = time.hours + ' ч';
            }

            if (time.minutes) {
                if (time.hours) {
                    timeText += ' ';
                }

                timeText += time.minutes + ' мин';
            }

            durationBlock = <li><span>{timeText}</span>общее время</li>;
        }

        return (
            <div className={'cl_content ' + cls_folded}>
                <div ref={t.anchor} className="cl_anchor" id="landingContentBlockAnchor"> </div>

                <div className="cl_centrer">
                    <div className="content_header_section">
                        <div className="chs_side">
                            <h2>Программа курса</h2>

                            <div className="certificate">
                                <Certificate />
                                Сертификат W-HUB по окончании курса
                            </div>
                        </div>

                        <div className="chs_side chs_duration">
                            <ul>
                                {sortedLessons.sortedArr.map(t.typesMapper)}
                                {durationBlock}
                            </ul>
                        </div>
                    </div>

                    <div className="content_list">
                        {lessons.map(t.lessonsMapper)}
                    </div>

                    <div className="switcher_centrer">
                        {foldSwitcher}
                    </div>
                </div>

                <ReactTooltip delayShow={5} effect={'solid'} />
            </div>
        );
    }
}

export default Content;
