import React from "react";

import './index.css';
import API from "../../../utils/api";
import FormWHub from "./forms/whub";
import FormYoutube from "./forms/youtube";

class LecturerDashboard extends React.Component {
    constructor (props) {
        super();
    }

    componentDidMount() {
    };

    createStream = () => {
        const t = this;
        const p = t.props;
        const lectureData = p.lectureData;
        p.setLoading(true);

        API.post('/med/v3/mux/', {lecture_id: lectureData.id})
            .then(function (r) {
                p.setLoading(false);
                p.reloadData();
                console.log(r)
            })
            .catch(function (error) {
                console.log(error)
            });
    };

    render() {
        const t = this;
        const p = t.props;
        const data = p.lectureData;
        const isWhubLive = data.is_whub_live;

        const broadcastService = isWhubLive ? 'W-Hub.Live' : 'Youtube Live';
        const form = isWhubLive ? <FormWHub {...p} createStream={t.createStream}/> : <FormYoutube {...p}/>;

        return (
            <div className="lecturer_dashboard">
                <h4>Панель управления трансляцией</h4>
                <h5>Сервис трансляции: <strong>{broadcastService}</strong></h5>

                <div className="broadcast_settings_form">
                    {form}
                </div>

            </div>
        );
    }
}


export default LecturerDashboard;