import React from 'react';
import CourseRatingBlock from "../../courses/course-rating-block";
import LecturesList from "./lectures-list";

class WebinarText extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.courseData;

        const description = data.description.length ? data.description : data.short_description;

        const rating_block = p.authorized && p.userData.role === 'student' ? <CourseRatingBlock {...p} setLoading={p.setLoading} type="webinar" /> : '';

        return (
            <div className="course_text">
                <div className="course_grid_item">
                    <h5>о вебинаре</h5>
                    <div className="cgi_content">
                        <div className="course_description">{description}</div>
                    </div>
                </div>

                <div className="course_grid_item">
                    <h5>о лекторе</h5>
                    <div className="cgi_content">
                        <div className="course_description">...</div>
                    </div>
                </div>


                <LecturesList {...p} />

                {rating_block}

            </div>
        );
    }
}

export default WebinarText;
