import React from "react";

import Preloader from "../../utils/preloader";
import {arr2obj, Plural} from "../../utils/utils";
import API from "../../utils/api";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import Dropdown from "../../utils/dropdown/dropdown";
import { addDays } from '@progress/kendo-date-math';

class EditLectureItem extends React.Component {
    constructor (props) {
        super();

        const lessonData = props.lessonData;

        const lectureDurationOptions = [
            {value: 30, label: '30 минут'},
            {value: 45, label: '45 минут'},
            {value: 60, label: '1 час'},
            {value: 90, label: '1.5 часа'},
            {value: 120, label: '2 часа'}
        ];

        this.state = {
            loading: false,
            changes: false,
            file: null,
            valid: lessonData.title.length,
            id: props.courseId,
            lesson_id: lessonData.id,
            title: lessonData.title,
            description: lessonData.description,
            is_whub_live: lessonData.is_whub_live,
            descriptionLength: lessonData.description.length,
            file_name: '',
            external_check: false,
            lectureDurationOptions: lectureDurationOptions,
            lectureStart: lessonData.date_start ? new Date(lessonData.date_start*1000) : addDays(new Date(), 1),
            lectureDuration: lessonData.duration ? lessonData.duration : 0,
        };
    }

    handleNameInput = (e) => {
        const t = e.target;

        t.style.height = "";

        let h = t.scrollHeight;

        t.style.height = h + "px";
    };

    handleLinebreak = (e) => {
        if (e.which === 13) {
            e.preventDefault();
        }
    };

    switchVideoSection = (e) => {
        const v = parseInt(e.currentTarget.getAttribute('data-cloud'));

        this.setState({is_whub_live: !!v}, this.validate);
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    validate = () => {
        const s = this.state;
        const d = this.props.lessonData;
        const testChanges = s.title !== d.title
            || s.description !== d.description
            || s.lectureDuration !== d.duration
            || s.is_whub_live !== d.is_whub_live
            || s.lectureStart.getTime() !== d.date_start * 1000;

        const testValid = s.title.length && s.description.length && s.lectureDuration && testChanges;

        this.setState({changes: testChanges, valid: testValid});
    };

    componentDidMount() {
        this.validate();
    };

    saveChanges = (opts) => {
        const t = this;
        const s = t.state;
        const data = t.props.lessonData;
        let d = {
            id: s.id,
            title: s.title,
            is_whub_live: s.is_whub_live,
            description: s.description,
            post_type: 'lecture',
            duration: s.lectureDuration,
            date_start: s.lectureStart.getTime() / 1000
        };

        t.setState({loading: true});

        if (!data.blank) {
            d.lesson_id = data.id;
            t.updateLesson(d, (r) => {

                t.setState({loading: false});

                const updatedData = JSON.parse(JSON.stringify(d));
                updatedData.id = d.lesson_id;
                delete updatedData.lesson_id;

                t.props.onSave(updatedData.id, updatedData);
                t.validate();
            });
        }
        else {
            t.createLesson(d, (r) => {
                d.id = r.data.id;
                t.setState({lesson_id: d.id, loading: false});
                t.props.onSave(data.id, d);
            });
        }

    };

    createLesson = (d, cb) => {
        const t = this;
        API.post('/med/v1/lesson/' + d.id, d)
            .then(cb)
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    updateLesson = (d, cb) => {
        const t = this;
        API.put('/med/v1/lesson/' + d.id, d)
            .then(cb)
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    deleteLesson = () => {
        const t = this;
        const s = t.state;
        const d = {data: {
                lesson_id: s.lesson_id
            }};

        t.setState({loading: true});
        if (t.props.lessonData.blank) {
            t.props.deleteFunc();
        }
        else {
            API.delete('/med/v1/lesson/' + s.id, d)
                .then(function (r) {
                    t.setState({loading: false});
                    t.props.deleteFunc();
                })
                .catch(function (error) {
                    t.setState({loading: false});
                    alert(error)
                });
        }

    };

    handleLectureDurationChange = (d) => {
        this.setState({lectureDuration: d.data.value}, this.validate);
    };

    getSelectedOption = (arr, val) => {
        const obj = arr2obj(arr, 'value');
        if (obj[val]) {
            return obj[val];
        }
        return arr[0];
    };

    render() {
        const t = this;
        const s = t.state;

        const sales = parseInt(t.props.courseData.total_sales);


        const preloader = s.loading ? <Preloader/> : '';
        const maxLessonDescriptionLength = 1000;

        const sourceTypeSwitcher = <div className="eli_video_tabs">
            <h5 onClick={t.switchVideoSection} className={s.is_whub_live ? 'active' : ''} data-cloud="1">Использовать W-Hub.Live</h5>
            <h5 onClick={t.switchVideoSection} className={!s.is_whub_live ? 'active' : ''} data-cloud="0">Использовать Youtube.Live</h5>
        </div>;


        const lectureDurationDefaultOption = [{label: 'выбрать', value: null}];
        const lectureDurationDropdownArr = lectureDurationDefaultOption.concat(s.lectureDurationOptions);
        const lectureDurationSelected = this.getSelectedOption(lectureDurationDropdownArr, parseInt(s.lectureDuration));

        return (
            <div className="edit_lecture_item">
                <div className="eli_position">{'Лекция ' + (t.props.idx + 1) + '.'}</div>
                <div className="eli_title_block">
                    <textarea className="eli_title_input" onInput={t.handleNameInput} onKeyDown={t.handleLinebreak} name="title" onChange={t.handleTextInput} value={s.title} rows="1" placeholder="название лекции" />
                </div>
                <div className="eli_video">
                    {sourceTypeSwitcher}
                </div>

                <div className="eli_params_block">
                    <div className="cpb_item">
                        <h5>дата и время</h5>
                        <DatePicker
                            className="text_input"
                            selected={s.lectureStart}
                            locale={ru}
                            minDate={addDays(new Date(), 1)}
                            onChange={date => t.setState({lectureStart: date}, () => t.validate())}
                            showTimeSelect
                            timeCaption="время"
                            dateFormat="dd.MM.yyyy HH:mm"
                        />
                    </div>

                    <div className="cpb_item">
                        <h5>продолжительность</h5>
                        <Dropdown onChange={this.handleLectureDurationChange} name="webinarLength" className="dd_cats" selected={lectureDurationSelected} ddArr={lectureDurationDropdownArr} />
                    </div>
                </div>


                <div className="eli_description">
                    <h5>описание лекции</h5>
                    <textarea maxLength={maxLessonDescriptionLength} onKeyUp={(e) => t.setState({descriptionLength: e.target.innerHTML.length})} name="description" onChange={t.handleTextInput} value={s.description} />
                    <div className="course_form_hint">осталось {maxLessonDescriptionLength - s.descriptionLength} символ{Plural(maxLessonDescriptionLength - s.descriptionLength, '', 'а', 'ов')}</div>
                </div>

                <div className="ce_button_holder">
                    <button className="small light_border red" onClick={t.deleteLesson} disabled={sales}>Удалить</button>
                    <button className="small light_border" onClick={t.saveChanges} disabled={!s.valid}>Сохранить</button>
                </div>

                {preloader}
            </div>
        );
    };
}

export default EditLectureItem;