import React from "react";
import API from "./utils/api";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorLogged: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    logger = () => {
        const t = this;
        const {authorized, userData} = t.props;
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
        let body = window.location.href;

        if (authorized) {
            body += '; authorizedUserId: ' + userData.id;
        }

        const data = {
            name: 'autologger',
            email: 'dev@w-hub.ru',
            body: body
        };

        if (env !== 'dev') {
            API.post('/med/v1/feedback', data)
                .then(function (r) {
                    console.log(r)
                })
                .catch(function (error) {
                    if (error.response) {
                        const message = error.response.data.message;
                        console.log(message);
                    }
                });
        }

    };

    componentDidCatch(error, errorInfo) {
        console.log(error)
        console.log(errorInfo)
    }

    render() {
        const t = this;
        const {hasError, errorLogged} = t.state;
        if (hasError) {
            if (!errorLogged) {
                t.setState({errorLogged: true});
                t.logger();
            }
            return <h1>Что-то пошло не так! Наши роботы уже оповестили программистов об ошибке, скоро все снова заработает!</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;