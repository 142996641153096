import React from 'react';
import './index.css';

class HostingHint extends React.Component {
    constructor (props) {
        super();
    }

    listMapper = (el, idx) => {
        return <p key={idx}><a rel="noreferrer noopener" href={el.url}>{el.name}</a></p>
    };

    render() {
        const freeCoursesRecommended = [
            {name: 'Youtube', url: 'https://www.youtube.com/'},
            {name: 'Vimeo', url: 'https://vimeo.com/'}
        ];

        const paidCoursesRecommended = [
            {name: 'Youtube', url: 'https://www.youtube.com/'},
            {name: 'Vimeo', url: 'https://vimeo.com/'},
            {name: 'Kinescope', url: 'https://kinescope.io/'},
            {name: 'Wistia', url: 'https://wistia.com/'},
            {name: 'GetCourse', url: 'https://getcourse.ru/'},
            {name: 'Dropbox', url: 'https://www.dropbox.com/'},
            {name: 'Mail.ru Cloud', url: 'https://mcs.mail.ru/'},
            {name: 'Yandex.Cloud', url: 'https://cloud.yandex.ru/'}
        ];

        return (
            <div className="video_hosting_hint">
                <div className="list">
                    <h4>Для бесплатных курсов:</h4>
                    {freeCoursesRecommended.map(this.listMapper)}
                </div>
                <div className="list">
                    <h4>Для платных курсов:</h4>
                    {paidCoursesRecommended.map(this.listMapper)}
                </div>
            </div>
        );
    }
}

export default HostingHint;
