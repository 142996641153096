import React, {useEffect, useState} from "react";

import {ScrollDebounce, html2plaintext} from "../../utils/utils";
import GeoPattern from "geopattern";

import Preloader from "../../utils/preloader";
import CourseRelated from "../../courses/course-related";
import CourseSocials from "../../courses/course-socials";
import CourseButtonsSchool from "../../courses/course-buttons-school";
import WebinarButtonsBlock from "./buttons-block";
import WebinarParams from "./params";
import WebinarText from "./text";
import CourseLevel from "../../utils/course-level";

function WebinarLanding(props) {
    const [fixed, setFixed] = useState(false);
    const [loading, setLoading] = useState(false);

    const data = props.courseData;

    let coverPattern = 'none';
    const imgObj = data.images_stack;
    const coverSrc = imgObj.cover && imgObj.cover.normal ? imgObj.cover.normal : null;


    let cover = coverSrc ? <div className="cover_wrapper"><img src={coverSrc} alt="" /></div> : '';

    if (!cover.length) {
        coverPattern = GeoPattern.generate(data.name, {color: '#434A5C'});
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollCrunch);
        scrollFunc();

        return () => {
            window.removeEventListener('scroll', scrollCrunch);
        }
    });

    const scrollCrunch = () => {
        return ScrollDebounce(scrollFunc());
    };

    const scrollFunc = () => {
        const scroll = window.scrollY;
        const treshold = 517;
        const bottom = document.querySelectorAll('.page_view')[0].clientHeight - window.innerHeight + document.querySelectorAll('.page_footer')[0].clientHeight;

        if (scroll >= treshold && scroll < bottom && !fixed) {
            setFixed('fixed');
        }

        if ((scroll < treshold || scroll >= bottom) && fixed) {
            setFixed('');
        }
    };

    const preloader = loading || props.loading ? <Preloader /> : '';


    let course_buttons = '';

    if (props.authorized && props.userData.role === 'school') {
        course_buttons = <CourseButtonsSchool {...props} />;
    }
    else {
        course_buttons = <WebinarButtonsBlock {...props} setLoading={setLoading} />;
    }

    const params = <WebinarParams courseData={data} />;
    const text = <WebinarText {...props} courseData={data} setLoading={setLoading} />;

    return (
        <div className="course_default">
            <div className={'course_top'}>
                <div className={'bg'} style={{backgroundImage: coverPattern.toDataUrl()}}>{cover}</div>
                <div className={'ct_content'}>
                    <div className="ct_headers">
                        <h6>{data.categories[0].name} <CourseLevel lvl={data.difficulty} /></h6>
                        <h1>{html2plaintext(data.name)}</h1>
                    </div>

                    {params}
                </div>
            </div>

            <div className="course_body">
                <div className="course_side_wrapper">
                    <div className={'course_side ' + fixed}>
                        {course_buttons}
                        <CourseSocials />

                        <div className="cs_info">
                            <h6>{data.categories[0].name}</h6>
                            <h2>{data.name}</h2>

                            {params}
                        </div>
                    </div>
                </div>

                {text}

            </div>

            <CourseRelated {...props} ids={data.related_ids} />

            {preloader}
        </div>
    );
}

export default WebinarLanding;