import React from 'react';

import CoursesFeed from "../courses/courses-feed";
import API from "../utils/api";
import {obj2arr, serrializer} from "../utils/utils";

import './course-related.css';
import Preloader from "../utils/preloader";

class CourseRelated extends React.Component {
    constructor (props) {
        super();

        this.state = {
            ids: null,
            courses: null,
            loaded: false
        };
    }

    loadRelated = () => {
        const t = this;
        const p = t.props;

        if (p.ids && p.ids.length) {
            t.setState({loading: true});
            API.get('/med/v2/courses?' + serrializer({include: p.ids, per_page: 5}))
                .then(function (r) {

                    const data = obj2arr(r.data.results);

                    t.setState({courses: data, loading: false});
                })
                .catch(function (error) {
                    console.log(error);
                    t.setState({loading: false});
                });
        }

    };
    componentDidMount() {
        const t = this;
        const p = t.props;

        if (p.ids && p.ids.length) {
            this.setState({ids: p.ids}, t.loadRelated);
        }
    };

    componentDidUpdate() {
        const t = this;
        const p = t.props;
        const {ids} = t.state;

        if (p.ids && p.ids.length && ids !== p.ids) {
            this.setState({ids: p.ids}, t.loadRelated);
        }
    };

    render() {
        const s = this.state;

        const preloader = s.loading ? <Preloader /> : '';

        return s.courses ? <div className="course_related">
            <h5>вас может заинтересовать:</h5>
            <CoursesFeed {...this.props} courses={s.courses} />
            {preloader}
        </div> : '';
    }
}

export default CourseRelated;
