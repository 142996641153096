import React from 'react';
import { Link } from 'react-router-dom';

import {CourseRating} from "../utils/icons";

import './top-featured.css';
import {html2plaintext, Plural} from "../utils/utils";
import CourseLevel from "../utils/course-level";
import TopScreen from "./top-screen";

//
// import CourseParams from "../courses/course-params";
// import Dialogs from "./dialogs";
// import w0 from './images/top/w0.png';
// import h0 from './images/top/h0.png';

class TopFeatured extends React.Component {
    constructor (props) {
        super();
    }

    renderTopFeatured = () => {
        const data = this.props.data;
        if (data.id) {
            return (
                <div className="main_top_featured">
                    <div className="bg"><img src={data.images_stack.cover.normal} alt="" /></div>
                    <div className="tf_content">
                        <h6>{data.categories[0].name} <CourseLevel lvl={data.difficulty} /></h6>
                        <h1>{data.name}</h1>
                        <p className="teaser">{html2plaintext(data.short_description)}</p>

                        {/*<CourseParams courseData={data} />*/}

                        <div className="course_params">
                            <dl className="cp_rating">
                                <dt>Рейтинг</dt>
                                <dd><CourseRating rating={data.rating}/>{data.rating}</dd>
                            </dl>
                            <dl>
                                <dt>Продолжительность:</dt>
                                <dd>{data.lessons_count} урок{Plural(parseInt(data.lessons_count), '', 'а', 'ов')}</dd>
                            </dl>
                            {/*<dl>*/}
                            {/*<dt>Автор:</dt>*/}
                            {/*<dd>{data.meta.chanel_title}</dd>*/}
                            {/*</dl>*/}
                        </div>
                    </div>

                    <Link className="tf_link" to={'/course/' + data.id}><button className="light">Учиться!</button></Link>
                </div>
            );
        }

        return (<div className="main_top_featured">
        </div>);
    };

    render() {
        //const data = this.props.data;

        //return this.renderTopFeatured();
        return (
            <div className="main_top_featured main_banner">
                {/*<div className="bg">*/}
                    {/*<div className="wh_banner">*/}
                        {/*<img className="img_banner_w" src={w0} alt="" />*/}
                        {/*<img className="img_banner_h" src={h0} alt="" />*/}
                    {/*</div>*/}
                {/*</div>*/}


                {/*<div className="tf_content">*/}
                    {/*<h1><nobr>W-Hub&nbsp;&mdash;&nbsp;это</nobr> маркетплейс образовательного контента</h1>*/}
                    {/*<p className="teaser">Более 11 000 <Link to={'/search?video=true&audio=false'}>видеокурсов</Link> и аудиокниг</p>*/}

                {/*</div>*/}

                {/*<Dialogs />*/}

                <TopScreen {...this.props} />

            </div>
        );
    }
}

export default TopFeatured;
