import React from 'react';
import './index.css';

import { Link, Redirect } from 'react-router-dom';
import {SearchIcon3} from "../../utils/icons";

class TopScreen extends React.Component {
    constructor (props) {
        super();

        this.state = {
            focused: false,
            search: '',
            request: null
        }
    }

    handleInput = (e) => {
        this.setState({search: e.target.value});
    };

    handleFocus = () => {
        this.setState({focused: true});
    };

    handleBlur = () => {
        this.setState({focused: false});
    };

    handleSearch = (e) => {
        e.preventDefault();

        const {search} = this.state;

        if (search.length) {
            this.setState({request: search});
        }
    };

    render() {
        const t = this;
        const {focused, search, request} = t.state;

        const cls_focused = focused ? 'focused' : '';

        const redirect = request ? <Redirect to={'/search?request=' + request} /> : '';
        const searchPlaceholder = 'Найди свой курс';

        return (
            <div className="main_top_screen">

                {redirect}

                <div className="mts_bg"> </div>
                <div className="mts_text">
                    <h1><strong>Coursmed</strong>
                        <nobr>цифровая платформа</nobr> <nobr>медицинского образования</nobr></h1>
                    <div className="w_mobile"> </div>
                    <p>Смотри онлайн-курсы, <Link to="/search">видеокурсы</Link>, вебинары и статьи другое или <Link to="/landings/school">размещай свой образовательный контент</Link></p>
                    <div className="h_mobile"> </div>
                </div>

                <div className={'mts_search ' + cls_focused}>
                    <form onSubmit={t.handleSearch}>
                        <input value={search} onChange={t.handleInput} onFocus={t.handleFocus} onBlur={t.handleBlur} className="search_input" placeholder={searchPlaceholder} type="text" />
                        <div onClick={t.handleSearch} className="search_button"><SearchIcon3 /></div>
                    </form>
                </div>
            </div>
        );
    }
}

export default TopScreen;
