import React from 'react';
import DlItem from "./dl-item";
import './dl-item.css';

class AddAuthorItem extends React.Component {
    constructor (props) {
        super();

        this.state = {
            isNew: true
        };
    }

    render() {
        const t = this;
        // const {isNew} = t.state;
        const p = t.props;

        return (
            <DlItem {...p} />
        );
    }
}

export default AddAuthorItem;
