import React from 'react';

import './courses-block.css'
import CoursePreviewV2 from "../course-preview-v2";

class CoursesBlock extends React.Component {
    constructor (props) {
        super();
    }

    renderFeed = (d) => {
        let feed = [];

        for (let i=0;i<d.length;i++) {
            feed.push(<CoursePreviewV2 {...this.props} itemData={d[i]} key={i} itemId={i} />);
        }

        return feed;
    };

    render() {
        const data = this.props.data;

        return (
            <div className="courses_block">
                {this.renderFeed(data)}
            </div>
        );
    }
}

export default CoursesBlock;
