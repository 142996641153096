import React from "react";

import '../index.css';
import API from "../../../../utils/api";

class FormYoutube extends React.Component {
    constructor (props) {
        super();
        const data = props.lectureData;

        this.state = {
            stream_url: data.stream_url ? data.stream_url : ''
        }
    }

    componentDidMount() {
    };

    handleChange = (e) => {
        this.setState({stream_url: e.target.value})
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const t = this;
        const p = t.props;
        const webinarData = p.webinarData;
        const lectureData = p.lectureData;

        p.setLoading(true);

        const d = {
            id: webinarData.id,
            lesson_id: lectureData.id,
            post_type: 'lecture',
            stream_url: t.state.stream_url
        };

        API.put('/med/v1/lesson/' + d.id, d)
            .then((r) => {
                p.setLoading(false);
                p.reloadData();
                console.log(r)
            })
            .catch(function (error) {
                p.setLoading(false);
                alert(error)
            });
    };

    render() {
        const t = this;
        const s = t.state;
        const data = t.props.lectureData;
        const changes = s.stream_url !== data.stream_url;

        return (
            <div>
                <div className="broadcast_manual">
                    <ol>
                        <li>Создайте и запустите трансляцию использую сервис Youtube Live</li>
                        <li>Скопируйте ссылку на трансляцию</li>
                        <li>Вставьте ссылку в поле "ссылка на трансляцию" и нажмите кнопку "сохранить"</li>
                        <li>Нажмите кнопку play в превью справа</li>
                    </ol>
                    <p>Если все сделано правильно, справа вы увидите запущенную трансляцию. Можете начинать лекцию!</p>
                </div>
                <div className="default_form">
                    <div className="input_block">
                        <input placeholder=" " value={s.stream_url} onChange={t.handleChange} />
                        <label>ссылка на трансляцию</label>
                    </div>
                    <button onClick={t.handleSubmit} disabled={!s.stream_url.length || !changes} className="small">сохранить</button>
                </div>
            </div>
        );
    }
}


export default FormYoutube;