import React from 'react';
import {Link} from 'react-router-dom';
import './buttons.css';
import {PriceFormat} from "../../../../../utils/utils";
import API from "../../../../../utils/api";
import SocialShare from "../../social-share";
import Spinner from "../../../../../utils/spinner";

class HeaderButtons extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            moderationDone: false
        };
    }

    checkAuthorization = (cb) => {
        const t = this;
        const p = t.props;

        if (p.authorized) {
            cb()
        }
        else {
            p.modalAuth(cb, false);
        }

    };

    handleEntryClick = () => {
        const t = this;

        t.checkAuthorization(() => {
            const p = t.props;
            const data = p.data;
            const userData = p.userData;
            const hasEntry = userData.course_entries && userData.course_entries.indexOf(data.id) > -1;

            if (!hasEntry) {
                t.processEntry();
            }
        });
    };

    processEntry = () => {
        const t = this;
        const p = t.props;
        const data = p.data;

        t.setState({loading: true});
        API.put('/med/v3/subscribe/' + data.id, {'entity': 'course'})
            .then(function (r) {
                p.reloadUserData();

                t.setState({loading: false});
            })
            .catch(function (error) {
                console.log(error.response);

                t.setState({loading: false});
            });
    };

    cancelEntry = () => {
        const t = this;
        const p = t.props;
        const data = p.data;

        t.setState({loading: true});
        API.delete('/med/v3/subscribe/' + data.id)
            .then(function (r) {
                p.reloadUserData();

                t.setState({loading: false});
            })
            .catch(function (error) {
                console.log(error.response);

                t.setState({loading: false});
            });
    };

    handlePurchaseClick = () => {
        const t = this;

        t.checkAuthorization(() => {
            const p = t.props;
            const data = p.data;
            const userData = p.userData;
            const isPurchased = userData.purchased && userData.purchased.indexOf(data.id) > -1;

            if (!isPurchased) {
                t.processPurchase();
            }
        });
    };

    processPurchase = () => {
        const t = this;
        const p = t.props;
        const data = p.data;

        const hasDiscount = !!parseInt(data.discount_amount_percentage);
        const price = hasDiscount ? data.price : data.regular_price;
        const isFree = !price;

        if (isFree) {
            p.freeCoursePurchase();
        }
        else {
            t.initPayment();
        }
    };

    initPayment = () => {
        const t = this;
        const p = t.props;

        p.setLoading(true);

        API.post('/med/v3/order', {product_id: p.data.id, payment_method: 'ukassa'})
            .then((r) => {
                console.log(r)
                p.setLoading(false);
                const purchaseLink = r.data.payment_data.payment_url;
                p.modalPurchase(purchaseLink);
                window.open(purchaseLink);

                //withId(62510686)('reachGoal','purchase');
            })
            .catch((error) => {
                p.setLoading(false);
                console.log(error);
                alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
            });
    };

    handleFavouriteClick = () => {
        const t = this;

        t.checkAuthorization(() => {
            const p = t.props;
            const data = p.data;
            const userData = p.userData;
            const isFavourited = userData.favourites && userData.favourites.indexOf(data.id) > -1;

            if (!isFavourited) {
                t.addFavourite();
            }
        });
    };

    addFavourite = () => {
        const t = this;
        const p = t.props;
        const data = p.data;
        API.post('/med/v1/favorite/' + data.id)
            .then(function (r) {
                p.reloadUserData();
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    renderViewButton = () => {
        const t = this;
        const p = t.props;
        const data = p.data;

        if (p.progress) {
            return <Link to={'/course/3/' + data.slug + '/' + p.progress.file_index}><button className="v2">Продолжить</button></Link>;
        }

        if (!data.lessons || !data.lessons[0]) {
            return '';
        }

        return <Link to={'/course/3/' + data.slug + '/' + data.lessons[0].id}><button className="v2">Смотреть</button></Link>;
    };

    moderateHide = () => {
        const t = this;
        const {data} = t.props;
        t.setState({loading: true});
        API.delete('/med/v3/moderate/course/' + data.id)
            .then(function (r) {
                t.setState({loading: false, moderationDone: true});
                alert('Курс переведен в черновики!')
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    render() {
        const t = this;
        const {loading, moderationDone} = t.state;
        const {data, userData, authorized, handleGift, scrollToContent, handleReport, isOwner} = t.props;

        const hasDiscount = !!parseInt(data.discount_amount_percentage);
        const price = hasDiscount ? data.price : data.regular_price;
        const isFree = !price;
        const isPurchased = userData.purchased && userData.purchased.indexOf(data.id) > -1;
        const hasEntry = userData.course_entries && userData.course_entries.indexOf(data.id) > -1;
        const isSchool = authorized && userData.role === 'school';
        const isTester = isSchool && userData.tester;

        const discountText = hasDiscount ? <div className="discount_text">Со скидкой {data.discount_amount_percentage} %</div> : '';
        const actualPrice = isFree ? 'Бесплатно' : PriceFormat(parseInt(Math.ceil(price))) + ' ₽';
        const regularPriceBlock = hasDiscount ? <div className="regular_price_block">
            <p>Стоимость без скидки</p>
            <span>{PriceFormat(parseInt(Math.ceil(data.regular_price)))} ₽</span>
        </div> : '';

        let purchaseText = '';

        if (isPurchased) {
            purchaseText = 'В моих курсах';
        }
        else {
            if (isFree) {
                purchaseText = 'В мои курсы'
            }
            else {
                purchaseText = 'Купить';
            }
        }

        const meta = data.meta ? data.meta : {};
        const onlineCourseStatus = meta.online_course_status ? meta.online_course_status : {};
        const dateNow = new Date();
        const sellsStarted = onlineCourseStatus.sell_starts_date && dateNow.getTime() >= onlineCourseStatus.sell_starts_date;

        const isOnlineCourse = meta.date_start && parseInt(data.meta.date_start) !== 0;
        const isAvailableForEntry = onlineCourseStatus.available_for_entry ? onlineCourseStatus.available_for_entry : false;
        const hasSellsStarts = isOnlineCourse && onlineCourseStatus.sell_starts_date && parseInt(onlineCourseStatus.sell_starts_date) > 0;

        const btnPurchase = !isSchool && !isPurchased && (!hasSellsStarts || sellsStarted) ? <button onClick={t.handlePurchaseClick} className="v2">{purchaseText}</button> : '';
        const btnEntry = !isSchool && !isPurchased && !hasEntry && isAvailableForEntry && !sellsStarted ? <button onClick={t.handleEntryClick} className="v2">Записаться</button> : '';

        const btnCancelEntry = !isSchool && !isPurchased && isOnlineCourse && hasEntry ? <button onClick={t.cancelEntry} className="v2">Отписаться</button> : '';


        const btnGift = !isFree && authorized && !isSchool ? <button onClick={handleGift} className="v2">Подарить</button> : '';

        const btnView = isPurchased ? t.renderViewButton() : '';

        const isFavourited = userData.favourites && userData.favourites.indexOf(data.id) > -1;
        const favouriteText = isFavourited ? 'В избранном' : 'В избранное';
        const cls_favourited = isFavourited ? 'favourited' : '';
        const btnFavourite = !isSchool ? <span onClick={t.handleFavouriteClick} className={'favourite_link ' + cls_favourited}>{favouriteText}</span> : '';

        const btnEdit = isOwner ? <Link to={'/course/' + data.id + '/edit/general'}><button className="v2 btn_edit">Редактировать</button></Link> : '';

        const btnModerate = isTester && !isOwner && !moderationDone ? <button onClick={t.moderateHide} className="v2 btn_moderate red">Скрыть курс</button> : '';

        const schoolText = isSchool && !isOwner ? <p className="school_text">К сожалению, покупка курсов для школ пока недоступна!</p> : '';


        return (
            <div className="header_buttons">
                {btnFavourite}
                <div className="share_holder">
                    <SocialShare />
                </div>
                <div className="price_block">
                    {discountText}
                    <div className="actual_price">{actualPrice}</div>
                    {regularPriceBlock}
                </div>
                {btnView}
                {btnPurchase}
                {btnEntry}
                {btnCancelEntry}
                {btnGift}
                {btnEdit}
                {btnModerate}
                {schoolText}
                <button onClick={scrollToContent} className="v2 light">Программа</button>
                <div className="report_link_wrapper">
                    <span onClick={handleReport} className="report_link">Пожаловаться</span>
                </div>
                <Spinner show={loading} />
            </div>
        );
    }
}

export default HeaderButtons;
