import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';
import {Logo} from "../icons";
import Preloader from "../preloader";
import API from "../api";

class TosConfirmationScreen extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false
        }
    }

    handleConfirm = () => {
        const t = this;

        t.setState({loading: true});

        API.patch('/med/v1/users/me', {tos_accepted: true})
            .then(function (r) {
                console.log(r)
                t.props.reloadUserData();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;

        const agreements = {
            student: <a rel="noopener noreferrer" target="_blank" href="/docs/offer_student.docx">Лицензионного договора-оферты</a>,
            school: <a rel="noopener noreferrer" target="_blank" href="/docs/offer_school.docx">Лицензионного договора-оферты</a>
        };

        const preloader = s.loading ? <Preloader /> : '';

        return (
            <div className="tos_confirmation_screen">
                <div className="tos_centrer">
                    <div className="tcs_logo_holder"><Logo /></div>
                    <p>Для продолжения использования маркетплейса подтвердите, что Вы:</p>
                    <ul>
                        <li>согласны на обработку персональных данных</li>
                        <li>ознакомлены с <a rel="noopener noreferrer" target="_blank" href="/docs/privacy_policy.docx">Политикой конфиденциальности и обработки персональных данных</a></li>
                        <li>принимаете условия {agreements[p.role]}</li>
                    </ul>
                    <div className="tcs_buttons_holder">
                        <Link to={'/logout'}><button className="small light_border">выход</button></Link>
                        <button onClick={t.handleConfirm} className="small">Я подтверждаю!</button>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default TosConfirmationScreen;
