import React from 'react';

import CoursesFeed from "../courses/courses-feed";

class ColectionFeed extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const p = this.props;

        return (
            <div className="main_feed_wrapper">
                <h2>{p.title}</h2>

                <CoursesFeed {...p} courses={p.courses} collection={true} />
            </div>
        );
    }
}

export default ColectionFeed;
