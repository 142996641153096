import React, {useState} from "react";
import {arr2obj, html2plaintext, checkOwner} from "../utils/utils";
import {Link, Redirect, useParams} from 'react-router-dom';
import {ArrowPrev, Close} from "../utils/icons";
import CourseRelated from "../courses/course-related";
import PlayerV2 from "../utils/player-v2";
import Tracker from "../utils/tracker";

function LessonDefault(props) {
    let pageParams = useParams();

    const data = props.courseData;
    const lessonsList = data.lessons;
    const lessonsObj = arr2obj(lessonsList, 'id');

    const lessonID = pageParams.lesson_id;
    const currentVideoData = lessonsObj[lessonID];
    const videoCode = currentVideoData.video_code;

    const siblingVideo = () => {
        const idx = lessonsList.indexOf(currentVideoData);
        const siblings = {};

        if (idx-1 >= 0) {
            const prevID = lessonsList[idx-1].id;
            siblings.prev = <Link className="sibling_video_link" to={'/course/3/' + data.slug + '/' + prevID}><ArrowPrev />предыдущий</Link>;
        }

        if (idx + 1 < lessonsList.length) {
            const nextID = lessonsList[idx+1].id;
            siblings.next = <Link className="sibling_video_link" to={'/course/3/' + data.slug + '/' + nextID}>следующий<ArrowPrev /></Link>;
        }

        return siblings;
    };

    const lesson_description = currentVideoData.description ? <div className="lesson_grid_item">
        <h5>описание урока</h5>
        <div className="lgi_content">
            <div className="lesson_description">{currentVideoData.description}</div>
        </div>
    </div> : '';

    const content_source = data.video_source.length ? <div className="lesson_grid_item">
        <h5>источник контента</h5>
        <div className="lgi_content">
            <div className="lesson_description"><a rel="noopener noreferrer" href={data.video_source} target="_blank">{data.video_source}</a></div>
        </div>
    </div> : '';

    const youtubeLessonCode = !currentVideoData.is_cloud && currentVideoData.lesson_from && currentVideoData.lesson_from === 'youtube';

    const externalHosting = youtubeLessonCode ? 'https://youtu.be/' + videoCode : videoCode;


    const cloud = currentVideoData.embed_link ? currentVideoData.embed_link : '';


    const video_src = currentVideoData.is_cloud ? cloud : externalHosting;

    const [playerStarted, setPlayerStarted] = useState(false);
    const trackerPlayer = playerStarted ? <Tracker id={data.id} event="videoStarted" childId={lessonID} /> : '';

    const player_iframe = <iframe src={video_src}
                           frameBorder="0" title="video_iframe"
                           allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                           allowFullScreen></iframe>;

    const player_v2 = <PlayerV2
        courseId={data.id}
        lessonId={lessonID}
        url={video_src}
        authorized={props.authorized}
        progress={props.progress}
        onPlayStarted={setPlayerStarted} />;

    const player = currentVideoData.is_cloud ? player_iframe : player_v2;

    const [notAllowed, setNotAllowed] = useState(false);
    const [checked, setChecked] = useState(false);

    const purchased = props.userData.purchased && props.userData.purchased.indexOf(data.id) > -1;
    const freeCourse = parseInt(data.regular_price) === 0;

    if (((!freeCourse && !purchased)) && !checked && !checkOwner(data.id, props.userData)) {
        setChecked(true);
        setNotAllowed(true);
    }

    const [checkedFree, setCheckedFree] = useState(false);
    const authorized = props.authorized;
    if (freeCourse && !purchased && !checkedFree && authorized) {
        setCheckedFree(true);
        props.freeCoursePurchase();
    }

    const redirect = notAllowed ? <Redirect to={{pathname: '/course/3/' + data.slug}}/> : '';

    const [bannerClosed, setBannerClosed] = useState(false);
    const unauthedBannerDiv = <div className="unauthed_banner">
        <span onClick={() => props.modalAuth(props.freeCoursePurchase)}>Зарегистрируйтесь или авторизуйтесь</span> чтобы ваш прогресс сохранялся автоматически!
        <ins onClick={() => setBannerClosed(true)}><Close /></ins>
    </div>;
    const unauthedBanner = !authorized && !bannerClosed ? unauthedBannerDiv : '';

    const lessonWrapper = () => {
        if (pageParams.id.toString() === data.slug.toString()) {
            return (
                <div className="lesson_wrapper">
                    <div className="lesson_top">

                        <h1>{html2plaintext(currentVideoData.title)}</h1>

                        <div className="pc_arrows_wrapper">
                            <div className="player_controls pc_left">
                                {siblingVideo().prev}
                            </div>
                            <div className="player_controls pc_right">
                                {siblingVideo().next}
                            </div>
                        </div>
                    </div>
                    {unauthedBanner}
                    <div className="player_wrapper">
                        <div className="player_inner">

                            {player}
                        </div>
                    </div>

                    <div className="lesson_text">
                        {lesson_description}
                        {content_source}
                    </div>

                    <CourseRelated {...props} ids={data.related_ids} />

                    {trackerPlayer}
                    <Tracker id={data.id} event="view" childId={lessonID} />

                    {redirect}
                </div>
            );
        }

        return '';
    };

    return lessonWrapper();
}

export default LessonDefault;