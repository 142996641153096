import React from 'react';

import TopFeatured from './top-featured';

import './index.css';
import API from "../utils/api";
import MainFeed from "./main-feed";
import CoursesFeaturedV2 from "../course-featured-v2";

import Spinner from "../utils/spinner";
// import TopFeaturedTest from "./top-featured-test";

class Main extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            super_premium_data: {}
        }
    }

    loadMainObject = () => {
        const t = this;
        
        t.setState({loading: true});
        API.get('/med/v3/main')
            .then(function (r) {
                t.setState({loading: false});
                t.props.setMainPageObj(r.data);

                if (r.duration && r.duration > 6000) {
                    console.log('long wait!: +' + r.duration + 'ms');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    loadLatest = () => {
        const t = this;

        API.get('/med/v3/latest')
            .then(function (r) {
                t.props.setMainPageObj(r.data, 'latest');
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    generateWatchingNow = (arr) => {
        const numbers = [];
        const output = [];
        while (output.length < 10) {
            const rnd = Math.floor(Math.random() * 50);
            if (numbers.indexOf(rnd) === -1) {
                numbers.push(rnd);
                output.push(arr[rnd]);
            }
        }

        return output;
    };

    componentDidMount() {
        document.body.classList.add('main_page');
        if (!this.props.mainPageLoaded) {
            this.loadMainObject();
            this.loadLatest();
        }
    };
    componentWillUnmount() {
        document.body.classList.remove('main_page');
    };

    render() {
        const t = this;
        const p = t.props;
        const o = p.mainPageObj;
        const {latest} = t.props;

        const {loading, super_premium_data} = t.state;

        const featuredBlock = o.featured.length ? <CoursesFeaturedV2 {...p} data={o.featured} /> : '';

        const feedLatest = latest.video.length ? <MainFeed {...p} courses={latest} title={'новое'} /> : '';
        const feedPopular = o.popular.video.length ? <MainFeed {...p} courses={o.popular} title={'популярное'} /> : '';
        const feedQuarantine = o.quarantine.video.length ? <MainFeed {...p} courses={o.quarantine} title={'рекомендованные'} /> : '';
        const feedWatchingNow = o.watching_now ? <MainFeed {...p} courses={t.generateWatchingNow(o.watching_now)} title={'сейчас смотрят'} noTabs={true} /> : '';
        const feedArticles = o.articles.length ?  <MainFeed {...p} courses={o.articles} feedType="blog_posts" title={'статьи'} noTabs={true} /> : '';
        return (
            <div className="main_page">
                <TopFeatured {...this.props} data={super_premium_data} />
                {/*<TopFeaturedTest {...this.props} data={super_premium_data} />*/}

                {featuredBlock}

                <div className="main_content">
                    {feedWatchingNow}
                    {feedLatest}
                    {feedArticles}
                    {feedQuarantine}
                    {feedPopular}
                </div>

                <Spinner show={loading} />
            </div>
        );
    }
}

export default Main;
