import React, {useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../my-courses.css';
import API from "../../utils/api";
import {parseQS} from "../../utils/utils";
import CoursePreviewV2 from "../../course-preview-v2";
import {Plus} from "../../utils/icons";
import Spinner from "../../utils/spinner";


export function Crawler(props) {
    const location = useLocation();

    const locate = () => {
        props.onLocation(parseQS(location.search.replace('?','')));
    };

    useEffect(() => {
        locate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return <i> </i>;
}

class MyOnlineCourses extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: false,
            courses: [],
            loadedLength: 0,
            no_courses: false,
            status: ''
        };

        this.delete = this.delete.bind(this);
    }

    loadCourses = () => {
        const t = this;
        const {status} = t.state;

        t.setState({loading: true});
        API.get('/med/v3/course?my=1&online=1&limit=30&status=' + status)
            .then(function (r) {
                t.setState({loading: false});
                if (r.data.products) {
                    t.setState({courses: r.data.products});
                }
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error);
            });
    };

    delete(id){
        this.setState(prevState => ({
            courses: prevState.courses.filter(el => el !== id )
        }));
    }

    componentDidMount() {
        document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    onLocation = (params) => {
        const t = this;
        const {status} = t.state;
        let newStatus = 'publish';

        if (params.status && params.status !== status) {
            newStatus = params.status;
        }

        t.setState({status: newStatus}, t.loadCourses);
    };

    activeCls = (cls) => {
        const t = this;
        const {status} = t.state;

        return cls === status ? 'active' : '';
    };

    render() {
        const t = this;
        const p = t.props;

        const {courses, loading, no_courses} = t.state;

        const coursesList = courses.map((course, idx) =>
            <CoursePreviewV2 {...this.props} deleteFunc={this.delete.bind(this, course)} itemData={course} itemId={idx} key={course.id} linkType="edit" />
        );

        const createNewCourse = <div key="random_key" className="course_preview_v2 cp_create_new_course">
            <Link to="/course/new" className="course_preview_link">
                <div className="cnc_plus"><Plus /></div>
                <p className="cnc_text">создать курс</p>
            </Link>
        </div>;

        if (courses.length) {
            coursesList.unshift(createNewCourse);
        }

        const created = p.userData.courses_created;
        const noCoursesText = no_courses ? <p className="no_courses">У вас нет опубликованных курсов. <Link to="/course/new">Создать курс?</Link></p> : '';
        const draftsText = no_courses && created && created.draft ? <p className="no_courses">Также вы можете опубликовать один из <Link to="/user/drafts">черновиков</Link>.</p> : '';

        return (
            <div className="profile_courses_list">
                <Crawler onLocation={t.onLocation} />

                <div className="status_switcher">
                    <Link className={t.activeCls('publish')} to='?status=publish'>Опубликованные</Link>
                    <span>|</span>
                    <Link className={t.activeCls('draft')} to='?status=draft'>Черновики</Link>
                </div>
                <div className="courses_block">
                    {coursesList}
                </div>
                {noCoursesText}
                {draftsText}
                <Spinner show={loading} />
            </div>
        );
    }
}

export default MyOnlineCourses;