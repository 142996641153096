import React from "react";

import './general.css';
import API from "../../utils/api";
import {html2plaintext, Plural} from "../../utils/utils";

import TextareaAutosize from 'react-autosize-textarea';
import Modal from "../../modal";

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Spinner from "../../utils/spinner";

class EditCourseDescriptions extends React.Component {
    constructor(props) {
        super();

        const dynamicDataList = [
            'description',
            'short_description'
        ];

        this.state = {
            loading: false,
            modal: false,
            valid: false,
            data: props.courseData,
            courseId: props.courseData.id,
            description: '',
            short_description: '',
            shortDescriptionLength: 0,
            originalState: {},
            dynamicDataList: dynamicDataList,
            editorState: EditorState.createEmpty()
        };
    }

    onEditorStateChange = (editorState) => {

        this.setState({
            editorState,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }, this.validate);
    };

    checkChanges = () => {
        return JSON.stringify(this.state.originalState) === JSON.stringify(this.mapCurrentState());
    };

    validate = () => {
        const s = this.state;
        const noChanges = this.checkChanges();

        const test = s.description
            && s.description.length
            && s.short_description
            && s.short_description.length && !noChanges;

        this.setState({valid: test});
    };

    renderData = () => {
        const s = this.state;
        const data = s.data;

        const contentBlock = htmlToDraft(data.description);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        this.setState({
            title: data.title,
            description: data.description,
            short_description: html2plaintext(data.short_description),
            shortDescriptionLength: data.short_description ? data.short_description.length : 0,
            loading: false,
            originalState: {},
            editorState
        }, this.afterRender);
    };

    afterRender = () => {
        this.updateOriginalState(this.validate);
    };

    mapCurrentState = () => {
        const s = this.state;
        return s.dynamicDataList.reduce(function (map, item) {
            map[item] = s[item];
            return map;
        }, {});
    };

    updateOriginalState = (cb) => {
        const originalState = this.mapCurrentState();

        this.setState({originalState: originalState}, cb)
    };

    saveChanges = (cb) => {
        const t = this;
        const s = t.state;
        let newData = {
            description: s.description,
            short_description: s.short_description
        };

        if (s.data.status === 'publish') {
            newData.edit = 1;
        }

        t.setState({loading: true});
        API.put('/med/v3/course/' + s.courseId, newData)
            .then(function (r) {
                t.setState({loading: false}, t.afterRender);
                t.updateLocalData({data: r.data});
                t.props.reloadUserData();

                if (cb && typeof cb === "function") {
                    cb();
                }
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    updateLocalData = (opts) => {
        const t = this;
        const s = t.state;
        const updatedData = {
            description: s.description,
            short_description: s.short_description,
            id: s.courseId
        };

        if (s.data.status === 'publish') {
            updatedData.edit = 1;
        }

        if (!opts) {
            opts = {};
        }

        let newData = s.data;
        newData.description = updatedData.description;
        newData.short_description = updatedData.short_description;

        const autoSave = s.valid && opts.leave ? updatedData : null;

        t.setState({data: newData, valid: false});
        t.props.updateData(newData, autoSave);
    };

    componentDidMount() {
        this.renderData();

        if (this.props.courseData.name === '') {
            this.setState({firstTimeEdit: true});
        }
    };

    componentWillUnmount() {
        this.updateLocalData({leave: true});
    };

    handleTextInput = (e) => {
        const t = this;
        const obj = {};

        obj[e.target.name] = e.target.value;
        t.setState(obj, t.validate);

        if (e.target.name === 'title') {
            this.props.updateData({title: e.target.value});
        }
    };

    handleShortDescriptionInput = (e) => {
        this.setState({shortDescriptionLength: e.target.innerHTML.length})
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;

        const {loading, editorState} = t.state;

        const maxShortDescriptionLength = 300;
        const remainingSymbols = maxShortDescriptionLength - s.shortDescriptionLength;


        const modal = s.modal ? <Modal modalContent={s.modal.modalData.content} modalData={s.modal.modalData} onModalClose={() => t.setState({modal: null})} /> : '';

        const toolbar = {
            options: ['inline', 'list'],
            inline: {
                options: ['bold', 'italic', 'underline']
            },
            blockType: {
                inDropdown: false,
                options: ['Blockquote'],

            },
            list: {
                options: ['unordered', 'ordered'],
            }
        };


        return (
            <div className="course_edit_block ceb_general">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={p.courseData.title} name="courseName" placeholder="название курса" />
                </div>

                <div className="course_description_block">
                    <div className="ce_grid">
                        <div className="ce_grid_block ce_left_block">
                            <h5>краткое описание курса</h5>
                            <textarea maxLength={maxShortDescriptionLength} onKeyUp={t.handleShortDescriptionInput} onChange={t.handleTextInput} name="short_description" value={s.short_description} />
                            <div className="course_form_hint">осталось {remainingSymbols} символ{Plural(remainingSymbols, '', 'а', 'ов')}</div>
                        </div>
                        <div className="ce_grid_block ce_right_block">
                            <h5>полное описание курса</h5>
                            <Editor
                                editorState={editorState}
                                toolbar={toolbar}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={t.onEditorStateChange}
                            />

                            {/*<textarea onChange={t.handleTextInput} name="description" value={s.description} />*/}
                            <div className="course_form_hint">не стесняйтесь, люди любят читать</div>
                        </div>
                    </div>
                </div>

                <div className="ce_button_holder">
                    <button onClick={t.saveChanges} disabled={!s.valid}>Сохранить</button>
                </div>

                {modal}
                <Spinner show={loading} />
            </div>
        );
    }
}

export default EditCourseDescriptions;