import React from "react";

import { Redirect } from 'react-router-dom';

import API from "../api";
import Preloader from "../preloader";

class SalePage extends React.Component {
    constructor (props) {
        super();

        this.state = {
            url: null
        }
    }

    componentDidMount() {
        const t = this;
        API.post('/med/v1/course', {service_cat: 'sale'})
            .then(function (r) {
                const slug = r.data.results[0].slug;
                t.setState({url: '/course/' + slug});
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        const redirect = this.state.url ? <Redirect to={{pathname: this.state.url}} /> : '';
        return (
            <div>
                <Preloader />
                {redirect}
            </div>
        );
    }
}

export default SalePage;