import React from 'react';
import { Link } from 'react-router-dom';

import '../my-courses.css';
import API from "../../utils/api";
import {obj2arr, serrializer} from "../../utils/utils";
import Preloader from "../../utils/preloader";
import CoursePreviewV2 from "../../course-preview-v2";

class ArchivedCourses extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: true,
            courses: [],
            loadedLength: 0,
            no_courses: false
        };
    }

    loadCourses = () => {
        const t = this;
        const ids = t.props.userData.purchased;

        if (!ids) {
            t.setState({loading: false, no_courses: true});
        }
        else {
            API.get('/med/v2/courses?' +  serrializer({showAllPurchased: true, per_page: 50, include: ids}))
                .then(function (r) {
                    const totalFound = r.data.total;

                    const data = obj2arr(r.data.results);

                    t.setState({loading: false, courses: data, loadedLength: totalFound});
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    checkAuth = () => {
        const p = this.props;

        if (!p.authorized) {
            alert('пользователь не авторизован!')
        }
        else {
            if (this.props.userData.purchased) {
                this.loadCourses();
            }
            else {
                this.setState({loading: false, no_courses: true});
            }
        }
    };

    componentDidMount() {
        document.body.classList.add('no_search');
        this.checkAuth();
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    render() {
        const coursesData = this.state.courses;
        const preloader = this.state.loading ? <Preloader/> : '';
        const archive = this.props.userData.archived_courses;
        const courses = coursesData.map((course, idx) => {
            if (archive.indexOf(course.id) > -1) {
                return <CoursePreviewV2 {...this.props} itemData={course} itemId={idx} key={course.id} linkType="archived" />
            }
            return '';
        });
        const noCoursesText = this.state.no_courses ? <p className="no_courses">У вас нет опубликованных курсов. <Link to="/course/new">Создать курс?</Link></p> : '';
        const draftsText = this.state.no_courses && this.props.userData.courses_created.draft ? <p className="no_courses">Также вы можете опубликовать один из <Link to="/user/drafts">черновиков</Link>.</p> : '';

        return (
            <div className="profile_courses_list">

                <div className="courses_block">
                    {courses}
                </div>
                {noCoursesText}
                {draftsText}
                {preloader}
            </div>
        );
    }
}

export default ArchivedCourses;