import React from 'react';
import {html2plaintext, checkOwner} from "../utils/utils";

import CourseRatingBlock from "./course-rating-block";
import {Link} from "react-router-dom";

class CourseText extends React.Component {
    constructor (props) {
        super();
    }

    renderLessons = () => {
        const t = this;
        const p = t.props;
        const data = p.courseData;

        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;
        const owner = p.authorized && checkOwner(data.id, p.userData);
        const freeCourse = parseInt(data.regular_price) === 0;

        const d = data.lessons;
        let ol = [];

        d.forEach((e,i) => {

            const link = freeCourse || purchased || owner ? <Link to={'/course/' + data.slug + '/' + e.id}>
                {html2plaintext(e.title)}
            </Link> : html2plaintext(e.title);

            ol.push(
                <li className={'ol_item'} data-idx={i+1+'.'} key={i}>
                    {link}
                </li>);
        });

        return <ol>{ol}</ol>;
    };

    render() {
        const t = this;
        const p = t.props;
        const data = p.courseData;

        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;

        const description = data.description.length ? data.description : data.short_description;

        const lessons_block = data.lessons.length ? <div className="course_grid_item">
            <h5>программа</h5>
            <div className="cgi_content">
                <div className="course_content cc_generated_lessons">{t.renderLessons()}</div>
            </div>
        </div> : '';

        const rating_block = p.authorized && p.userData.role === 'student' ? <CourseRatingBlock {...p} setLoading={p.setLoading} /> : '';

        const content_source = data.video_source.length && !parseInt(data.price) && purchased ? <div className="course_grid_item">
            <h5>источник контента</h5>
            <div className="cgi_content">
                <div className="course_description"><a rel="noopener noreferrer" href={data.video_source} target="_blank">{data.video_source}</a></div>
            </div>
        </div> : '';

        return (
            <div className="course_text">
                <div className="course_grid_item">
                    <h5>о курсе</h5>
                    <div className="cgi_content">
                        <div className="course_description">{description}</div>
                    </div>
                </div>

                {lessons_block}

                {rating_block}

                {content_source}

            </div>
        );
    }
}

export default CourseText;
