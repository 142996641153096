import React from "react";
import './index.css';
import GeoPattern from "geopattern";

import './user-page.css';
import API from "../utils/api";
import {arr2obj, html2plaintext, obj2arr, serrializer} from "../utils/utils";
import {TitleComponent} from "../utils/title";
import CoursePreviewV2 from "../course-preview-v2";
import Spinner from "../utils/spinner";
// import PublicContacts from "./public-contacts";

class PublicProfile extends React.Component {
    constructor (props) {
        super();

        this.state = {
            userId: null,
            loading: false,
            courses: [],
            loadedLength: 0,
            no_courses: false
        };
    }

    loadCourses = () => {
        const t = this;
        const p = this.props;
        const ud = p.userData;
        const ids = ud.courses;

        if (ids) {
            this.setState({loading: true});

            API.get('/med/v2/courses?' + serrializer({include: ids, per_page: 100}))
                .then(function (r) {
                    const totalFound = r.data.total;

                    const data = obj2arr(r.data.results);

                    t.setState({loading: false, courses: data, loadedLength: totalFound});
                })
                .catch(function (error) {
                    t.setState({loading: false});
                    console.log(error);
                });
        }

    };

    componentDidMount() {
        document.body.classList.add('no_dashboard');

        const p = this.props;
        const ud = p.userData;
        this.setState({userId: ud.id});
        this.loadCourses();
    };

    componentWillUnmount() {
        document.body.classList.remove('no_dashboard');
    };

    componentDidUpdate() {
        const s = this.state;
        const p = this.props;
        const ud = p.userData;

        if (ud.id !== s.userId && !s.loading) {
            this.setState({courses: [], userId: ud.id});
            this.loadCourses();

        }
    };

    getSchoolType = (type) => {
        const schoolTypesList = [
            {value: 'online-school', label: 'онлайн-школа'},
            {value: 'school', label: 'школа'},
            {value: 'university', label: 'университет'},
            {value: 'institute', label: 'институт'},
            {value: 'academy', label: 'академия'},
            {value: 'college', label: 'колледж'},
            {value: 'author', label: 'автор'},
            {value: 'expert', label: 'эксперт'},
            {value: 'blogger', label: 'блогер'},
            {value: 'instructor', label: 'преподаватель'},
            {value: 'teacher', label: 'учитель'},
            {value: 'lecturer', label: 'лектор'},
            {value: 'trainer', label: 'тренер'}
        ];
        const obj = arr2obj(schoolTypesList, 'value');

        return obj[type].label;
    };

    render() {
        const t = this;
        const p = t.props;
        const ud = p.userData;
        const isSchool = ud.role === 'school';

        const {courses, loading} = t.state;

        const patternaName = ud.display_name ? ud.display_name : ud.name;
        const coverPattern = GeoPattern.generate(patternaName, {color: '#434A5C'});

        const avatar = ud.avatar ? <img className="avatar" src={ud.avatar} alt="" /> : <span>{patternaName[0]}</span>;

        const coursesFeed = courses.map((course, idx) =>
            <CoursePreviewV2 {...p} itemData={course} itemId={idx} key={course.id} />
        );

        const roleMap = {
            school: 'школа',
            student: 'студент'
        };

        const role = isSchool && ud.school_type ? t.getSchoolType(ud.school_type) : roleMap[ud.role]

        const roleMapRod = {
            school: 'школы',
            student: 'студента'
        };

        const about = isSchool && ud.about && ud.about.length ? <div className="profile_about">{ud.about}</div> : '';
        // const contacts = isSchool && ud.contacts ? <PublicContacts {...p} />: '';

        const page_title = <TitleComponent title={patternaName + ' - профиль ' + roleMapRod[ud.role]} />;

        return (
            <div className="public_profile">
                <div className="profile_bg" style={{backgroundImage: coverPattern.toDataUrl()}}> </div>
                <div className="profile_userpic">{avatar}</div>
                <div className="profile_name">{html2plaintext(patternaName)}</div>
                <div className="profile_role">{role}</div>

                <div className="profile_about_wrapper">
                    {about}
                    {/*{contacts}*/}
                </div>

                <div className="profile_courses_list">

                    <div className="courses_block">
                        {coursesFeed}
                    </div>
                </div>
                {page_title}
                <Spinner show={loading} />
            </div>
        )
    }
}

export default PublicProfile;