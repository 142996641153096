import React from 'react';
// import { Link } from 'react-router-dom';

import './experts.css';

class Experts extends React.Component {
    constructor (props) {
        super();
        this.state = {
        };
    }

    componentDidMount() {
        document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    mapper = (el, idx) => {
        const about = el.about && el.about.length ? <dl>
            <dt>Об авторе:</dt>
            <dd>{el.about}</dd>
        </dl> : '';
        return (
            <li key={idx}>
                <dl>
                    <dt>Имя:</dt>
                    <dd>{el.name}</dd>
                </dl>
                {about}
            </li>
        );
    };

    render() {
        const t = this;

        const {userData} = t.props;

        return (
            <div className="school_experts">

                <h1>Эксперты</h1>
                <p>В этом разделе можно просмотреть, добавить или удалить экспертов, которых вы сможете указывать в качестве авторов ваших курсов.</p>


                <ul className="experts_list">
                    {userData.authors.map(t.mapper)}
                </ul>

            </div>
        );
    }
}

export default Experts;