import React from 'react';

import {Link, useParams} from 'react-router-dom';


function StudentDashboardLinks(props) {
    const userData = props.userData;

    let pageParams = useParams();

    const activeCls = (c) => {
        return c === pageParams.profilePage ? 'active' : ''
    };

    const purchasedLink = userData.purchased ? <Link className={activeCls('purchased')} to={'/user/purchased'}>мои курсы ({userData.purchased.length - parseInt(userData.archived_courses.length)})</Link> : '';
    const entriesLink = userData.course_entries ? <Link className={activeCls('entries')} to={'/user/entries'}>записи ({userData.course_entries.length})</Link> : '';
    const favouritesLink = userData.favourites ? <Link className={activeCls('favourites')} to={'/user/favourites'}>избранное ({userData.favourites.length})</Link> : '';
    const archivedLink = userData.archived_courses && userData.archived_courses.length ? <Link className={activeCls('archived')} to={'/user/archived'}>архив ({userData.archived_courses.length})</Link> : '';

    return (
        <div className="profile_nav_feed">
            {purchasedLink}
            {entriesLink}
            {favouritesLink}
            {archivedLink}
        </div>
    );
}

export default StudentDashboardLinks;