import React from 'react';

import './text.css';

class LessonText extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const {content} = p;

        return (
            <div className="lesson_text_wrapper" dangerouslySetInnerHTML={{ __html: content.text }}>
            </div>
        )
    }
}

export default LessonText;
