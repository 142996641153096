import React, { useState, useEffect } from 'react';

import {useParams, useLocation} from 'react-router-dom';

import './index.css';
import API from "../../utils/api";
import {TitleComponent} from "../../utils/title";
import WebinarLive from "../live";
import Preloader from "../../utils/preloader";
import WebinarLanding from "./webinar-landing";
import Breadcrumbs from "../../utils/breadcrumbs";
import {crumbBuilder} from "../../utils/breadcrumbs/crumb-builder";

function Webinar (props) {
    const [loading, setLoading] = useState(true);
    const [webinarID, setWebinarId] = useState(null);
    const [webinarData, setWebinarData] = useState(null);
    // const [purchased, setPurchased] = useState(false);
    //const purchased = false;
    const [title, setTitle] = useState(null);

    let pageParams = useParams();
    const location = useLocation();

    useEffect(() => {
        statusChange();
        // if (pageParams.lesson_id) {
        //     document.body.classList.remove('with_background');
        // }
        // else {
        //     document.body.classList.add('with_background');
        // }
        //
        // return () => {
        //     document.body.classList.remove('with_background');
        // }
    });

    const statusChange = () => {
        if (!webinarID || webinarID !== pageParams.id) {
            if (!loading) {
                setLoading(true);
            }
            setWebinarId(pageParams.id);
            loadData();
        }
    };

    const loadData = () => {
        setLoading(true);
        API.get('/med/v1/course/' + pageParams.id + '?skip_cache=1')
            .then(function (r) {
                setLoading(false);
                setTitle(r.data.name);
                setWebinarData(r.data);
                //freeCoursePurchase(r.data.id);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error)
            });
    };

    const freeCoursePurchase = (id) => {
        // const p = props;
        // const purchased = p.userData.purchased && p.userData.purchased.indexOf(id) > -1;


        // if (!purchased) {
        //     setLoading(true);
        //     API.get('/med/v1/pay/nothing/' + id)
        //         .then((r) => {
        //             setLoading(false);
        //             setPurchased(true);
        //             p.reloadUserData();
        //         })
        //         .catch((error) => {
        //             setLoading(false);
        //             console.log(error);
        //         });
        // }
        // else {
        //     setPurchased(true);
        // }
    };

    const page_title = title ? <TitleComponent title={title} /> : '';

    let content = '';
    if (webinarData) {
        if (pageParams.lecture_id) {
            content = <WebinarLive {...props} webinarData={webinarData} lectureId={pageParams.lecture_id} setLoading={setLoading} reloadData={loadData} />;
        }
        else {
            content = <WebinarLanding {...props} courseData={webinarData} contentType="webinar" pathname={location.pathname} loading={loading} freeCoursePurchase={freeCoursePurchase} />
        }
    }


    const preloader = loading && !props.loading ? <Preloader /> : '';
    const crumbs = webinarData ? crumbBuilder('course', {data: webinarData, pageParams: pageParams}) : null;
    const breadcrumbs = crumbs ? <Breadcrumbs data={crumbs} /> : '';

    return (
        <div className="webinar_wrapper course_wrapper">
            {page_title}
            {breadcrumbs}
            {content}
            {preloader}
        </div>
    );
}

export default Webinar;
