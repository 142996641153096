import React from 'react';
//import { Redirect } from 'react-router-dom';

import '../my-courses.css';
import API from "../../utils/api";
import {obj2arr, serrializer} from "../../utils/utils";
import Preloader from "../../utils/preloader";
import CoursePreviewV2 from "../../course-preview-v2";

class Favourites extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: true,
            courses: [],
            loadedLength: 0,
            no_favourites: false
        };

        this.delete = this.delete.bind(this);
    }

    loadCourses = () => {
        const t = this;
        const ids = t.props.userData.favourites;

        if (!ids || !ids.length) {
            t.setState({loading: false, no_favourites: true});
        }
        else {
            API.get('/med/v2/courses?' + serrializer({include: ids, per_page: 50}))
                .then(function (r) {
                    const totalFound = r.data.total;

                    const data = obj2arr(r.data.results);

                    t.setState({loading: false, courses: data, loadedLength: totalFound});
                })
                .catch(function (error) {
                    console.log(error);
                });
        }


    };

    checkAuth = () => {
        const p = this.props;

        if (!p.authorized) {
            alert('пользователь не авторизован!');
        }
        else {
            if (this.props.userData.favourites) {
                this.loadCourses();
            }
            else {
                this.setState({loading: false, no_courses: true});
            }
        }
    };

    delete(id){
        this.setState(prevState => ({
            courses: prevState.courses.filter(el => el !== id ),
            loadedLength: prevState.loadedLength -1,
            no_courses: !(prevState.loadedLength -1)
        }));
    }

    componentDidMount() {
        document.body.classList.add('no_search');
        this.checkAuth();
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    render() {
        const coursesData = this.state.courses;
        const preloader = this.state.loading ? <Preloader/> : '';
        const courses = coursesData.map((course, idx) =>
            <CoursePreviewV2 deleteFunc={this.delete.bind(this, course)} itemData={course} itemId={idx} key={course.id} linkType="favourite" />
        );
        const noFavouritesText = this.state.no_favourites ? <p className="no_courses">В избранном пока пусто...</p> : '';

        return (
            <div className="profile_courses_list">
                <div className="courses_block">
                    {courses}
                    {noFavouritesText}
                </div>
                {preloader}
            </div>
        );
    }
}

export default Favourites;