import React from 'react';
import './index.css';
import {PriceFormat} from "../../../../utils/utils";
import {Certificate} from "../../icons";

class Purchase extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;
        const userData = p.userData;

        const hasDiscount = !!parseInt(data.discount_amount_percentage);
        const price = hasDiscount ? data.price : data.regular_price;
        const isFree = !price;
        const isPurchased = userData.purchased && userData.purchased.indexOf(data.id) > -1;

        const discountText = hasDiscount ? <div className="discount_text">Со скидкой {data.discount_amount_percentage} %</div> : '';
        const actualPrice = isFree ? 'Бесплатно' : PriceFormat(parseInt(Math.ceil(price))) + ' ₽';
        const regularPriceBlock = hasDiscount ? <div className="regular_price_block">
            <p>Стоимость без скидки</p>
            <span>{PriceFormat(parseInt(Math.ceil(data.regular_price)))} ₽</span>
        </div> : '';

        let purchaseText = '';

        if (isPurchased) {
            purchaseText = 'В моих курсах';
        }
        else {
            if (isFree) {
                purchaseText = 'В мои курсы'
            }
            else {
                purchaseText = 'Купить';
            }
        }

        const uKassa = !isFree ? <div className="u_kassa"> </div> : '';
        const btnGift = !isFree && p.authorized ? <button onClick={p.handleGift} className="v2 light_inverted">Подарить</button> : '';

        return (
            <div className="cl_purchase">
                <div className="cl_centrer">

                    <div className="purchase_block">
                        <div className="part">
                            <h2>Стоимость курса</h2>
                            <div className="price_block">
                                {discountText}
                                <div className="actual_price">{actualPrice}</div>
                                {regularPriceBlock}
                            </div>

                            <div className="labels_block">
                                {uKassa}

                                <div className="certificate">
                                    <Certificate />
                                    <span>Сертификат W-HUB по окончании курса</span>
                                </div>
                            </div>
                        </div>
                        <div className="part right">
                            {/*<div className="additional_params">*/}
                                {/*<div className="date_and_slots">*/}
                                    {/*<p>Старт курса: <span>23 марта</span></p>*/}
                                    {/*<p>Осталось мест: <span>120</span></p>*/}
                                {/*</div>*/}

                                {/*<div className="age_restriction">16+</div>*/}
                            {/*</div>*/}
                            <div className="buttons_holder">
                                <button onClick={t.handlePurchaseClick} disabled={isPurchased} className="v2">{purchaseText}</button>
                                {btnGift}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Purchase;
