import React from 'react';

import {useParams} from 'react-router-dom';
import Collections from "./collections";

function CollectionsRouter(props) {
    let pageParams = useParams();

    return <Collections {...props} collectionId={pageParams.collectionId} />;
}

export default CollectionsRouter;