import React from 'react';
import {checkOwner} from "../utils/utils";
import {Link} from "react-router-dom";

class CourseButtonsSchool extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const p = this.props;
        const data = p.courseData;
        const owner = p.authorized && checkOwner(data.id, p.userData);

        const content = owner && data.lessons[0] ? <div>
            <Link to={'/course/' + data.slug + '/' + data.lessons[0].id}><button>смотреть</button></Link>
        </div> : <p>Школы не могут покупать курсы!<br /><strong>Школы могут создавать курсы!</strong></p>
        return (
            <div className="buttons_block school_lock">
                {content}
            </div>
        );
    }
}

export default CourseButtonsSchool;
