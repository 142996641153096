import React from 'react';
import './index.css';
import {RatingStar} from "../../icons";
import API from "../../../../utils/api";
import Preloader from "../../../../utils/preloader";

class LeaveReview extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            review: '',
            score: 0,
            selected_score: 0,
            rated: false
        };
    }

    handleTextChange = (e) => {
        const t = this;
        t.setState({review: e.target.value});
    };

    handleStarClick = (score) => {
        this.setState({selected_score: score});
    };

    handleStarEnter = (score) => {
        this.setState({score: score});
    };

    handleStarLeave = () => {
        const t = this;
        const {selected_score} = t.state;
        t.setState({score: selected_score});
    };

    stars = () => {
        const t = this;
        const {score} = t.state;

        const list = [];

        for (let i=1;i<6;i++) {
            const cls_selected = score >= i ? 'selected' : '';

            list.push(
                <li key={i}
                    className={cls_selected}
                    onClick={() => t.handleStarClick(i)}
                    onMouseEnter={() => t.handleStarEnter(i)}
                    onMouseLeave={t.handleStarLeave}>
                    <RatingStar />
                </li>
            );
        }

        return list;
    };

    handleSubmit = () => {
        const t = this;
        const p = t.props;
        const {review, score} = t.state;

        const reviewData = {
            course_id: p.data.id,
            body: review,
            rating: score
        };

        t.setState({loading: true});
        API.post('/med/v3/comments', reviewData)
            .then((r) => {
                t.setState({loading: false});
                t.setState({rated: true});
            })
            .catch((error) => {
                console.log(error.response);
                alert('вероятно, вы уже голосовали за этот курс...');

                t.setState({loading: false});
            })
    };

    render() {
        const t = this;
        const {loading, review, score, rated} = t.state;

        const cls_rated = rated ? 'rated' : '';

        const preloader = loading ? <Preloader /> : '';

        return (
            <div className="cl_leave_review">
                <div className="cl_centrer">
                    <div className="cl_left">
                        <h2>Оцените курс</h2>
                        <p>Поделитесь мнением о прохождении курса. Это поможет другим определиться с выбором.</p>
                    </div>


                    <div className="cl_right">
                        <div className={'leave_review_block ' + cls_rated}>
                            <div className="score">
                                <label>Ваша оценка курса</label>
                                <ul>{t.stars()}</ul>
                            </div>

                            <textarea placeholder="Ваш отзыв" onChange={t.handleTextChange} value={review} />

                            <button onClick={t.handleSubmit} disabled={!review.length || !score} className="v2">Оставить отзыв</button>

                            <p className="rated_message">
                                <strong>Спасибо за вашу оценку!</strong>
                                <br /><br />Ваш отзыв будет опубликован на странице курса после модерации!
                            </p>
                        </div>

                        {preloader}
                    </div>
                </div>
            </div>
        );
    }
}

export default LeaveReview;
