import React from "react";
import './index.css';
import AudioPlayer from "react-h5-audio-player";
import Preloader from "../preloader";
import Dropdown from "../dropdown/dropdown";
import {pushPlaybackProgress} from "../utils";

class AudioPlayerTool extends React.Component {
    constructor (props) {
        super();
        this.player = React.createRef();

        this.state = {
            loading: true,
            currentTrackIndex: props.currentProgress && props.currentProgress.file_index ? parseInt(props.currentProgress.file_index) : 0,
            timeReset: false,
            partsDropdown: this.makePartsDropdown(props.files),
            progressCounter: 0
        };
    }

    initPlayback = () => {
        const t = this;
        const s = this.state;
        const p = this.props;
        const player = this.player.current.audio.current;

        this.setState({loading: false});
        if (!s.timeReset && p.currentProgress && p.files[s.currentTrackIndex].purchased) {
            this.setState({timeReset: true});
            player.pause();
            player.play();
            player.currentTime = p.currentProgress.time_in_secs;
            t.setState({progressCounter: 10}, t.saveProgress);
        }
        else {
            player.play();
        }
    };

    saveProgress = () => {
        const t = this;
        const s = t.state;
        const p = t.props;
        if (p.files[s.currentTrackIndex].purchased && p.saveProgress) {

            const player = t.player.current.audio.current;
            const time = Math.floor(player.currentTime);
            const progress = {file_index: s.currentTrackIndex, time_in_secs: time};
            p.saveProgress(progress);

            let counter = s.progressCounter;
            counter+=1;
            if (counter >= 10) {
                counter = 0;
                pushPlaybackProgress(p.courseId, progress);
            }
            t.setState({progressCounter: counter});

        }
    };

    componentDidMount() {
        const player = this.player.current.audio.current;
        player.load();
    };

    handleError = () => {
        alert('error!')
    };

    playNext = () => {
        this.switchTrack(this.state.currentTrackIndex + 1);
    };

    playPrevious = () => {
        this.switchTrack(this.state.currentTrackIndex - 1);
    };

    makePartsDropdown = (files) => {
        return files.map((item, idx) => {
            return {
                label: 'Часть ' + (idx + 1),
                value: idx
            };
        })
    };

    switchTrack = (idx) => {
        const p = this.props;
        const files = p.files;

        let newTrackIndex = idx;

        if (idx < 0) {
            newTrackIndex = files.length - 1;
        }

        if (idx >= files.length) {
            newTrackIndex = 0;
        }

        this.setState({loading: true, currentTrackIndex: newTrackIndex});
    };

    handleDropdownChange = (d) => {
        this.switchTrack(d.data.value)
    };

    getSelectedOption = (arr, val) => {

        for (let i=0; i < arr.length; i++) {
            if (parseInt(arr[i].value) === parseInt(val)) {
                return arr[i];
            }
        }
        return arr[0];
    };

    handlePause = () => {
        const t = this;
        t.setState({progressCounter: 10}, t.saveProgress);
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;

        const preloader = s.loading ? <Preloader /> : '';

        const currentTrack = p.files[s.currentTrackIndex];

        return currentTrack ? <div className="audio_player_wrapper">
            <div className="track_title">
                {currentTrack.title ? currentTrack.title : ''}
            </div>
            <div className="dropdown_wrapper"><Dropdown onChange={this.handleDropdownChange} numerous={true} name="audio_part" selected={this.getSelectedOption(s.partsDropdown, s.currentTrackIndex )} ddArr={s.partsDropdown} /></div>

            <AudioPlayer
                ref={this.player}
                onCanPlay={this.initPlayback}
                onClickPrevious={this.playPrevious}
                onClickNext={this.playNext}
                onEnded={this.playNext}
                onPause={this.handlePause}
                onListen={this.saveProgress}
                showSkipControls={p.files.length > 1}
                className="audio_player"
                customAdditionalControls={[]}
                onError={this.handleError}
                src={currentTrack.link}
                // other props here
            />
            {preloader}
        </div> : '';
    }
}

export default AudioPlayerTool;