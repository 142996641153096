import React from 'react';
import LessonText from "./text";
import LessonPdf from "./pdf";
import LessonVideo from "./video";
import LessonWebinar from "./webinar";
import {arr2obj, html2plaintext} from "../../utils/utils";
import {crumbBuilder} from "../../utils/breadcrumbs/crumb-builder";
import Breadcrumbs from "../../utils/breadcrumbs";

import './index.css';

class LessonContent extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const {courseData, pageParams, lessonId, type, onToggleSidebar} = p;

        const lessons = courseData.lessons;
        const lessonsObj = arr2obj(lessons, 'id');
        const lessonData = lessonsObj[lessonId];

        const types = {
            text: <LessonText {...p} />,
            pdf: <LessonPdf {...p} />,
            video: <LessonVideo {...p} />,
            webinar: <LessonWebinar {...p} />
        };

        const crumbs = crumbBuilder('course', {data: courseData, pageParams: pageParams});

        return (
            <div className="lesson_content">
                <div className="lesson_inner">
                    <div className="lesson_top">
                        <Breadcrumbs data={crumbs} />
                        <h1>{html2plaintext(lessonData.title)}</h1>
                        <button onClick={onToggleSidebar} className="v2 light_inverted sidebar_toggler">Содержание курса</button>
                    </div>
                    {types[type]}
                </div>
            </div>
        );
    }
}

export default LessonContent;
