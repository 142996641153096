import {arr2obj} from "../utils";

export const crumbBuilder = (type, props) => {
    const obj = {
        type: 'course',
        crumbs: []
    };

    if (type === 'course') {
        const d = props.data;
        const pageParams = props.pageParams;

        const category = d.categories[0];

        obj.crumbs.push({title: category.name, url: '/categories/' + category.slug});
        if (category.children && category.children[0]) {
            const subcategory = category.children[0];
            obj.crumbs.push({title: subcategory.name, url: '/categories/' + category.slug + '/' + subcategory.slug});
        }

        if (pageParams.lesson_id) {
            obj.crumbs.push({title: d.name || d.title, url: '/course/3/' + d.slug});
            obj.type = 'lesson';
        }
        if (pageParams.lecture_id) {
            obj.crumbs.push({title: d.name || d.title, url: '/webinar/' + d.slug});
            obj.type = 'lesson';
        }
    }

    if (type === 'category') {
        const d = props.category;
        const pageParams = props.pageParams;

        obj.type = 'category';


        if (pageParams.subcategory_slug && d.children) {
            const subcategorySlug = pageParams.subcategory_slug;
            const childrenWithSlug = arr2obj(d.children, 'slug');

            if (childrenWithSlug[subcategorySlug]) {
                obj.crumbs.push({title: d.name, url: '/categories/' + d.slug});
                obj.crumbs.push({title: childrenWithSlug[subcategorySlug].name});
            }
            else {
                obj.crumbs.push({title: d.name});
            }
        }
        else {
            obj.crumbs.push({title: d.name});
        }

    }

    return obj;
};