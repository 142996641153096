import React from "react";

import './general.css';
import API from "../../utils/api";
import {arr2obj} from "../../utils/utils";

import TextareaAutosize from 'react-autosize-textarea';
import Dropdown from "../../utils/dropdown/dropdown";
import Modal from "../../modal";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ru from "date-fns/locale/ru";
import {addDays} from "@progress/kendo-date-math";
import DatePicker from "react-datepicker";
import Spinner from "../../utils/spinner";
import RadioDefault from "../../utils/radio/radio-default";
import ReactTooltip from "react-tooltip";

class EditCourseGeneral extends React.Component {
    constructor(props) {
        super();

        const dynamicDataList = [
            'title',
            'categories',
            'category',
            'subcategory',
            'difficulty',
            'isOnlineCourse',
            'dateStart',
            'sellStarts',
            'maxParticipants',
            'isAvailableForEntry'
        ];

        const difficulties = [
            {value: 1, label: 'Начинающий'},
            {value: 2, label: 'Средний'},
            {value: 3, label: 'Продвинутый'}
        ];

        const data = props.courseData;
        const category = data.categories[0];
        const hasSubcategory = category.children && category.children.length;

        const meta = data.meta;
        const onlineStatus = meta.online_course_status ? meta.online_course_status : {};

        this.state = {
            loading: false,
            modal: false,
            valid: false,
            originalState: {},
            dynamicDataList: dynamicDataList,
            data: data,
            courseId: data.id,
            title: data.title,
            categories: data.categories,
            category: category,
            subcategory: hasSubcategory ? category.children[0] : {name: 'выбрать', id: null},
            difficulty: meta.difficulty ? meta.difficulty : 0,
            difficulties: difficulties,
            firstTimeEdit: false,
            datePickerFocused: false,
            dateStart: meta.date_start ? new Date(meta.date_start) : null,
            sellStarts: onlineStatus.sell_starts_date ? new Date(onlineStatus.sell_starts_date) : null,
            maxParticipants: meta.max_participants ? meta.max_participants : '',
            isOnlineCourse: !!meta.date_start,
            isAvailableForEntry: onlineStatus.available_for_entry ? onlineStatus.available_for_entry : false
        };
    }

    componentDidMount() {
        this.afterRender();

        if (this.props.courseData.title === '') {
            this.setState({firstTimeEdit: true});
        }
    };

    componentWillUnmount() {
        this.updateLocalData({leave: true});
    };

    checkChanges = () => {
        return JSON.stringify(this.state.originalState) === JSON.stringify(this.mapCurrentState());
    };

    validate = () => {
        const s = this.state;
        const noChanges = this.checkChanges();

        const test = s.title && !noChanges;

        this.setState({valid: test});
    };

    afterRender = () => {
        this.updateOriginalState(this.validate);
    };

    mapCurrentState = () => {
        const s = this.state;
        return s.dynamicDataList.reduce(function (map, item) {
            map[item] = s[item];
            return map;
        }, {});
    };

    updateOriginalState = (cb) => {
        const originalState = this.mapCurrentState();

        this.setState({originalState: originalState}, cb)
    };

    checkConfirms = () => {
        const t = this;
        const {data, isOnlineCourse, dateStart, isAvailableForEntry, sellStarts} = t.state;

        let confirm = '';

        if (isOnlineCourse) {
            if (!dateStart) {
                confirm = 'Вы не указали дату старта онлайн-курса, при сохранении тип курса будет изменен на "Готовый курс".\n' +
                    'Продолжить?';
            }
            else {
                if (isAvailableForEntry && !sellStarts) {
                    confirm = 'Вы не указали дату начала продаж, при сохранении режим продаж будет изменен на "Немедленный старт продаж".\n' +
                        'Продолжить?';
                }
            }
        }
        else {
            let hasWebinars = false;
            const lessons = data.lessons;

            lessons.forEach((lesson) => {
                if (lesson.type === 'webinar') {
                    hasWebinars = true;
                }
            });

            if (hasWebinars) {
                // confirm = 'Вы собираетесь изменить тип курса на "Готовый курс". При этом все уроки типа "вебинар" будут автоматически удалены. Это действие невозможно отменить.\n' +
                //     'Продолжить?';
            }

        }

        if (confirm.length) {
            if (window.confirm(confirm)) {
                t.saveChanges();
            }
        }
        else {
            t.saveChanges();
        }
    };

    saveChanges = (cb) => {
        const t = this;
        const s = t.state;
        const {dateStart, sellStarts, maxParticipants, isOnlineCourse, isAvailableForEntry} = t.state;
        let newData = {
            title: s.title,
            difficulty: s.difficulty,
            date_start: isOnlineCourse && dateStart ? dateStart.getTime() : 0,
            sell_starts_date: isOnlineCourse && isAvailableForEntry && sellStarts ? sellStarts.getTime() : 0,
            available_for_entry:  isOnlineCourse && isAvailableForEntry
        };

        if (maxParticipants) {
            newData.max_participants = maxParticipants;
        }

        if (s.data.status === 'publish') {
            newData.edit = 1;
        }
        if (s.category.term_id) {
            newData.categories = [parseInt(s.category.term_id)];

            if (s.subcategory.term_id) {
                newData.categories.push(parseInt(s.subcategory.term_id));
            }
        }

        t.setState({loading: true});
        API.put('/med/v3/course/' + s.courseId, newData)
            .then(function (r) {
                t.setState({loading: false}, t.afterRender);
                t.updateLocalData({data: r.data});
                t.props.reloadUserData();

                if (cb && typeof cb === "function") {
                    cb();
                }

                if (isOnlineCourse && !dateStart) {
                    t.setState({isOnlineCourse: false});
                }
                if (isAvailableForEntry && !sellStarts) {
                    t.setState({isAvailableForEntry: false});
                }

                if (s.firstTimeEdit) {
                    const courseBase = '/course/' + s.courseId + '/edit/';
                    t.setState({firstTimeEdit: false, modal: {
                            modalData: {
                                content: 'text',
                                text: 'Теперь вы можете добавить описание, загрузить обложку или добавить уроки к вашему курсу!',
                                additional_buttons: [
                                    {
                                        button_text: 'описание',
                                        button_url: courseBase + 'descriptions'
                                    },
                                    {
                                        button_text: 'обложка',
                                        button_url: courseBase + 'covers'
                                    },
                                    {
                                        button_text: 'уроки',
                                        button_url: courseBase + 'lessons'
                                    }
                                ]
                            }
                        }})
                }

            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    updateLocalData = (opts) => {
        const t = this;
        const s = t.state;
        const {courseData} = t.props;
        const {dateStart, sellStarts, maxParticipants, isOnlineCourse, isAvailableForEntry} = t.state;
        const updatedData = {
            title: s.title,
            difficulty: s.difficulty,
            id: s.courseId,
            date_start: isOnlineCourse && dateStart ? dateStart.getTime() : 0,
            sell_starts_date: isAvailableForEntry && isOnlineCourse && sellStarts ? sellStarts.getTime() : 0,
            available_for_entry: isOnlineCourse && isAvailableForEntry
        };


        if (maxParticipants) {
            updatedData.max_participants = maxParticipants;
        }

        if (s.category.term_id) {
            updatedData.categories = [parseInt(s.category.id)];

            if (s.subcategory.term_id) {
                updatedData.categories.push(parseInt(s.subcategory.id));
            }
        }

        if (s.data.status === 'publish') {
            updatedData.edit = 1;
        }

        if (!opts) {
            opts = {};
        }

        let newData = s.data;
        newData.title = updatedData.title;
        newData.difficulty = updatedData.difficulty;
        newData.meta = courseData.meta;


        newData.meta.date_start = updatedData.date_start;
        newData.meta.online_course_status = {
            available_for_entry: updatedData.available_for_entry,
            sell_starts_date: updatedData.sell_starts_date
        };

        if (updatedData.max_participants) {
            newData.meta.max_participants = updatedData.max_participants;
        }

        if (updatedData.categories) {
            newData.categories = [s.category];

            if (s.subcategory.term_id) {
                newData.categories.push(s.subcategory);
            }
        }

        const autoSave = s.valid && opts.leave ? updatedData : null;

        t.setState({data: newData, valid: false});
        t.props.updateData(newData, autoSave);
    };

    handleTextInput = (e) => {
        const t = this;
        const obj = {};

        obj[e.target.name] = e.target.value;
        t.setState(obj, t.validate);

        if (e.target.name === 'title') {
            // this.props.updateData({title: e.target.value});
        }
    };

    handleLinebreak = (e) => {
        if (e.which === 13) {
            e.preventDefault();
        }
    };

    handleCategoryChange = (d) => {
        const update = {term_id: d.data.value, name: d.data.label};

        this.setState({category: update, subcategory: {name: 'выбрать', id: null}}, this.validate);
    };
    handleSubcategoryChange = (d) => {
        const update = {term_id: d.data.value, name: d.data.label};
        this.setState({subcategory: update}, this.validate);
    };

    handleDifficultyChange = (d) => {
        this.setState({difficulty: d.data.value}, this.validate);
        this.updateLocalData();
    };

    getSelectedOption = (arr, val) => {
        const obj = arr2obj(arr, 'value');
        if (obj[val]) {
            return obj[val];
        }
        return arr[0];
    };

    makeCatsDropdownArr = (obj, first) => {
        const arr = [first];
        const banList = [181, 182, 188, 204,215,216];

        Object.keys(obj).forEach((n) => {
            const el = obj[n];
            if (banList.indexOf(el.term_id) === -1 && !el.isHidden) {
                arr.push({label: el.name, value: el.term_id});

            }
        });

        return arr;
    };

    handleRadioChange = (name, value) => {
        const obj = {};
        obj[name] = value;

        this.setState(obj, this.validate);
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;

        const {loading, dateStart, sellStarts, datePickerFocused, maxParticipants, isOnlineCourse, isAvailableForEntry} = t.state;

        const categories = p.categories;
        const categoriesObj = arr2obj(categories, 'term_id');

        const categoriesDefault = {label: 'выбрать', value: null};
        const categoriesList = t.makeCatsDropdownArr(categories, categoriesDefault);
        const categorySelected = t.getSelectedOption(categoriesList, s.category.term_id);
        const currentCategory = categorySelected.value;

        const subcategoriesDefault = {label: 'выбрать', value: null};
        let hasSubcategories = false;
        let subcategoriesList = [{label: 'выбрать', value: null}];

        if (currentCategory
            && categoriesObj[currentCategory].children
            && categoriesObj[currentCategory].children.length) {
            const children = categoriesObj[currentCategory].children;
            hasSubcategories = true;
            subcategoriesList = t.makeCatsDropdownArr(children, subcategoriesDefault);
        }

        const subcategorySelected = t.getSelectedOption(subcategoriesList, s.subcategory.term_id);


        const difficultyDefaultOption = [{label: 'выбрать', value: null}];
        const difficultyDropdownArr = difficultyDefaultOption.concat(this.state.difficulties);
        const difficultySelected = this.getSelectedOption(difficultyDropdownArr, parseInt(s.difficulty));

        const modal = s.modal ? <Modal modalContent={s.modal.modalData.content} modalData={s.modal.modalData} onModalClose={() => t.setState({modal: null})} /> : '';

        const cls_focused = datePickerFocused ? 'focused' : '';

        const cls_online_hidden = !isOnlineCourse ? 'hidden' : '';
        const cls_entry_hidden = !isAvailableForEntry || !isOnlineCourse ? 'hidden' : '';

        const defaulCourseDescription = 'Готовый курс может состоять из любого количества видеоуроков, текстовых уроков и презентаций.';
        const onlineCourseDescription = 'Онлайн-курс, помимо видеоуроков, текстовых уроков и презентаций, может содержать вебинары и имеет дату старта. Доступен режим отложенного старта продаж.';
        const noEntryDescription = 'Ваш курс будет доступен к приобретению немедленно после публикации.';
        const withEntryDescription = 'Запись на Ваш курс будет доступна сразу после публикации. Продажи будут открыты в указанную Вами дату.';

        const courseTypeDescription = isOnlineCourse ? onlineCourseDescription : defaulCourseDescription;
        const entryModeDescription = isAvailableForEntry ? withEntryDescription : noEntryDescription;

        return (
            <div className="course_edit_block ceb_general">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" autoFocus={true} onKeyDown={t.handleLinebreak} onChange={t.handleTextInput} value={s.title} name="title" placeholder="название курса" />
                    <div className="course_form_hint">название курса рекомендуется делать максимально кратким и понятным
                    </div>
                </div>

                <div className="course_parametres_block cpb_first_line">
                    <div className="cpb_item">
                        <h5>категория</h5>
                        <Dropdown onChange={t.handleCategoryChange} name="category" className="dd_cats" selected={categorySelected} ddArr={categoriesList} />
                    </div>
                    <div className="cpb_item">
                        <h5>подкатегория</h5>
                        <Dropdown onChange={t.handleSubcategoryChange} disabled={!hasSubcategories} name="subcategory" className="dd_cats" selected={subcategorySelected} ddArr={subcategoriesList} />
                    </div>
                    <div className="cpb_item">
                        <h5>уровень студента</h5>
                        <Dropdown onChange={this.handleDifficultyChange} name="difficulty" className="dd_cats" selected={difficultySelected} ddArr={difficultyDropdownArr} />
                    </div>
                </div>

                <div className="course_type_selection">
                    <div className="course_parametres_block">
                        <div className="cpb_item">
                            <RadioDefault onChange={t.handleRadioChange} isOnlineCourse={isOnlineCourse} name="isOnlineCourse" value={false} label="Готовый курс" />
                        </div>
                        <div className="cpb_item">
                            <RadioDefault onChange={t.handleRadioChange} isOnlineCourse={isOnlineCourse} name="isOnlineCourse" value={true} label="Онлайн-курс" />
                        </div>
                    </div>

                    <div className="course_parametres_block cpb_description">
                        {courseTypeDescription}
                    </div>

                    <div className={'course_parametres_block cpb_date_start ' + cls_focused + ' ' + cls_online_hidden}>
                        <div className="cpb_item">
                            <h5>старт курса</h5>
                            <DatePicker
                                className="text_input"
                                selected={dateStart}
                                locale={ru}
                                minDate={addDays(new Date(), 3)}
                                onChange={date => t.setState({dateStart: date}, () => t.validate())}
                                showTimeSelect
                                timeCaption="время"
                                dateFormat="dd.MM.yyyy HH:mm"
                                onCalendarOpen={() => t.setState({datePickerFocused: true})}
                                onCalendarClose={() => t.setState({datePickerFocused: false})}
                            />
                        </div>
                        <div className="cpb_item">
                            <h5>макс. число студентов</h5>
                            <input type="number" className='text_input' value={maxParticipants} name="maxParticipants" onChange={t.handleTextInput} />
                        </div>

                    </div>

                    <div className={'course_parametres_block ' + cls_online_hidden}>
                        <div className="cpb_item">
                            <RadioDefault onChange={t.handleRadioChange} isAvailableForEntry={isAvailableForEntry} name="isAvailableForEntry" value={false} label="Немедленный старт продаж" />
                        </div>
                        <div className="cpb_item">
                            <RadioDefault onChange={t.handleRadioChange} isAvailableForEntry={isAvailableForEntry} name="isAvailableForEntry" value={true} label="Отложенный старт продаж" />
                        </div>
                    </div>

                    <div className={'course_parametres_block cpb_description ' + cls_online_hidden}>
                        {entryModeDescription}
                    </div>

                    <div className={'course_parametres_block ' + cls_entry_hidden}>

                        <div className="cpb_item">
                            <h5>старт продаж</h5>
                            <DatePicker
                                className="text_input"
                                selected={sellStarts}
                                locale={ru}
                                minDate={new Date()}
                                maxDate={dateStart ? addDays(new Date(dateStart), -3) : false}
                                onChange={date => t.setState({sellStarts: date}, () => t.validate())}
                                showTimeSelect
                                timeCaption="время"
                                dateFormat="dd.MM.yyyy HH:mm"
                                onCalendarOpen={() => t.setState({datePickerFocused: true})}
                                onCalendarClose={() => t.setState({datePickerFocused: false})}
                            />

                            <div className="q_hint" data-tip="Дата старта продаж должна быть определена,<br />при этом вы всегда сможете изменить ее,<br />например, если потребуется больше времени на сбор<br />минимально достаточного количества записавшихся на курс">?</div>
                        </div>
                        <div className="cpb_item"> </div>
                        <div className="cpb_item"> </div>
                    </div>
                </div>

                <ReactTooltip effect={'solid'} multiline={true} />

                <div className="ce_button_holder">
                    <button onClick={t.checkConfirms} disabled={!s.valid}>Сохранить</button>
                </div>

                {modal}
                <Spinner show={loading} />
            </div>
        );
    }
}

export default EditCourseGeneral;