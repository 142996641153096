import React from 'react';
import {html2plaintext, checkOwner, Plural} from "../../utils/utils";
import moment from 'moment-timezone/builds/moment-timezone-with-data';

import {Link} from "react-router-dom";

class LecturesList extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.courseData;

        const purchased = p.authorized && p.userData.purchased && p.userData.purchased.indexOf(data.id) > -1;
        const owner = p.authorized && checkOwner(data.id, p.userData);
        const freeCourse = parseInt(data.regular_price) === 0;
        const timezone = moment.tz.guess();

        const lectures = data.lectures;
        let ol = [];

        lectures.forEach((e,i) => {

            const link = freeCourse || purchased || owner ? <Link to={'/webinar/' + data.slug + '/lecture/' + e.id}>
                {html2plaintext(e.title)}
            </Link> : html2plaintext(e.title);

            ol.push(
                <li className={'ol_item lecture'} data-idx={i+1+'.'} key={i}>
                    {link}
                    <ul className="lecture_params">
                        <li>{moment.unix(e.date_start).tz(timezone).format('DD.MM.YYYY, HH:mm')}</li>
                        <li>{e.duration} минут{Plural(e.duration, 'а', 'ы', '')}</li>
                    </ul>
                </li>);
        });


        return lectures.length ? <div className="course_grid_item">
            <h5>расписание</h5>
            <div className="cgi_content">
                <div className="course_content"><ol>{ol}</ol></div>
            </div>
        </div> : '';
    }
}

export default LecturesList;
