import React from 'react';
import './index.css';
import {Link} from "react-router-dom";
import {html2plaintext, Plural} from "../../../../utils/utils";
import {SchoolLike} from "../../icons";

class AboutSchool extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data.author_data;

        const title = html2plaintext(data.display_name);
        const firstLetter = title[0].toUpperCase();

        const userpic = data.avatar.length ? <img src={data.avatar} alt="" /> : <span>{firstLetter}</span>;

        const slug = data.slug;
        const courseCount = data.publications > 1 ? <Link to={'/users/' + slug}>{data.publications} курс{Plural(data.publications, '', 'а', 'ов')}</Link> : '';


        return (
            <div className="cl_about_school">
                <div className="cl_centrer">
                    <h2>О школе</h2>

                    <div className="description_wrapper">
                        <div className="description">
                            {data.about}
                        </div>

                        <div className="school_card">
                            <div className="card_inner">
                                {/*<div data-tip="подписаться на обновления школы" className="subscribe_to_school"><SchoolLike /></div>*/}
                                <div className="subscribe_to_school"><SchoolLike /></div>
                                <div className="userpic"><Link to={'/users/' + slug}>{userpic}</Link></div>
                                <div className="title"><Link to={'/users/' + slug}>{title}</Link></div>
                                <div className="courses_count">{courseCount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutSchool;
