import React from 'react';
import Preloader from "../../../utils/preloader";
import './dl-item.css';
import {addKeyIds, generateKeyId, removeKeyIds} from "./key-ids";
import API from "../../../utils/api";
import AddAuthorItem from "./add-author-item";

class CourseAuthorsModal extends React.Component {
    constructor (props) {
        super();

        const d = props.courseData;
        const data = d['authors'] ? addKeyIds(d['authors']) : [];

        this.state = {
            loading: false,
            maxItems: 6,
            data: data,
            valid: false,
            ids: []
        };

        this.removeItem = this.removeItem.bind(this);
    }

    validate = (cb) => {
        const t = this;
        const {data} = t.state;

        let valid = !!data.length;

        data.forEach((el) => {
            if (!el.name || !el.name.length) {
                valid = false;
            }
        });

        t.setState({
            valid: valid
        }, cb)
    };

    updateData = (idx, k, v) => {
        const t = this;
        const {data} = t.state;
        const item = data[idx];

        item[k] = v;

        t.setState({data: data}, t.validate);
    };

    handleNewAuthors = (arr, cb) => {
        if (!arr.length) {
            cb()
        }
        else {
            API.post('/med/v3/author', {authors: arr})
                .then(function (r) {
                    cb({ids: r.data.ids});
                })
                .catch(function (error) {
                    console.log(error.response);
                    cb({error: error.response});
                });
        }
    };

    handleExistingAuthors = (arr, cb) => {
        if (!arr.length) {
            cb()
        }
        else {
            API.put('/med/v3/author/batch', {authors: arr})
                .then(function (r) {
                    cb();
                })
                .catch(function (error) {
                    console.log(error.response);
                    cb({error: error.response});
                });
        }
    };

    handleSave = () => {
        const t = this;
        const {onSave} = t.props;

        let newAuthors = [];
        let existingAuthors = [];

        const {data} = t.state;
        const d = removeKeyIds(data);

        let ids = [];

        d.forEach((el) => {
            if (el.id) {
                existingAuthors.push(el);
                ids.push(el.id);
            }
            else {
                newAuthors.push(el);
            }
        });

        t.setState({loading: true});
        t.handleNewAuthors(newAuthors, (r1 = {}) => {
            if (!r1.error) {
                let outputIds;
                if (r1.ids) {
                    outputIds = ids.concat(r1.ids);
                    t.setState({ids: ids.concat(r1.ids)});
                }

                t.handleExistingAuthors(existingAuthors, (r2 = {}) => {
                    if (!r2.error) {
                        onSave({authors: outputIds}, () => {
                            t.setState({loading: false});
                        });
                    }
                    else {
                        t.setState({loading: false});
                    }
                });
            }
            else {
                t.setState({loading: false});
            }
        });
    };

    dataMapper = (el, idx) => {
        const t = this;

        console.log(el)

        return <AddAuthorItem
            key={el.key_id}
            dt={el.name}
            dtLength={30}
            dtPlaceholder="Имя и фамилия"
            dtName="name"
            dd={el.about}
            ddLength={300}
            ddPlaceholder="Об авторе (макс. 300 символов)"
            ddName="about"
            idx={idx}
            update={t.updateData}
            removeItem={t.removeItem.bind(t, el)} />
    };


    removeItem(id){
        this.setState(prevState => ({
            data: prevState.data.filter(el => el !== id )
        }), this.validate);
    }

    addItem = () => {
        const {data, maxItems} = this.state;

        if (data.length < maxItems) {
            data.push({name: '', about: '', key_id: generateKeyId()});
            this.setState({data: data}, this.validate);
        }
    };

    render() {
        const t = this;
        const {data, maxItems, loading, valid} = t.state;
        const p = t.props;

        const addButtonText = data.length ? 'добавить еще' : 'добавить';

        const preloader = loading ? <Preloader/> : '';

        return (
            <div className="course_edit_modal">
                <div className="cem_inner">
                    <div className="cem_text">
                        <h3>Блок "Авторы"</h3>
                        <p className="block_description">Вы можете добавить до {maxItems} авторов курса.</p>
                    </div>
                    <div className="cem_content cem_dl">
                        {data.map(t.dataMapper)}
                        <button disabled={data.length >= maxItems} className="small light_border" onClick={t.addItem}>{addButtonText}</button>
                    </div>
                    <div className="cem_buttons">
                        <button className="small" onClick={p.onModalClose}>отмена</button>
                        <button disabled={!valid} className="small" onClick={t.handleSave}>сохранить</button>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default CourseAuthorsModal;
