import React from 'react';
// import { Link } from 'react-router-dom';

import './reviews.css';
import API from "../../utils/api";
import Spinner from "../../utils/spinner";

class Reviews extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: true,
            reviews: []
        };
    }

    loadReviews = () => {
        const t = this;

        API.get('/med/v3/school/comment')
            .then(function (r) {
                console.log(r)
                t.setState({loading: false, reviews: r.data});
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    componentDidMount() {
        document.body.classList.add('no_search');
        this.loadReviews();
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    mapper = (el, idx) => {
        return (
            <li key={idx}>
                <dl>
                    <dt>Курс:</dt>
                    <dd> </dd>
                </dl>
                <dl>
                    <dt>Автор:</dt>
                    <dd dangerouslySetInnerHTML={{ __html: el.author }} />
                </dl>
                <dl>
                    <dt>Отзыв:</dt>
                    <dd>{el.content}</dd>
                </dl>
            </li>
        );
    };

    render() {
        const t = this;

        const {
            reviews,
            loading
        } = t.state;

        return (
            <div className="school_courses_reviews">

                <ul className="reviews_list">
                    {reviews.map(t.mapper)}
                </ul>

                <Spinner show={loading} />
            </div>
        );
    }
}

export default Reviews;