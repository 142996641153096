import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';
import {DropdownArrow, TipPoint} from "../../utils/icons";
import {html2plaintext, logout} from "../../utils/utils";
import AuthorizedPlus from "./plus";

class AuthorizedUser extends React.Component {
    constructor (props) {
        super();
        this.state = {
            opened: false
        }
    }

    toggleOpened = () => {
        this.setState({opened: !this.state.opened});
    };

    collapse = () => {
        this.setState({opened: false});
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    };
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    };

    handleClick = (e) => {
        e.stopPropagation();
        if (this.state.opened) {
            if (this.node.contains(e.target)) {
                return;
            }
            this.collapse();
        }
    };

    processLogout = (e) => {
        const t = this;
        const p = t.props;

        e.preventDefault();

        p.closeFunc();

        logout(p.authFunc);

    };

    render() {
        const t = this;
        const p = t.props;

        const data = p.userData;
        const isTester = data.tester;
        const hasBlog = data.blog_posts && data.blog_posts.length;
        const isSchool = data.role === 'school';
        const isStudent = data.role === 'student';
        const hasElka = data['elka_registered'];

        const elkaUrls = {
            social: '/dedmorozonline',
            commercial: '/greeting'
        };

        let outputName = data.name.length ? data.name : data.email;
        if (data.display_name && data.display_name.length) {
            outputName = data.display_name;
        }

        const cls_opened = t.state.opened ? 'opened' : '';

        const elkaLink = hasElka ? <Link onClick={p.closeFunc} to={elkaUrls[hasElka]} className="red">Елка!</Link> : '';

        const profileLink =  isSchool ? <Link onClick={p.closeFunc} to={'/user/profile'}>Профиль</Link> : '';

        const coursesLink = isSchool ? <Link onClick={p.closeFunc} to={'/user/courses'}>Курсы</Link> : '';
        const blogLink = isTester && hasBlog ? <Link onClick={p.closeFunc} to={'/user/posts'}>Статьи</Link> : '';

        const purchasedLink = isStudent && data.purchased ? <Link onClick={p.closeFunc} to={'/user/purchased'}>Мои курсы</Link> : '';
        const favouritesLink = isStudent && data.favourites ? <Link onClick={p.closeFunc} to={'/user/favourites'}>Избранное</Link> : '';

        const plus = isSchool ? <AuthorizedPlus {...p} tester={isTester} /> : '';

        const avatar = data.avatar ? <div className="avatar"><img src={data.avatar} alt="" /></div> : <span>{outputName[0]}</span>;

        return (
            <div className="user_authorized">
                <div ref={node => t.node = node} className={'ua_username ' + cls_opened} onClick={t.toggleOpened}>
                    <div className="ua_userpic">{avatar}

                        <div className="ua_dropdown">
                            <TipPoint />
                            <div className="dropdown_list">
                                {elkaLink}
                                {profileLink}

                                {coursesLink}
                                {blogLink}

                                {purchasedLink}
                                {favouritesLink}
                                <a href="/" onClick={t.processLogout} className="ua_logout">Выход</a>
                            </div>
                        </div>
                    </div>
                    <span className="ua_username_text">{html2plaintext(outputName)}</span>
                    <DropdownArrow />

                </div>

                {plus}
            </div>
        );
    }
}

export default AuthorizedUser;