import React, {useState, useRef} from "react";

import {useLocation, useHistory} from 'react-router-dom';

import {parseQS, serrializer} from "../utils/utils";
import {Close, SearchIcon, SearchIcon2} from "../utils/icons";
// import API from "../utils/api";

import './search-bar.css';
import SearchSuggests from "./search-suggests";

// let suggestTimeout = null;

const SearchBar = (props) => {
    const location = useLocation();
    const isSearch = location.pathname === '/search';
    let history = useHistory();

    const searchParams = parseQS(decodeURI(location.search.replace('?','')));

    const [focused, setFocused] = useState(false);
    const [suggsts, setSuggests] = useState(null);
    const [selected, setSelected] = useState(null);
    const [request, setRequest] = useState(searchParams.request ? searchParams.request : '');

    const textInput = useRef(null);


    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        processSearch(request);
    };

    const processSearch = (r) => {
        setRequest(r);
        setSuggests(null);

        let params;

        if (isSearch) {
            params = Object.assign(searchParams, {request: r});
        }
        else {
            if (!selected) {
                params = {request: r};
            }
            else {
                params = {request: selected};
                setRequest(selected);
            }
        }

        const qStr = serrializer(params);
        history.push('/search?' + qStr);
    };

    // const searchSuggests = (request) => {
    //     API.post('/med/v1/search', {phrase: request, per_page: 15})
    //         .then(function (r) {
    //
    //             if (r.data.hits.hits.length) {
    //                 const suggests = checkSuggestsDubs(r.data.hits.hits)
    //                 setSuggests(suggests)
    //             }
    //             else {
    //                 setSuggests(null);
    //                 setSelected(null);
    //             }
    //         })
    //         .catch(function (error) {
    //             setSuggests(null);
    //             console.log(error)
    //         });
    //
    // };

    // const checkSuggestsDubs = (s) => {
    //     let arr = [];
    //     s.forEach((el) => {
    //         if (el._source.title && arr.indexOf(el._source.title) === -1 && arr.length < 10) {
    //             arr.push(el._source.title);
    //         }
    //     });
    //
    //     return arr;
    // };

    const handleSuggestClick = (suggestedRequest) => {
        setSuggests(null);
        setSelected(null);
        processSearch(suggestedRequest);
    };

    // const checkSuggestSearch = (val) => {
    //
    //     if (suggestTimeout) {
    //         clearTimeout(suggestTimeout);
    //         suggestTimeout = null;
    //     }
    //     suggestTimeout = setTimeout(() => {
    //
    //         if (!isSearch) {
    //             searchSuggests(val)
    //         }
    //         else {
    //             processSearch(val);
    //         }
    //     }, 300);
    //
    // };

    const handleChange = (e) => {
        const val = e.target.value;
        setRequest(val);

        // checkSuggestSearch(val)

    };

    const handleBlur = () => {
        props.onBlur();
        setFocused(false);

        setTimeout(() => {
            setSuggests(null);
            setSelected(null);
        }, 100);
    };

    const handleFocus = () => {
        props.onFocus();
        setFocused(true);
    };

    const handleClearSearch = () => {
        textInput.current.focus();
        setRequest('');
    };

    const clearSearch = request.length ? <div className="clear_search" onClick={handleClearSearch}><Close /></div> : '';

    const suggestsList = suggsts && !isSearch ? <SearchSuggests
                                        {...props}
                                        onSelect={setSelected}
                                        suggests={suggsts}
                                        suggestClick={handleSuggestClick} /> : '';

    const cls_focused = focused ? 'focused' : '';

    return (
        <div className={'search_bar ' + cls_focused}>
            <form onSubmit={handleSubmit}>
                <input
                    ref={textInput}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={request}
                    type="text"
                    placeholder="поиск курсов" />

                {suggestsList}

                <button className="no_style" type="submit"><SearchIcon /><SearchIcon2 /></button>
                <div className="close_search" onClick={props.closeSearch}><Close /></div>
                {clearSearch}
            </form>

        </div>
    )
};


export default SearchBar;