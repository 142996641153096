import React from "react";

import './advert.css';
import Preloader from "../../utils/preloader";
import API from "../../utils/api";
import TextareaAutosize from "react-autosize-textarea";

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';
import Checkbox from "../../utils/checkbox/checkbox";

class EditCourseAdvert extends React.Component {
    constructor (props) {
        super();

        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            loading: false,
            advert_package: 0,
            selectedDay: null,
            package_price: '',
            agreement: false,
            valid: false
        };
    }

    validate = () => {
        const test = this.state.agreement && this.state.selectedDay;
        this.setState({valid: test});
    };

    handleDayClick(day, { selected }) {
        this.setState({
            selectedDay: selected ? undefined : day,
        }, this.validate);
    }

    handleRadioChange = (e) => {
        const obj = {};
        const packagePrices = {
            0: 0,
            3608: 99,
            3609: 399
        };
        obj[e.target.name] = e.target.value;
        obj.package_price = packagePrices[e.target.value];
        this.setState(obj);
    };

    handleCheckbox = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.checked;

        if (e.target.name === 'school') {
            const role = e.target.checked ? 'school' : 'student';
            this.setState({role: role});
        }

        this.setState(obj, this.validate);
    };

    hanldePurchase = () => {
        const t = this;
        const d = {
            payment_method: 'yandex_kassa',
            product_id: [this.props.courseData.id, parseInt(this.state.advert_package)],
            date_start: this.state.selectedDay,

        };

        t.setState({loading: true});
        API.post('/med/v1/order', d)
            .then(function (r) {
                t.setState({loading: false});
                alert('Заказ '+r.data.id+' успешно сформирован!');
                //t.handleOrder(r.data);
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error)
            });
    };

    handleOrder = (orderData) => {
        const t = this;
        const d = {order_id: orderData.id};

        t.setState({loading: true});
        API.post('/med/v1/order/paid', d)
            .then(function (r) {
                t.setState({loading: false});
                alert('Услуга '+r.data.id+' успешно оплачена!');
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error)
            });
    };

    render () {
        const t = this;
        const s = t.state;
        const p = t.props;

        const preloader = s.loading ? <Preloader/> : '';
        const selectedDay = s.selectedDay ? moment(s.selectedDay).format('DD.MM.YYYY') : '...';
        const checkboxLabel = <span>я с <a href="/docs/oferta.docx" target="_blank">договором оферты</a></span>
        const advertDatepicker = parseInt(s.advert_package) ? <div className="advert_datepicker">
            <h4>начиная с {selectedDay}</h4>
            <DayPicker
                localeUtils={MomentLocaleUtils}
                locale="ru"
                selectedDays={s.selectedDay}
                onDayClick={this.handleDayClick}
                disabledDays={[{before: new Date(Date.now())}]}
            />

            <div className="agreement_wrapper">
                <Checkbox onChange={this.handleCheckbox} name="agreement" checked={this.state.agreement} label={checkboxLabel} />
            </div>

            <div className="ce_button_holder">
                {/*<button onClick={this.hanldePurchase} disabled={!s.valid}>Подключить за {s.package_price} ₽</button>*/}
                <button onClick={this.props.demoFunc} disabled={!s.valid}>Подключить за {s.package_price} ₽</button>
            </div>
        </div> : '';

        return (
            <div className="ceb_advert">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={p.courseData.title} name="courseName" placeholder="название курса" />
                </div>

                <div className="advert_block">
                    <div className="packages_list">

                        <div className="radio_wrapper simple">
                            <input checked={parseInt(s.advert_package)===0} onChange={this.handleRadioChange} value="0" id="advert_package_0" type="radio" name="advert_package" />
                            <label htmlFor="advert_package_0" className="advert_item">
                                <div className="point"></div>
                                <h4>без рекламы</h4>
                            </label>
                        </div>

                        <div className="radio_wrapper">
                            <input checked={parseInt(s.advert_package)===3608} onChange={this.handleRadioChange} value="3608" id="advert_package_1" type="radio" name="advert_package" />
                            <label htmlFor="advert_package_1" className="advert_item">
                                <div className="point"></div>
                                <h4>рекламный пакет №1</h4>
                                <p>Размещение курса в ротации на втором экране главной страницы на 3 дня</p>
                                <div className="ai_price">99 ₽</div>
                            </label>
                        </div>
                        <div className="radio_wrapper">
                            <input checked={parseInt(s.advert_package)===3609} onChange={this.handleRadioChange} value="3609" id="advert_package_2" type="radio" name="advert_package" />
                            <label htmlFor="advert_package_2" className="advert_item">
                                <div className="point"></div>
                                <h4>рекламный пакет №2</h4>
                                <p>Размещение курса в ротации на первом экране главной страницы на 3 дня</p>
                                <div className="ai_price">399 ₽</div>
                            </label>
                        </div>
                    </div>
                </div>

                {advertDatepicker}

                {preloader}
            </div>
        );
    }

}

export default EditCourseAdvert;