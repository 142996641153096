import React from 'react';
import {CourseRating} from "../utils/icons";
import {html2plaintext, Plural} from "../utils/utils";

import './course-params.css'
import {Link} from "react-router-dom";

class CourseParams extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const data = this.props.courseData;
        const rating = parseFloat(data.average_rating) ? <dl className="cp_rating">
            <dt>Рейтинг</dt>
            <dd><CourseRating rating={data.average_rating}/>{parseFloat(data.average_rating).toFixed(1)}</dd>
        </dl> : '';

        return (
            <div className="course_params">
                {rating}
                <dl>
                    <dt>Автор курса:</dt>
                    <dd><Link to={'/users/' + data.author.slug.toLowerCase()}>{html2plaintext(data.author.display_name)}</Link></dd>
                </dl>
                <dl>
                    <dt>Продолжительность:</dt>
                    <dd>{data.lessons.length} урок{Plural(data.lessons.length, '', 'а', 'ов')}</dd>
                </dl>

            </div>
        );
    }
}

export default CourseParams;
