import React from 'react';
import {Plural} from "../../../../../utils/utils";
import {TimeWatchIcon} from "../../../icons";
import {sortLessonsByType, timeConverter} from "../../../duration-utils";

class ParamDuration extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    typesMapper = (el, idx) => {
        const typesMap = {
            video: ['видеоурок', '', 'а', 'ов'],
            webinar: ['вебинар', '', 'а', 'ов'],
            pdf: ['презентаци', 'я', 'и', 'й'],
            text: ['текстов', 'ый урок', 'ых урока', 'ых уроков'],
        };

        const count = el.count;
        const type = el.type;
        const t = typesMap[type];

        return (
            <span className="sorted" key={idx}>{count}&nbsp;{t[0]}{Plural(count, t[1], t[2], t[3])}</span>
        )
    };

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;

        const lessons = sortLessonsByType(data.lessons);

        const time = timeConverter(lessons.duration);
        let timeText = '';
        let durationBlock;

        if (time.hours || time.minutes) {

            if (time.hours) {
                timeText = time.hours + ' час' + Plural(time.hours, '', 'а', 'ов');
            }

            if (time.minutes) {
                if (time.hours) {
                    timeText += ' ';
                }

                timeText += time.minutes + ' минут' + Plural(time.minutes, 'а', 'ы', '');
            }

            durationBlock = <p className="time"><TimeWatchIcon />{timeText}</p>;
        }

        return (
            <div className="params_section">
                <h3>Продолжительность</h3>

                <div className="cl_param_duration">
                    <p>{lessons.sortedArr.map(t.typesMapper)}</p>
                    {durationBlock}
                </div>
            </div>
        );
    }
}

export default ParamDuration;
